import { RegularChart } from '@jgp-er-dev/charts'
import React, { useMemo } from 'react'

export interface Props {
  subsections: {
    name: string
    score: number
  }[]
  peerAverage: number
}

export const getChartConfig = (props: Props) => {
  const series = [
    {
      type: 'column',
      threshold: props.peerAverage,
      data: props.subsections.map((subsection) => ({
        color: subsection.score >= props.peerAverage ? '#30ab09' : '#c4c4c4',
        name: subsection.name,
        y: subsection.score,
      })),
      tooltip: {
        pointFormat: 'Score: {point.y}',
        valueDecimals: 2,
      },
    },
  ]

  return {
    options: {
      chart: {
        marginRight: 50,
      },
      plotOptions: {
        column: {
          minPointLength: 1,
          dataLabels: {
            enabled: true,
            format: '{point.y:.0f}',
          },
        },
      },
      series: series as any,
      title: { text: '' },
      credits: { text: '' },
      legend: { enabled: false },
      xAxis: { type: 'category' as const },
      yAxis: {
        plotLines: [
          {
            color: '#D1D3D7',
            dashStyle: 'ShortDash' as const,
            value: props.peerAverage,
            label: {
              align: 'right' as const,
              text: 'Média<br/>peers',
              x: 45,
            },
          },
        ],
        title: { text: '' },
      },
      navigation: {
        buttonOptions: { enabled: false },
      },
    },
  }
}

const SubsectionVsPeersChart: React.FC<Props> = (props) => {
  const chartProps = useMemo(() => getChartConfig(props), [props])

  return (
    <div className="grid h-chart grid-cols-1 p-2">
      <RegularChart chartProps={chartProps} />
    </div>
  )
}

export default SubsectionVsPeersChart
