import { useLocation } from 'react-router-dom'

import { Path } from '../../../utils/constants'

export const useCompanyPath = () => {
  const { pathname } = useLocation()

  const skipCount = Path.REPORTS_COMPANY.split('/').length
  const [companyName, section, subsection] = pathname
    .split('/')
    .slice(skipCount)

  return { companyName, section, subsection }
}
