import React from 'react'
import { Link } from 'react-router-dom'

const LowerFooter: React.FC = () => (
  <div className="flex justify-center p-4 font-work-sans">
    <div className="flex w-full max-w-screen-xl flex-col justify-between md:flex-row md:items-center">
      <div className="grid grid-cols-max-3 text-sm">
        <Link className="border-r border-black pr-8" to="#">
          Termos & Condições
        </Link>
        <Link className="border-r border-black px-8" to="#">
          Política de Privacidade
        </Link>
        <Link className="px-8" to="#">
          FAQ
        </Link>
      </div>
      <div className="mt-4 text-xs text-jgp-secondary-dark md:mt-0">
        Copyright © 2021 JGP · Todos os direitos reservados
      </div>
    </div>
  </div>
)

export default LowerFooter
