import React from 'react'

import ColumnChart, {
  Props as ColumnChartProps,
} from '../../../../components/ColumnChart'
import ScoreHistoryChart, {
  Props as ScoreHistoryChartProps,
} from '../../../../components/ScoreHistoryChart'

interface Props {
  data: [ColumnChartProps, ScoreHistoryChartProps][]
  className?: string
}

const ESGSubsubsection: React.FC<Props> = (props) => {
  return (
    <div className={props.className}>
      <div className="font-bold">Quantitativo</div>
      <div className="mt-10 grid grid-cols-2 gap-x-4 gap-y-16">
        {props.data.map((indicator) => {
          return (
            <React.Fragment key={indicator[0].title}>
              <ColumnChart {...indicator[0]} />
              <ScoreHistoryChart {...indicator[1]} />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
export default ESGSubsubsection
