import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import React, { useCallback, useEffect, useState } from 'react'

import { useImperativeQuery } from '../../../../utils/hooks'
import Companies from '../../components/Companies'

interface Props {
  className?: string
}

interface QueryResult {
  user: {
    favorites: { companies: string[] }
    notifications: { companies: string[] }
  }
}

const query = loader('./query.graphql')
const mutationSetFavorite = loader('./mutationSetFavorite.graphql')
const mutationSetNotification = loader('./mutationSetNotification.graphql')

const CompaniesContainer: React.FC<Props> = (props) => {
  const [favorites, setFavorites] = useState<string[]>([])
  const [notifications, setNotifications] = useState<string[]>([])

  const makeQuery = useImperativeQuery(query)
  const [changeFavorite] = useMutation(mutationSetFavorite)
  const [changeNotification] = useMutation(mutationSetNotification)

  useEffect(() => {
    makeQuery().then((data: QueryResult) => {
      setFavorites(data.user.favorites.companies)
      setNotifications(data.user.notifications.companies)
    })
  }, [makeQuery])

  const handleFavoriteChange = useCallback(
    (companySlug: string, value: boolean) =>
      changeFavorite({
        variables: {
          companySlug,
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setFavorites(res.data.setFavorite.favorites.companies)
      }),
    [changeFavorite]
  )

  const handleNotificationChange = useCallback(
    (companySlug: string, value: boolean) =>
      changeNotification({
        variables: {
          companySlug,
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setNotifications(res.data.setNotification.notifications.companies)
      }),
    [changeNotification]
  )

  return (
    <Companies
      className={props.className}
      favorites={favorites}
      notifications={notifications}
      onFavoriteChange={handleFavoriteChange}
      onNotificationChange={handleNotificationChange}
    />
  )
}

export default CompaniesContainer
