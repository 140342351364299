import NotFound from '../../../../components/NotFound'
import RequirePermissions from '../../../../components/RequirePermissions'
import ControversiesSearch from '../../containers/ControversiesSearch'
import { useThemesPath } from '../../utils/hooks'
import ControversiesLibrary from '../ControversiesLibrary'

const Controversies: React.FC = () => {
  const { path } = useThemesPath()

  const [, page] = path

  return (
    <RequirePermissions permissions={['v2:esg_reports:themes:controversies']}>
      {page === 'search' ? (
        <RequirePermissions
          permissions={['v2:esg_reports:themes:controversies:search']}
        >
          <ControversiesSearch />
        </RequirePermissions>
      ) : page === 'library' ? (
        <RequirePermissions
          permissions={['v2:esg_reports:themes:controversies:library']}
        >
          <ControversiesLibrary />
        </RequirePermissions>
      ) : (
        <NotFound />
      )}
    </RequirePermissions>
  )
}

export default Controversies
