import { clone } from 'ramda'

import { getConsolidatedDate, isEstimateDate } from './remuneration'

const getKey = (
  field: ExtendedRemunerationDataField
): TimeSeriesRemunerationDataField => {
  switch (field) {
    case 'councilLongTermRemuneration':
    case 'councilFixedRemuneration':
    case 'councilVariableRemuneration':
      return 'councilRemunerationBreakdown'

    case 'directorsLongTermRemuneration':
    case 'directorsFixedRemuneration':
    case 'directorsVariableRemuneration':
      return 'directorsRemunerationBreakdown'

    case 'councilMaxRemuneration':
    case 'councilMinRemuneration':
    case 'councilAvgRemuneration':
      return 'councilRemunerationStats'

    case 'directorsMaxRemuneration':
    case 'directorsMinRemuneration':
    case 'directorsAvgRemuneration':
      return 'directorsRemunerationStats'

    default:
      return field
  }
}

const getSubfield = (field: ExtendedRemunerationDataField) => {
  switch (field) {
    case 'councilLongTermRemuneration':
    case 'directorsLongTermRemuneration':
      return 'long_term'

    case 'councilFixedRemuneration':
    case 'directorsFixedRemuneration':
      return 'fixed'

    case 'councilVariableRemuneration':
    case 'directorsVariableRemuneration':
      return 'variable'

    case 'councilMaxRemuneration':
    case 'directorsMaxRemuneration':
      return 'max'

    case 'councilMinRemuneration':
    case 'directorsMinRemuneration':
      return 'min'

    case 'councilAvgRemuneration':
    case 'directorsAvgRemuneration':
      return 'avg'

    default:
      return null
  }
}

export class CompanyRemunerationDataHandler {
  data: CompanyRemunerationData

  constructor(data: CompanyRemunerationData) {
    this.data = clone(data)
  }

  getAvailableYears(
    field: ExtendedRemunerationDataField,
    options?: {
      removeEstimates?: boolean
    }
  ) {
    const key = getKey(field)
    const subfield = getSubfield(field)

    return this.data[key].data
      .filter((dataPoint) => {
        if (options?.removeEstimates && isEstimateDate(dataPoint.date)) {
          return false
        }

        return subfield === null
          ? dataPoint.value != null
          : dataPoint.value?.[subfield] != null
      })
      .map((dataPoint) => dataPoint.date)
      .sort((ya, yb) => ya.localeCompare(yb))
  }

  getValue(
    year: string,
    field: ExtendedRemunerationDataField,
    options?: {
      allowConsolidatedValueForEstimatedYear?: boolean
    }
  ) {
    const key = getKey(field)
    const subfield = getSubfield(field)

    const getDataPoint = (y: string) =>
      this.data[key].data.find((dataPoint) => dataPoint.date === y)

    const dataPoint = options?.allowConsolidatedValueForEstimatedYear
      ? getDataPoint(year) ?? getDataPoint(getConsolidatedDate(year))
      : getDataPoint(year)

    const value = dataPoint?.value ?? undefined

    if (!value || !subfield) {
      return value
    }

    return value[subfield]
  }

  setValue(year: string, field: ExtendedRemunerationDataField, value: any) {
    const key = getKey(field)
    const subfield = getSubfield(field)

    let curPoint = this.data[key].data.find(
      (dataPoint) => dataPoint.date === year
    )

    if (!curPoint) {
      curPoint = { date: year, value: undefined }
      this.data[key].data = this.data[key].data
        .concat(curPoint)
        .sort((pointA, pointB) => pointA.date.localeCompare(pointB.date))
    }

    if (!subfield) {
      curPoint.value = value
      return this
    }

    const newValue = curPoint.value ?? {}
    newValue[subfield] = value
    curPoint.value = newValue

    return this
  }

  getData() {
    return this.data
  }
}
