import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import type { Props as ColumnChartProps } from '../../../../components/ColumnChart'
import type { Props as SubsectionPieChartProps } from '../../../../components/SubsectionPieChart'
import { computeAverage, sectionIdToLabel, slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import ESGSectionOverview from '../../components/ESGSectionOverview'
import { useActiveCompanies } from '../../contexts/ActiveCompanies'
import { useSectoralReport } from '../../contexts/SectoralReport'

interface Props {
  section: ESGSection
  className?: string
}

interface QueryResult {
  multiCompanyFramework: MultiCompanyFramework
}

interface MultiCompanyFramework {
  sections: MultiCompanyFrameworkSection[]
}

interface MultiCompanyFrameworkSection {
  title: string
  scores: {
    company: { name: string }
    value: number
  }[]
  subsections: {
    title: string
    defaultWeight: number | null
    scores:
      | {
          company: { name: string }
          value: number
        }[]
      | null
  }[]
}

const query = loader('./query.graphql')

export const getSubsectionPieChartProps = (
  data: QueryResult,
  args: { section: ESGSection }
) => {
  const section = data.multiCompanyFramework.sections.find(
    (s) => slugify(s.title) === args.section
  )!

  return {
    subsections: section.subsections
      .filter((subsection) => subsection.defaultWeight !== null)
      .map((subsection) => ({
        name: subsection.title,
        weight: subsection.defaultWeight!,
      })),
  }
}

export const getScoresColumnChartProps = (
  data: QueryResult,
  args: { section: ESGSection; companies: string[] }
) => {
  const section = data.multiCompanyFramework.sections.find(
    (s) => slugify(s.title) === args.section
  )!

  const isActiveCompany = (score: { company: { name: string } }) =>
    args.companies.includes(score.company.name)

  return {
    type: 'vertical' as const,
    title: `Notas ${sectionIdToLabel(args.section)}`,
    data: section.scores
      .filter(isActiveCompany)
      .map((score) => ({
        name: score.company.name,
        value: score.value,
      }))
      .sort((a, b) => b.value - a.value),
    average: computeAverage(
      section.scores.filter(isActiveCompany).map((score) => score.value)
    ),
    dataLabels: true,
  }
}

const getSubsectionScoresColumnChartPropsList = (
  data: QueryResult,
  args: { section: ESGSection; companies: string[] }
) => {
  const section = data.multiCompanyFramework.sections.find(
    (s) => slugify(s.title) === args.section
  )!

  const isActiveCompany = (score: { company: { name: string } }) =>
    args.companies.includes(score.company.name)

  return section.subsections
    .filter((subsection) => subsection.scores !== null)
    .map((subsection) => ({
      type: 'vertical' as const,
      title: `Notas ${subsection.title}`,
      data: subsection
        .scores!.filter(isActiveCompany)
        .map((score) => ({
          name: score.company.name,
          value: score.value,
        }))
        .sort((a, b) => b.value - a.value),
      average: computeAverage(
        subsection.scores!.filter(isActiveCompany).map((score) => score.value)
      ),
      dataLabels: true,
    }))
}

const ESGSectionOverviewContainer: React.FC<Props> = (props) => {
  const [subsectionPieChartProps, setSubsectionPieChartProps] =
    useState<SubsectionPieChartProps | null>(null)
  const [scoresColumnChartProps, setScoresColumnChartProps] =
    useState<ColumnChartProps | null>(null)
  const [
    subsectionScoresColumnChartPropsList,
    setSubsectionScoresColumnChartPropsList,
  ] = useState<ColumnChartProps[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const {
    loading: loadingSector,
    sector,
    year,
    companies,
  } = useSectoralReport()
  const { activeCompanies } = useActiveCompanies()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingSector) {
      return
    }

    setLoading(true)

    makeQuery({
      peerGroup: sector,
      year,
      companyIds: companies.map((company) => company.id),
    }).then((data: QueryResult) => {
      setSubsectionPieChartProps(
        getSubsectionPieChartProps(data, { section: props.section })
      )
      setScoresColumnChartProps(
        getScoresColumnChartProps(data, {
          section: props.section,
          companies: activeCompanies,
        })
      )
      setSubsectionScoresColumnChartPropsList(
        getSubsectionScoresColumnChartPropsList(data, {
          section: props.section,
          companies: activeCompanies,
        })
      )
      setLoading(false)
    })
  }, [
    loadingSector,
    sector,
    year,
    companies,
    makeQuery,
    props.section,
    activeCompanies,
  ])

  return (
    <ESGSectionOverview
      className={props.className}
      loading={loading}
      subsectionPieChartProps={subsectionPieChartProps}
      scoresColumnChartProps={scoresColumnChartProps}
      subsectionScoresColumnChartPropsList={
        subsectionScoresColumnChartPropsList
      }
    />
  )
}

export default ESGSectionOverviewContainer
