import { forwardRef, useEffect, useState } from 'react'

import { formatNumber, parseFormattedNumber } from '../../utils/numbers'

interface Props {
  className?: string
  value: number | null
  onChange?: (value: number | null) => any
  disabled?: boolean
}

const format = (value: number | null) =>
  value === null ? '' : formatNumber(value)

const NumericInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [value, setValue] = useState<string>('')

  useEffect(() => setValue(format(props.value)), [props.value])

  const handleBlur = () => {
    if (!value.trim()) {
      props.onChange?.(null)
      setValue('')
      return
    }

    const parsed = parseFormattedNumber(value)
    const numValue = isNaN(parsed) ? 0 : parsed
    props.onChange?.(numValue)
    setValue(format(numValue))
  }

  return (
    <input
      ref={ref}
      className={props.className}
      disabled={props.disabled}
      value={value}
      onBlur={handleBlur}
      onChange={(event) => setValue(event.target.value)}
    />
  )
})

export default NumericInput
