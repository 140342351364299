import React from 'react'

const EnvironmentalIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props
) => (
  <svg viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M12.0443 4.24189C12.3407 5.06673 11.9257 5.5731 11.6886 6.06354C10.1966 9.17182 9.29418 12.4139 8.9253 15.8247C8.78697 17.1049 9.1789 17.2865 10.3481 16.9839C13.5692 16.1527 15.6803 13.7132 15.9405 10.4776C16.2106 7.16864 15.4169 3.93615 15.2818 0.655899C15.2423 -0.305884 14.6758 0.00303284 14.162 0.245071C11.8434 1.33424 9.56754 2.50303 7.37405 3.8215C6.28389 4.47756 5.31229 5.2387 4.5976 6.261C3.57331 7.72915 3.71493 8.13042 5.31888 8.87883C5.86231 9.13042 6.40904 9.39475 6.89319 9.73233C8.42798 10.812 8.43127 10.8311 9.17561 9.08902C9.90019 7.39475 10.6643 5.72915 12.0443 4.24189ZM2.52925 11.1209C4.58442 12.5476 5.66799 14.7132 6.92613 16.8183C8.7211 14.2323 8.18426 11.8756 5.58566 10.554C4.47244 9.98711 3.29994 9.53488 2.1505 9.03488C0.0920383 8.13998 0.105212 8.13998 0.154615 10.2419C0.16779 10.8183 0.0393417 11.3979 0.00969984 11.9807C-0.145096 14.8916 1.22172 17.2196 3.46462 17.8629C4.46915 18.1495 5.04222 18.0667 4.76886 16.7705C4.34399 14.7674 3.23078 13.0604 2.52925 11.1209Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default EnvironmentalIcon
