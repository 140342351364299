import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import CompanySectorList from '../../../../components/CompanySectorList'
import ESGPlatformHeader from '../../../../components/ESGPlatformHeader'
import Select from '../../../../components/Select'
import { useCompanies } from '../../../../contexts/Companies'
import { useWatchLoading } from '../../../../contexts/Loading'
import { slugify } from '../../../../utils'
import { Path } from '../../../../utils/constants'

interface Props {
  favorites: string[]
  notifications: string[]
  className?: string
  onFavoriteChange?: (companySlug: string, value: boolean) => Promise<void>
  onNotificationChange?: (companySlug: string, value: boolean) => Promise<void>
}

const Companies: React.FC<Props> = (props) => {
  const history = useHistory()
  const { loading, companies } = useCompanies()
  useWatchLoading(loading)

  const sortedCompanies = useMemo(
    () =>
      [...companies]
        .filter((company) => !company.hasQuantDataOnly)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [companies]
  )

  const listItems = useMemo(() => {
    const favorites = props.favorites
    const notifications = props.notifications
    const onFavoriteChange = props.onFavoriteChange
    const onNotificationChange = props.onNotificationChange

    return sortedCompanies.map((company) => {
      const slug = slugify(company.name)
      return {
        isFavorite: favorites.includes(slug),
        isNotified: notifications.includes(slug),
        label: company.name,
        url: `${Path.REPORTS_COMPANY}/${slug}`,
        onFavoriteChange: async (value: boolean) =>
          onFavoriteChange?.(slug, value),
        onNotificationChange: async (value: boolean) =>
          onNotificationChange?.(slug, value),
      }
    })
  }, [
    sortedCompanies,
    props.favorites,
    props.notifications,
    props.onFavoriteChange,
    props.onNotificationChange,
  ])

  return loading ? null : (
    <div className={classNames('mb-8 font-work-sans', props.className)}>
      <ESGPlatformHeader />
      <div className="animate-fade-in-300">
        <div className="flex justify-center bg-jgp-secondary-dark py-10">
          <Select
            className="mx-4 w-full max-w-2xl"
            options={sortedCompanies.map((company) => ({
              label: company.name,
              value: slugify(company.name),
            }))}
            onChange={(value) =>
              value !== null &&
              history.push(`${Path.REPORTS_COMPANY}/${value.value}`)
            }
            placeholder="Selecione a empresa..."
          />
        </div>
        <div className="flex justify-center">
          <CompanySectorList
            className="mt-8 w-full max-w-screen-xl"
            title="Empresas"
            items={listItems}
          />
        </div>
      </div>
    </div>
  )
}

export default Companies
