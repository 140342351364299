import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import LoadingOverlay from '../../components/LoadingOverlay'

interface Props {
  type: 'overlay' | 'local'
  className?: string
}

interface ILoadingContext {
  register: () => string
  unregister: (id: string) => void
  loading: boolean
}

const LoadingContext = createContext<ILoadingContext>({} as ILoadingContext)

export const LoadingContextProvider: React.FC<Props> = (props) => {
  const [registeredIds, setRegisteredIds] = useState<string[]>([])

  const register = useCallback(() => {
    const id = Math.random().toString(36).slice(2)
    setRegisteredIds((curIds) => curIds.concat(id))
    return id
  }, [])

  const unregister = useCallback((id: string) => {
    setRegisteredIds((curIds) => curIds.filter((x) => x !== id))
  }, [])

  const value = useMemo(
    () => ({
      register,
      unregister,
      loading: registeredIds.length > 0,
    }),
    [register, unregister, registeredIds]
  )

  return (
    <LoadingContext.Provider value={value}>
      <LoadingOverlay
        className={props.className}
        type={props.type}
        show={registeredIds.length > 0}
      />
      {props.children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  const ctx = useContext(LoadingContext)

  if (!ctx) {
    throw new Error('useLoading must be used within a LoadingContextProvider')
  }

  return ctx
}

export const useWatchLoading = (loading: boolean) => {
  const { register, unregister } = useLoading()

  const idRef = useRef<string | null>(null)

  useEffect(() => {
    if (loading) {
      idRef.current = register()
    } else if (idRef.current !== null) {
      unregister(idRef.current)
    }

    return () => {
      if (idRef.current !== null) {
        unregister(idRef.current)
      }
    }
  }, [register, unregister, loading])
}
