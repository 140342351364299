import { loader } from 'graphql.macro'
import { useEffect, useState } from 'react'

import { ENVIRONMENTAL } from '../../../../utils/constants'
import { useImperativeQuery } from '../../../../utils/hooks'
import DecarbonizationQuali from '../../components/DecarbonizationQuali'
import type { QualiIndicator } from '../../components/QualiIndicatorTable'
import { useCompanyReport } from '../../contexts/CompanyReport'
import { buildQualiTableIndicator } from '../../utils'

interface Props {
  className?: string
}

interface QueryResult {
  companyDecarbFramework: {
    sections: {
      title: string
      subsections: {
        title: string
        qualiIndicators: {
          title: string
          score: number
          reason: string
        }[]
      }[]
    }[]
  } | null
}

const query = loader('./query.graphql')

const DecarbonizationQualiContainer: React.FC<Props> = (props) => {
  const [indicatorData, setIndicatorData] = useState<
    { name: string; indicators: QualiIndicator[] }[]
  >([])
  const [isSingleTopic, setIsSingleTopic] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingCompany) {
      return
    }

    setLoading(true)

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        if (!data.companyDecarbFramework) {
          setIndicatorData([])
          setLoading(false)
          return
        }

        const section = data.companyDecarbFramework.sections.find(
          (s) => s.title === ENVIRONMENTAL
        )

        if (!section) {
          throw new Error('Environmental section not found in decarb framework')
        }

        const indicators = section.subsections
          .map((subsection) => ({
            name: subsection.title === ENVIRONMENTAL ? '' : subsection.title,
            indicators: subsection.qualiIndicators.map(
              buildQualiTableIndicator
            ),
          }))
          .filter((subsection) => subsection.indicators.length > 0)

        setIndicatorData(indicators)
        setIsSingleTopic(indicators.every((indicator) => !indicator.name))
        setLoading(false)
      }
    )
  }, [loadingCompany, companyData, year, makeQuery])

  return (
    <DecarbonizationQuali
      className={props.className}
      loading={loading}
      companyName={companyData?.name ?? ''}
      topics={indicatorData}
      isSingleTopic={isSingleTopic}
    />
  )
}

export default DecarbonizationQualiContainer
