import classNames from 'classnames'
import { useEffect, useState } from 'react'

import PlainTabs from '../../../../components/PlainTabs'
import { useWatchLoading } from '../../../../contexts/Loading'
import DecarbonizationGoalsContainer from '../../containers/DecarbonizationGoals'
import DecarbonizationQualiContainer from '../../containers/DecarbonizationQuali'
import DecarbonizationQuantContainer from '../../containers/DecarbonizationQuant'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface Props {
  className?: string
}

type DecarbonizationTab = 'quant' | 'goals'

const Decarbonization: React.FC<Props> = (props) => {
  const [activeTab, setActiveTab] = useState<DecarbonizationTab>('quant')
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany } = useCompanyReport()

  useWatchLoading(loading)

  useEffect(() => {
    setLoading(loadingCompany)
  }, [loadingCompany])

  return loading ? null : (
    <div
      className={classNames(
        'pt-4 pb-40 pl-10 pr-4 font-work-sans',
        props.className
      )}
    >
      <div className="text-3xl font-semibold">Descarbonização</div>
      <DecarbonizationQualiContainer className="mt-8" />
      <PlainTabs
        className="my-8"
        tabs={[
          { id: 'quant', label: 'Quantitativo' },
          { id: 'goals', label: 'Metas' },
        ]}
        activeId={activeTab}
        onTabChange={(id) => setActiveTab(id)}
      />
      <DecarbonizationQuantContainer
        className={classNames('animate-fade-in-150', {
          hidden: activeTab !== 'quant',
        })}
      />
      <DecarbonizationGoalsContainer
        className={classNames('animate-fade-in-150', {
          hidden: activeTab !== 'goals',
        })}
      />
    </div>
  )
}

export default Decarbonization
