import classNames from 'classnames'
import React from 'react'

import { useWatchLoading } from '../../../../contexts/Loading'
import DataTable, { RowData } from '../DataTable'

interface Props {
  loading: boolean
  companyName: string
  rowData: RowData[]
  className?: string
}

const Data: React.FC<Props> = (props) => {
  useWatchLoading(props.loading)

  return props.loading ? null : (
    <DataTable
      className={classNames('pt-4 pb-20 pl-10 pr-4', props.className)}
      companyName={props.companyName}
      data={props.rowData}
    />
  )
}

export default Data
