import type { PermissionName } from '@jgp-er-dev/permissions'

import { useUser } from '../../contexts/User'
import NotFound from '../NotFound'

interface Props {
  permissions: PermissionName[]
  children?: React.ReactElement
}

const RequirePermissions: React.FC<Props> = (props: Props) => {
  const { checkPermissions } = useUser()

  if (!checkPermissions(props.permissions)) {
    return <NotFound />
  }

  return props.children ?? null
}

export default RequirePermissions
