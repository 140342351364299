import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import { slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import ESGSubsectionQuali from '../../components/ESGSubsectionQuali'
import type { QualiIndicator } from '../../components/QualiIndicatorTable'
import { useCompanyReport } from '../../contexts/CompanyReport'
import { buildQualiTableIndicator } from '../../utils'

interface Props {
  section: ESGSection
  subsection: string
  className?: string
}

interface QueryResult {
  companyFramework: {
    sections: {
      title: string
      subsections: {
        title: string
        qualiIndicators: {
          subsubsection: string | null
          title: string
          score: number
          reason: string
        }[]
      }[]
    }[]
  }
}

const query = loader('./query.graphql')

const ESGSubsectionQualiContainer: React.FC<Props> = (props) => {
  const [indicatorData, setIndicatorData] = useState<
    {
      name: string
      indicators: QualiIndicator[]
    }[]
  >([])
  const [isSingleTopic, setIsSingleTopic] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingCompany) {
      return
    }

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        const section = data.companyFramework.sections.find(
          (s) => slugify(s.title) === props.section
        )
        const subsection = section?.subsections.find(
          (s) => s.title === props.subsection
        )

        if (!section) {
          throw new Error(`Section ${props.section} does not exist`)
        } else if (!subsection) {
          throw new Error(
            `Subsection ${props.subsection} does not exist in section ${props.section}`
          )
        }

        const singleTopic = subsection.qualiIndicators.every(
          (indicator) => indicator.subsubsection === null
        )
        setIsSingleTopic(singleTopic)

        if (singleTopic) {
          setIndicatorData([
            {
              name: '',
              indicators: subsection.qualiIndicators.map(
                buildQualiTableIndicator
              ),
            },
          ])
        } else {
          const topics: Record<string, QualiIndicator[]> = {}
          subsection.qualiIndicators.forEach((indicator) => {
            if (!topics[indicator.subsubsection ?? '']) {
              topics[indicator.subsubsection ?? ''] = []
            }
            topics[indicator.subsubsection ?? ''].push(
              buildQualiTableIndicator(indicator)
            )
          })
          setIndicatorData(
            Object.entries(topics).map(([name, indicators]) => ({
              name,
              indicators,
            }))
          )
        }
        setLoading(false)
      }
    )
  }, [
    loadingCompany,
    companyData,
    year,
    makeQuery,
    props.section,
    props.subsection,
  ])

  return (
    <ESGSubsectionQuali
      className={props.className}
      loading={loading}
      companyName={companyData?.name ?? ''}
      topics={indicatorData}
      isSingleTopic={isSingleTopic}
    />
  )
}

export default ESGSubsectionQualiContainer
