import axios from 'axios'

import type { MetricInput, ScoreStatisticsMetrics } from '../typings/metrics'

export const getMetrics = async (): Promise<ScoreStatisticsMetrics> =>
  axios({
    method: 'GET',
    url: '/api/reports/statistics/metrics',
  }).then((res) => res.data)

export const getStatisticsData = async (args: {
  include: {
    companies?: string[]
    peerGroups?: string[]
    baskets?: string[]
  }
  exclude: {
    companies?: string[]
    peerGroups?: string[]
    baskets?: string[]
  }
  metrics: MetricInput[]
  yearRange: {
    min: number
    max: number
  }
}): Promise<{
  data: {
    companyName: string
    values: Record<string, { year: number; value: number }[]>
  }[]
  columns: {
    name: string
    field: string
  }[]
  measures: {
    name: string
    values: Record<
      string,
      { year: number; value: number; subjects?: string[] }[]
    >
  }[]
  yearRange: {
    min: number
    max: number
  }
}> =>
  axios({
    method: 'POST',
    url: '/api/reports/statistics/data',
    data: args,
  }).then((res) => res.data)
