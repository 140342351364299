import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import NotFound from '../../components/NotFound'
import { useUser } from '../../contexts/User'
import { Path } from '../../utils/constants'
import ThemesReportContainer from './containers/ThemesReport'

interface Props {
  className?: string
}

const ThemesReportPage: React.FC<Props> = (props) => {
  const { email, checkPermissions } = useUser()
  const history = useHistory()

  const utilitiesOnly = useMemo(
    () => checkPermissions(['v2:esg_reports:utilities_only']),
    [checkPermissions]
  )

  if (!email) {
    history.push(Path.LOGIN)
  }

  if (utilitiesOnly) {
    return <NotFound />
  }

  return <ThemesReportContainer className={props.className} />
}

export default ThemesReportPage
