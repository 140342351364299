import React from 'react'

import ThemesReport from '../../components/ThemesReport'

interface Props {
  className?: string
}

const ThemesReportContainer: React.FC<Props> = (props) => {
  return <ThemesReport className={props.className} />
}

export default ThemesReportContainer
