import { loader } from 'graphql.macro'
import { useEffect, useState } from 'react'

import { useImperativeQuery } from '../../../../utils/hooks'
import DecarbonizationGoals, {
  DecarbGoal,
} from '../../components/DecarbonizationGoals'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface Props {
  className?: string
}

interface QueryResult {
  companyDecarbGoals:
    | {
        description: string
        indicator: string | null
        unitOfMeasurement: string | null
        comments: string | null
        source: string | null
        data: {
          referenceDate: number
          value: number
        }[]
        goals: {
          referenceDate: number
          value: number
        }[]
      }[]
    | null
}

const query = loader('./query.graphql')

const DecarbonizationGoalsContainer: React.FC<Props> = (props) => {
  const [goals, setGoals] = useState<DecarbGoal[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingCompany) {
      return
    }

    setLoading(true)

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        setGoals(data.companyDecarbGoals ?? [])
        setLoading(false)
      }
    )
  }, [loadingCompany, makeQuery, companyData, year])

  return (
    <DecarbonizationGoals
      className={props.className}
      loading={loading}
      goals={goals}
    />
  )
}

export default DecarbonizationGoalsContainer
