import { loader } from 'graphql.macro'
import { useEffect, useState } from 'react'

import { useImperativeQuery } from '../../../../utils/hooks'
import type { CompanyGoal } from '../../components/CompanyGoals'
import CompanyGoals from '../../components/CompanyGoals'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface Props {
  className?: string
}

interface QueryResult {
  companyGoals:
    | {
        type: ESGSection
        description: string
        comments: string | null
        source: string | null
        data: {
          referenceDate: number
          value: number
        }[]
        goals: {
          referenceDate: number
          value: number
        }[]
        hasIndicator: boolean
        indicator: string | null
        unitOfMeasurement: string | null
        isSLB: boolean
        baseline: string | null
        stepUp: string | null
        jgpRating: string | null
      }[]
    | null
}

const query = loader('./query.graphql')

const CompanyGoalsContainer: React.FC<Props> = (props) => {
  const [goals, setGoals] = useState<CompanyGoal[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingCompany) {
      return
    }

    setLoading(true)

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        setGoals(data.companyGoals ?? [])
        setLoading(false)
      }
    )
  }, [loadingCompany, makeQuery, companyData, year])

  return (
    <CompanyGoals className={props.className} loading={loading} goals={goals} />
  )
}

export default CompanyGoalsContainer
