import type { ColDef, GridApi } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import classNames from 'classnames'
import { range } from 'ramda'
import { useMemo, useRef } from 'react'

import { DownloadIcon } from '../../../../components/icons'
import type { Props as CellRendererProps } from './CellRenderer'
import CellRenderer from './CellRenderer'
import CellTooltip from './CellTooltip'
import type { Props as TableHeaderProps } from './TableHeader'
import TableHeader from './TableHeader'
import type { StatisticsData, TableCellValue } from './types'

export interface Props {
  statisticsData: StatisticsData
  onHeaderClick?: (field: string) => any
  onCompanyClick?: (companyName: string) => any
}

const Table: React.FC<Props> = (props) => {
  const gridApiRef = useRef<GridApi | null>(null)

  const columnDefs: ColDef[] = useMemo(() => {
    const onHeaderClick = props.onHeaderClick

    const companyNameColumn: ColDef = {
      headerName: 'Empresa',
      field: 'companyName',
      pinned: 'left',
      cellRenderer: CellRenderer,
      cellRendererParams: {
        onClick: props.onCompanyClick,
      } as CellRendererProps,
    }

    const metricColumns = props.statisticsData.columns.map((column) => {
      return {
        headerName: column.name,
        headerGroupComponent: TableHeader,
        headerGroupComponentParams: {
          field: column.field,
          onClick: () => onHeaderClick?.(column.field),
        } as TableHeaderProps,
        children: range(
          props.statisticsData.yearRange.min,
          props.statisticsData.yearRange.max + 1
        ).map((year) => ({
          headerName: year,
          field: `${column.field}/${year}`,
          tooltipComponent: CellTooltip,
          tooltipField: `${column.field}/${year}`,
          headerClass: 'ag-center-header',
          cellClass: 'text-center',
          cellClassRules: {
            underline: (params: { value: TableCellValue }) =>
              params !== null &&
              params !== undefined &&
              typeof params.value !== 'number',
          },
          valueFormatter: (params: { value: TableCellValue }) =>
            typeof params.value === 'number'
              ? params.value.toFixed(2).replace('.', ',')
              : params.value === null || params.value === undefined
              ? null
              : params.value.value.toFixed(2).replace('.', ','),
          suppressHeaderMenuButton: true,
          width: 100,
          sortable: true,
        })),
      }
    })

    return [companyNameColumn, ...metricColumns]
  }, [props.statisticsData, props.onHeaderClick, props.onCompanyClick])

  const rowData = useMemo(
    () =>
      props.statisticsData.data.map((row) => {
        const metricFields = Object.fromEntries(
          Object.entries(row.values).flatMap(([field, values]) =>
            values.map((value) => [`${field}/${value.year}`, value.value])
          )
        )
        return { companyName: row.companyName, ...metricFields }
      }),
    [props.statisticsData.data]
  )

  const pinnedRowData = useMemo(
    () =>
      props.statisticsData.measures.map((measure) => {
        const metricFields = Object.fromEntries(
          Object.entries(measure.values).flatMap(([field, values]) =>
            values.map((value) => [
              `${field}/${value.year}`,
              value.subjects
                ? { value: value.value, subjects: value.subjects.join(', ') }
                : value.value,
            ])
          )
        )
        return { companyName: measure.name, ...metricFields }
      }),
    [props.statisticsData.measures]
  )

  const shouldLimitTableHeight = props.statisticsData.data.length > 10

  return (
    <div>
      <div
        className={classNames('ag-theme-alpine-compact', {
          'h-[400px]': shouldLimitTableHeight,
        })}
      >
        <AgGridReact
          columnDefs={columnDefs}
          pinnedTopRowData={pinnedRowData}
          rowData={rowData}
          domLayout={shouldLimitTableHeight ? 'normal' : 'autoHeight'}
          onGridReady={(event) => {
            gridApiRef.current = event.api
          }}
        />
      </div>
      <div className="mt-1 flex justify-end">
        <button
          className="flex items-center gap-x-1.5 text-jgp-secondary-dark hover:underline"
          onClick={() => gridApiRef.current?.exportDataAsExcel()}
        >
          <DownloadIcon className="h-5 w-5" />
          Exportar como .xlsx
        </button>
      </div>
    </div>
  )
}

export default Table
