import React, { useRef } from 'react'

import Button from '../../../../components/Button'
import LabeledComponent from '../../../../components/LabeledComponent'
import Modal from '../Modal'

interface Props {
  reportName: string
  onClose?: () => void
  onSave?: () => Promise<any>
  onReportNameChange?: (reportName: string) => void
}

const SaveModal: React.FC<Props> = (props) => {
  const reportNameInputRef = useRef<HTMLInputElement>(null)

  const handleSave = async () => {
    if (!props.reportName) {
      reportNameInputRef.current?.focus()
    } else {
      await props.onSave?.()
    }
  }

  return (
    <Modal title="Salvar análise" onClose={props.onClose}>
      <div className="flex flex-wrap items-end gap-4 bg-white px-4 py-8">
        <LabeledComponent label="Nome da análise">
          <input
            ref={reportNameInputRef}
            className="w-80 rounded-sm border px-1 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-jgp-success-main"
            placeholder="Escreva o nome da análise"
            value={props.reportName}
            onChange={(event) => props.onReportNameChange?.(event.target.value)}
          />
        </LabeledComponent>
        <Button
          className="flex h-8 w-28 items-center justify-center"
          variant="secondary"
          onClick={handleSave}
        >
          Salvar
        </Button>
      </div>
    </Modal>
  )
}

export default SaveModal
