import React from 'react'

const ControversyIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props
) => (
  <svg
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.91667 0.833336L0.75 11.8333H9L8.08333 19.1667L17.25 8.16667H9L9.91667 0.833336Z"
      fill="currentColor"
    />
  </svg>
)

export default ControversyIcon
