import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import CompanySectorList from '../../../../components/CompanySectorList'
import ESGPlatformHeader from '../../../../components/ESGPlatformHeader'
import Select from '../../../../components/Select'
import { useWatchLoading } from '../../../../contexts/Loading'
import { useSectors } from '../../../../contexts/Sectors'
import { slugify } from '../../../../utils'
import { Path } from '../../../../utils/constants'

interface Props {
  favorites: string[]
  notifications: string[]
  className?: string
  onFavoriteChange?: (sectorSlug: string, value: boolean) => Promise<void>
  onNotificationChange?: (sectorSlug: string, value: boolean) => Promise<void>
}

const Sectors: React.FC<Props> = (props) => {
  const history = useHistory()
  const { loading, sectors } = useSectors()
  useWatchLoading(loading)

  const sortedSectors = useMemo(
    () => [...sectors].sort((a, b) => a.localeCompare(b)),
    [sectors]
  )

  const listItems = useMemo(() => {
    const favorites = props.favorites
    const notifications = props.notifications
    const onFavoriteChange = props.onFavoriteChange
    const onNotificationChange = props.onNotificationChange

    return sortedSectors.map((sector) => {
      const slug = slugify(sector)
      return {
        isFavorite: favorites.includes(slug),
        isNotified: notifications.includes(slug),
        label: sector,
        url: `${Path.REPORTS_SECTORAL}/${slug}`,
        onFavoriteChange: async (value: boolean) =>
          onFavoriteChange?.(slug, value),
        onNotificationChange: async (value: boolean) =>
          onNotificationChange?.(slug, value),
      }
    })
  }, [
    props.favorites,
    props.notifications,
    props.onFavoriteChange,
    props.onNotificationChange,
    sortedSectors,
  ])

  return loading ? null : (
    <div className={classNames('mb-8 font-work-sans', props.className)}>
      <ESGPlatformHeader />
      <div className="animate-fade-in-300">
        <div className="flex justify-center bg-jgp-secondary-dark py-10">
          <Select
            className="mx-4 w-full max-w-2xl"
            options={sortedSectors.map((sector) => ({
              label: sector,
              value: slugify(sector),
            }))}
            onChange={(value) =>
              value !== null &&
              history.push(`${Path.REPORTS_SECTORAL}/${value.value}`)
            }
            placeholder="Selecione o setor..."
          />
        </div>
        <div className="flex justify-center">
          <CompanySectorList
            className="mt-8 w-full max-w-screen-xl"
            title="Setores"
            items={listItems}
          />
        </div>
      </div>
    </div>
  )
}

export default Sectors
