import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import { useUser } from '../../contexts/User'
import { Path } from '../../utils/constants'
import SectoralReportContainer from './containers/SectoralReport'
import SectorsContainer from './containers/Sectors'
import { ActiveCompaniesContextProvider } from './contexts/ActiveCompanies'
import { SectoralReportProvider } from './contexts/SectoralReport'

interface Props {
  className?: string
}

const SectoralReportPage: React.FC<Props> = (props) => {
  const { email } = useUser()
  const history = useHistory()

  if (!email) {
    history.push(Path.LOGIN)
  }

  return (
    <Switch>
      <Route path={Path.REPORTS_SECTORAL} exact>
        <SectorsContainer className={props.className} />
      </Route>
      <Route>
        <SectoralReportProvider>
          <ActiveCompaniesContextProvider>
            <SectoralReportContainer className={props.className} />
          </ActiveCompaniesContextProvider>
        </SectoralReportProvider>
      </Route>
    </Switch>
  )
}

export default SectoralReportPage
