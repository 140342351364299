import classNames from 'classnames'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../../../components/Button'
import { ArrowLeftIcon } from '../../../../components/icons'
import ReportHeader from '../../../../components/ReportHeader'
import Spinner from '../../../../components/Spinner'
import { useToast } from '../../../../contexts/Toast'
import { Path } from '../../../../utils/constants'
import type {
  BasketCompany,
  BasketFilter,
  BasketFilterOption,
} from '../../typings/baskets'
import CompaniesTable from '../CompaniesTable'
import ManagementModal from '../ManagementModal'
import Filters from './Filters'
import SaveModal from './SaveModal'

interface Props {
  loadingCompanies: boolean
  loadingFiltersData: boolean
  companyCount: number | null
  filters: BasketFilter[]
  filterOptions: BasketFilterOption[]
  companies: BasketCompany[] | null
  basketName: string
  userBaskets: UserBasket[]
  className?: string
  onAddFilter?: () => void
  onChangeFilterFilter?: (id: string, filter: SelectOption) => void
  onChangeFilterMode?: (id: string, mode: SelectOption) => void
  onChangeFilterValues?: (id: string, values: SelectOption[]) => void
  onRemoveFilter?: (id: string) => void
  onSubmit?: () => Promise<void>
  onBasketNameChange?: (name: string) => void
  onSave?: () => Promise<any>
  onOpenBasket?: (id: string) => void
  onDeleteBasket?: (id: string) => Promise<void>
}

const Baskets: React.FC<Props> = (props) => {
  const history = useHistory()
  const { enqueueToast } = useToast()

  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false)
  const [basketsModalOpen, setBasketsModalOpen] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)

  const handleSave = () => {
    if (props.onSave) {
      setSaving(true)
    }

    props
      .onSave?.()
      .then(() => {
        enqueueToast({
          type: 'success',
          message: 'Basket salvo com sucesso',
        })
        setSaveModalOpen(false)
      })
      .catch((err) => {
        console.error('Failed to save basket:', err)
        enqueueToast({
          type: 'fail',
          message:
            'Ocorreu um erro ao salvar o basket. Por favor, tente novamente.',
        })
      })
      .finally(() => setSaving(false))
  }

  return (
    <div
      className={classNames('flex flex-col font-work-sans', props.className)}
    >
      <ReportHeader type="baskets" onManage={() => setBasketsModalOpen(true)} />
      <Filters
        loading={props.loadingFiltersData}
        companyCount={props.companyCount}
        filters={props.filters}
        filterOptions={props.filterOptions}
        onAddFilter={props.onAddFilter}
        onChangeFilterFilter={props.onChangeFilterFilter}
        onChangeFilterMode={props.onChangeFilterMode}
        onChangeFilterValues={props.onChangeFilterValues}
        onRemoveFilter={props.onRemoveFilter}
        onSubmit={props.onSubmit}
        onSave={() => setSaveModalOpen(true)}
      />
      <div className="flex flex-grow justify-center">
        <div className="flex w-full max-w-screen-xl flex-col px-4 py-8">
          {props.loadingCompanies ? (
            <div className="flex justify-center">
              <Spinner className="text-jgp-success-dark" size={36} />
            </div>
          ) : props.companies ? (
            <CompaniesTable className="flex-grow" companies={props.companies} />
          ) : null}
          <div className="mt-8 flex justify-center">
            <Button
              className="flex h-8 w-32 items-center justify-center"
              variant="secondary"
              onClick={() => history.push(Path.REPORTS_PERSONALIZED)}
            >
              <ArrowLeftIcon className="mr-1 h-5 w-5" />
              Voltar
            </Button>
          </div>
          {saveModalOpen && (
            <SaveModal
              basketName={props.basketName}
              saving={saving}
              onClose={() => setSaveModalOpen(false)}
              onBasketNameChange={props.onBasketNameChange}
              onSave={handleSave}
            />
          )}
          {basketsModalOpen && (
            <ManagementModal
              type="basket"
              loading={props.loadingFiltersData}
              items={props.userBaskets}
              onClose={() => setBasketsModalOpen(false)}
              onOpen={props.onOpenBasket}
              onDelete={props.onDeleteBasket}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Baskets
