import axios from 'axios'

export const getUserData = async () =>
  axios.get<{ name: string | null; email: string; permissions: string[] }>(
    '/api/auth/user',
    {
      withCredentials: true,
    }
  )

export const login = async (
  email: string,
  password: string,
  rememberMe: boolean
) =>
  axios({
    method: 'POST',
    url: '/api/auth/login',
    data: { user: email, pwd: password, remember_me: rememberMe },
  })
