import classNames from 'classnames'
import React from 'react'

import ColumnChart, {
  Props as ColumnChartProps,
} from '../../../../components/ColumnChart'
import ScoreCard from '../../../../components/ScoreCard'
import ScoreHistoryChart, {
  Props as ScoreHistoryChartProps,
} from '../../../../components/ScoreHistoryChart'
import { useWatchLoading } from '../../../../contexts/Loading'
import ESGSubsubsection from '../ESGSubsubsection'

interface Props {
  loading: boolean
  subsection: string
  subsectionWeight: number | null
  scoresColumnChartProps: ColumnChartProps | null
  scoreHistoryChartProps: ScoreHistoryChartProps | null
  indicatorChartPropsList: [ColumnChartProps, ScoreHistoryChartProps][]
  className?: string
}

const ESGSubsection: React.FC<Props> = (props) => {
  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div className={classNames('font-work-sans', props.className)}>
      <div className="text-xl font-semibold">{props.subsection}</div>
      {props.subsectionWeight !== null && (
        <ScoreCard
          className="mt-8"
          color="primary"
          title="Peso"
          value={(100 * props.subsectionWeight).toFixed(0) + '%'}
        />
      )}
      {props.scoresColumnChartProps !== null && (
        <div className="mt-10 grid grid-cols-2 gap-x-4 gap-y-16">
          <ColumnChart {...props.scoresColumnChartProps!} />
          <ScoreHistoryChart {...props.scoreHistoryChartProps!} />
        </div>
      )}
      {props.indicatorChartPropsList.length > 0 && (
        <ESGSubsubsection
          className="mt-10"
          data={props.indicatorChartPropsList}
        />
      )}
    </div>
  )
}

export default ESGSubsection
