import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import {
  ENVIRONMENTAL,
  GLOBAL_VARIABLES,
  GOVERNANCE,
  SOCIAL,
} from '../../../../utils/constants'
import { useImperativeQuery } from '../../../../utils/hooks'
import DataTable, { RowData } from '../../components/DataTable'
import { useSectoralReport } from '../../contexts/SectoralReport'

interface Props {
  className?: string
}

interface QueryResult {
  multiCompanyFramework: {
    sections: Section[]
  }
}

interface Section {
  title: string
  subsections: Subsection[]
}

interface Subsection {
  quantIndicators: {
    title: string
    values: {
      company: { name: string }
      values: {
        year: number
        value: number
      }[]
    }[]
    unit: string
  }[]
}

const query = loader('./query.graphql')

const buildRowData = (
  framework: QueryResult['multiCompanyFramework'],
  year: number
): RowData[] => {
  const sectionOrder = [GLOBAL_VARIABLES, ENVIRONMENTAL, SOCIAL, GOVERNANCE]
  return [...framework.sections]
    .sort(
      (sa, sb) =>
        sectionOrder.indexOf(sa.title) - sectionOrder.indexOf(sb.title)
    )
    .map((section) => buildRowDataFromSection(section, year))
    .flat()
}

const buildRowDataFromSection = (section: Section, year: number): RowData[] =>
  section.subsections
    .map((subsection) =>
      buildRowDataFromSubsection(section.title, subsection, year)
    )
    .flat()

const buildRowDataFromSubsection = (
  section: string,
  subsection: Subsection,
  year: number
): RowData[] =>
  subsection.quantIndicators.map((indicator) => ({
    section:
      section === GLOBAL_VARIABLES
        ? null
        : (section.toLocaleLowerCase() as ESGSection),
    indicator: indicator.title,
    unit: indicator.unit,
    companyValues: Object.fromEntries(
      indicator.values.map((company) => [
        company.company.name,
        company.values.find((value) => value.year === year)?.value ?? null,
      ])
    ),
  }))

const DataTableContainer: React.FC<Props> = (props) => {
  const [rowData, setRowData] = useState<RowData[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingSector, companies, year } = useSectoralReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    setLoading(true)

    if (loadingSector) {
      return
    }

    makeQuery({
      companyIds: companies.map((company) => company.id),
      year,
    }).then((data: QueryResult) => {
      setRowData(buildRowData(data.multiCompanyFramework, year!))
      setLoading(false)
    })
  }, [loadingSector, makeQuery, companies, year])

  return (
    <DataTable
      className={props.className}
      loading={loading}
      companies={companies.map((company) => company.name)}
      rowData={rowData}
    />
  )
}

export default DataTableContainer
