import { Fragment } from 'react'

import NumericInput from '../../../../components/NumericInput'

export interface DataField {
  label: string
  field: ExtendedRemunerationDataField
  value: number | null
}

interface Props {
  className?: string
  title: string
  fields: DataField[]
  disabled?: boolean
  onChange?: (field: ExtendedRemunerationDataField, value: number | null) => any
}

const DataFieldSection: React.FC<Props> = (props) => (
  <div className={props.className}>
    <div className="text-xl font-bold">{props.title}</div>
    <div className="mt-4 grid grid-cols-[auto_12rem] items-center gap-2">
      {props.fields.map((field) => (
        <Fragment key={field.field}>
          <span>{field.label}:</span>
          <NumericInput
            className="border px-1"
            value={field.value}
            disabled={props.disabled}
            onChange={(value) => props.onChange?.(field.field, value)}
          />
        </Fragment>
      ))}
    </div>
  </div>
)

export default DataFieldSection
