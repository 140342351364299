import { uniq } from 'ramda'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { isEstimateDate } from '../../utils/remuneration'
import RemunerationTable from '../RemunerationTable'

interface Props {
  className?: string
  data: RemunerationData
  type: 'council' | 'directors'
  includeEstimates?: boolean
}

const RemunerationReportTable: FunctionComponent<Props> = (props) => {
  const [year, setYear] = useState<string>('')

  const availableYears = useMemo(() => {
    const allYears = Object.values(props.data)
      .map((companyData) =>
        companyData[`${props.type}Remuneration`].data.map((data) => data.date)
      )
      .flat(2)
      .filter(
        (year): year is string =>
          typeof year === 'string' &&
          (props.includeEstimates || !isEstimateDate(year))
      )

    return uniq(allYears).sort((ya, yb) => parseInt(yb) - parseInt(ya))
  }, [props.data, props.includeEstimates, props.type])

  useEffect(() => {
    setYear(availableYears[0] ?? '')
  }, [availableYears])

  return (
    <div className={props.className}>
      <div className="flex justify-between">
        <span className="font-bold">
          {props.type === 'council' ? 'Conselho de Administração' : 'Diretoria'}
        </span>
        <select
          className="border"
          value={year}
          onChange={(event) => setYear(event.target.value)}
        >
          {availableYears.map((year) => (
            <option key={year} label={year} value={year} />
          ))}
        </select>
      </div>
      <RemunerationTable
        className="mt-2"
        data={props.data}
        type={props.type}
        year={year}
      />
    </div>
  )
}

export default RemunerationReportTable
