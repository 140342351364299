import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface Props {
  series: {
    name: string
    data: { x: number; y: number }[]
  }[]
}

interface IActiveContext {
  activeSeries: Record<string, boolean>
  changeActive: (name: string, active: boolean) => void
}

const ActiveContext = createContext<IActiveContext>(
  undefined as unknown as IActiveContext
)

export const ActiveContextProvider: React.FC<Props> = (props) => {
  const [activeSeries, setActiveSeries] = useState<Record<string, boolean>>({})

  useEffect(() => {
    setActiveSeries(
      Object.fromEntries(
        props.series.map((series, idx) => [series.name, idx === 0])
      )
    )
  }, [props.series])

  const changeActive = useCallback((name: string, active: boolean) => {
    setActiveSeries((cur) => ({
      ...cur,
      [name]: active,
    }))
  }, [])

  const value = useMemo(
    () => ({ activeSeries, changeActive }),
    [activeSeries, changeActive]
  )

  return (
    <ActiveContext.Provider value={value}>
      {props.children}
    </ActiveContext.Provider>
  )
}

export const useActive = () => useContext(ActiveContext)
