import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import { useUser } from '../../contexts/User'
import { Path } from '../../utils/constants'
import CompaniesContainer from './containers/Companies'
import CompanyReportContainer from './containers/CompanyReport'
import { CompanyReportProvider } from './contexts/CompanyReport'

interface Props {
  className?: string
}

const CompanyReportPage: React.FC<Props> = (props) => {
  const { email } = useUser()
  const history = useHistory()

  if (!email) {
    history.push(Path.LOGIN)
  }

  return (
    <Switch>
      <Route path={Path.REPORTS_COMPANY} exact>
        <CompaniesContainer className={props.className} />
      </Route>
      <Route>
        <CompanyReportProvider>
          <CompanyReportContainer className={props.className} />
        </CompanyReportProvider>
      </Route>
    </Switch>
  )
}

export default CompanyReportPage
