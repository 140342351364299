import { RegularChart } from '@jgp-er-dev/charts'
import { toPng } from 'html-to-image'
import { mergeDeepRight } from 'ramda'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

interface IChartRendererContext {
  getChartImages: (charts: any[]) => Promise<string[]>
}

const ChartRendererContext = createContext<IChartRendererContext>(
  {} as IChartRendererContext
)

export const ChartRendererContextProvider: React.FC = (props) => {
  const [curIdx, setCurIdx] = useState<number | null>(null)
  const [chartConfigs, setChartConfigs] = useState<any[]>([])
  const [chartImages, setChartImages] = useState<string[]>([])

  const getChartImagesResolvePromiseRef =
    useRef<((images: string[]) => void) | null>(null)

  const getChartImages = useCallback((charts: any[]) => {
    setChartConfigs(charts)
    setChartImages([])
    setCurIdx(0)

    return new Promise<string[]>((resolve) => {
      getChartImagesResolvePromiseRef.current = resolve
    })
  }, [])

  useEffect(() => {
    if (curIdx === null && getChartImagesResolvePromiseRef.current !== null) {
      getChartImagesResolvePromiseRef.current(chartImages)
      getChartImagesResolvePromiseRef.current = null
      setChartConfigs([])
    }
  }, [curIdx, chartImages])

  const value = useMemo(() => ({ getChartImages }), [getChartImages])

  return (
    <ChartRendererContext.Provider value={value}>
      {props.children}
      {curIdx !== null && (
        <div className="fixed top-0 w-full" style={{ zIndex: -1 }}>
          <RegularChart
            key={curIdx}
            chartProps={{
              ...mergeDeepRight(chartConfigs[curIdx], {
                options: {
                  chart: {
                    // A4 proportion sizing that looks somewhat ok
                    height: 396,
                    width: 564,
                  },
                  plotOptions: {
                    series: {
                      animation: false,
                    },
                  },
                },
              }),
              id: 'rendered-chart',
              callback: (chart: any) => {
                toPng(chart.container)
                  .then((image) => {
                    setChartImages((curImages) => curImages.concat(image))
                    if (curIdx + 1 >= chartConfigs.length) {
                      setCurIdx(null)
                    } else {
                      setCurIdx(curIdx + 1)
                    }
                  })
                  .catch((err) => console.error(err))
              },
            }}
          />
        </div>
      )}
    </ChartRendererContext.Provider>
  )
}

export const useChartRenderer = () => {
  const ctx = useContext(ChartRendererContext)

  if (!ctx) {
    throw new Error(
      'useChartRenderer must be used within a ChartRendererContextProvider'
    )
  }

  return ctx
}
