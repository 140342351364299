import { useHistory } from 'react-router-dom'

import { useUser } from '../../contexts/User'
import { Path } from '../../utils/constants'
import StatisticsContainer from './containers/Statistics'

interface Props {
  className?: string
}

const ScoreStatisticsPage: React.FC<Props> = (props) => {
  const { email } = useUser()
  const history = useHistory()

  if (!email) {
    history.push(Path.LOGIN)
    return null
  }

  return <StatisticsContainer className={props.className} />
}

export default ScoreStatisticsPage
