import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  className?: string
  url: string | null
}

const LinkGridLink: FunctionComponent<Props> = (props) =>
  !props.url ? (
    <span className={props.className}>{props.children}</span>
  ) : props.url.startsWith('/') ? (
    <Link className={props.className} to={props.url ?? '#'} target="_blank">
      {props.children}
    </Link>
  ) : (
    <a
      className={props.className}
      href={props.url}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  )

export default LinkGridLink
