import { useRef, useState } from 'react'

import Button from '../../../../components/Button'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import Modal from '../../../../components/Modal'
import { useToast } from '../../../../contexts/Toast'
import { displayModal } from '../../../../utils/modal'

type Props = React.ComponentProps<typeof Modal> & {
  onSave?: (name: string) => Promise<any>
  savedReportNames: string[]
  lastReportName: string
}

const ERROR_CLASS = 'border-red-500'

const RemunerationSaveModal: React.FC<Props> = (props) => {
  const [saving, setSaving] = useState<boolean>(false)

  const { enqueueToast } = useToast()

  const inputRef = useRef<HTMLInputElement>(null)

  const handleSave = () => {
    const name = inputRef.current?.value?.trim()

    if (!name) {
      inputRef.current?.classList.add(ERROR_CLASS)
      return
    }

    const proceedToSave = () => {
      if (!props.onSave) {
        return
      }

      setSaving(true)
      props
        .onSave(name)
        .then(() => {
          enqueueToast({
            type: 'success',
            message: 'Relatório salvo com sucesso',
          })
          props.onClose?.()
        })
        .catch((err: any) => {
          console.error('Failed to save report:', err)
          enqueueToast({
            type: 'fail',
            message: 'Ocorreu um erro ao salvar o relatório',
          })
        })
        .finally(() => {
          setSaving(false)
        })
    }

    if (props.savedReportNames.includes(name)) {
      displayModal({
        message:
          'Já existe um relatório salvo com este nome. Deseja sobrescrever?',
        props: {
          open: true,
          onClose: () => {},
        },
        buttons: [
          {
            label: 'Sobrescrever',
            props: {
              variant: 'primary',
              className: 'h-8 w-28',
              onClick: proceedToSave,
            },
          },
          {
            label: 'Cancelar',
            props: {
              variant: 'secondary',
              className: 'h-8 w-28',
            },
          },
        ],
      })

      return
    }

    proceedToSave()
  }

  return (
    <Modal {...props} dismissable={!saving && props.dismissable}>
      <div className="max-w-screen relative min-w-[40rem] p-8">
        <LoadingOverlay
          className="absolute inset-0 flex items-center justify-center bg-opacity-70"
          type="local"
          show={saving}
        />
        <div className="text-2xl font-bold">Salvar relatório</div>
        <div className="mt-4">Digite o nome do relatório a ser salvo:</div>
        <input
          ref={inputRef}
          className="w-full border px-1"
          defaultValue={props.lastReportName}
          onChange={() => inputRef.current?.classList.remove(ERROR_CLASS)}
        />
        <div className="mt-8 flex justify-end gap-4">
          <Button variant="primary" className="h-8 w-28" onClick={handleSave}>
            Salvar
          </Button>
          <Button
            variant="secondary"
            className="h-8 w-28"
            onClick={props.onClose}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default RemunerationSaveModal
