import React from 'react'

const Logo: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 32" {...props}>
    <path
      fill="#ffffff"
      fillRule="evenodd"
      d="M42.217 13.749v6.627h5.385c-1.567 2.336-4.05 3.507-7.227 3.507-2.687 0-4.82-.81-6.312-2.388-1.493-1.578-2.218-3.54-2.218-5.843 0-2.388.768-4.35 2.26-5.885 1.493-1.536 3.455-2.304 5.8-2.304 2.9 0 5.332 1.237 6.549 3.153l6.436-3.79c-1.299-2.05-2.998-3.746-5.344-4.983C45.243.606 42.592 0 39.82 0c-4.392 0-8.103 1.493-11.088 4.52-2.985 3.029-4.478 6.696-4.478 11.09 0 4.392 1.493 8.06 4.478 11.045 3.028 2.985 6.866 4.478 11.515 4.478 3.837 0 7.11-1.23 9.628-3.352 2.04-1.72 3.585-4.025 4.526-6.742l.399.147c-.408 1.826-1.102 4.797-4.604 8.103v1.204h6.598V13.75H42.217zm29.08 0h-3.63V7.933h3.63c1.562 0 2.772 1.17 2.772 2.888s-1.21 2.928-2.772 2.928zm7.58-10.255C76.89 1.59 74.45.64 71.513.64H60.033v29.853h7.634v-9.586h3.847c2.936 0 5.377-.951 7.362-2.854 1.986-1.903 2.978-4.343 2.978-7.28 0-2.936-.992-5.377-2.978-7.28zM0 25.163l6.525-3.796c.853 1.663 1.876 2.345 3.796 2.345 2.175 0 3.284-1.023 3.284-3.028V.64h7.634v20.172c0 3.199-.981 5.758-2.986 7.592-1.961 1.834-4.606 2.73-7.932 2.73-4.905 0-8.317-2.005-10.321-5.971z"
    />
  </svg>
)

export default Logo
