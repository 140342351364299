import React from 'react'

const BasketIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 24"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path d="M30 9.91661V10.7499C30 11.4403 29.4404 11.9999 28.75 11.9999H28.3333L26.9733 21.5202C26.7973 22.7518 25.7426 23.6666 24.4984 23.6666H5.50156C4.25745 23.6666 3.20266 22.7518 3.02667 21.5202L1.66667 11.9999H1.25C0.559635 11.9999 0 11.4403 0 10.7499V9.91661C0 9.22625 0.559635 8.66661 1.25 8.66661H4.75734L10.3188 1.01968C10.8602 0.275309 11.9025 0.110673 12.647 0.652079C13.3914 1.19349 13.556 2.23583 13.0146 2.98026L8.87901 8.66661H21.121L16.9854 2.9802C16.444 2.23583 16.6086 1.19343 17.3531 0.652027C18.0974 0.110621 19.1398 0.275205 19.6812 1.01963L25.2427 8.66661H28.75C29.4404 8.66661 30 9.22625 30 9.91661ZM16.25 19.0833V13.2499C16.25 12.5596 15.6904 11.9999 15 11.9999C14.3096 11.9999 13.75 12.5596 13.75 13.2499V19.0833C13.75 19.7736 14.3096 20.3333 15 20.3333C15.6904 20.3333 16.25 19.7736 16.25 19.0833ZM22.0833 19.0833V13.2499C22.0833 12.5596 21.5237 11.9999 20.8333 11.9999C20.143 11.9999 19.5833 12.5596 19.5833 13.2499V19.0833C19.5833 19.7736 20.143 20.3333 20.8333 20.3333C21.5237 20.3333 22.0833 19.7736 22.0833 19.0833ZM10.4167 19.0833V13.2499C10.4167 12.5596 9.85703 11.9999 9.16667 11.9999C8.4763 11.9999 7.91667 12.5596 7.91667 13.2499V19.0833C7.91667 19.7736 8.4763 20.3333 9.16667 20.3333C9.85703 20.3333 10.4167 19.7736 10.4167 19.0833Z" />
  </svg>
)

export default BasketIcon
