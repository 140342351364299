import classNames from 'classnames'
import React from 'react'

import styles from './ESGPlatformHeader.module.css'

interface Props {
  className?: string
}

const ESGPlatformHeader: React.FC<Props> = (props) => (
  <div
    className={classNames(
      'flex h-36 justify-center',
      styles.header,
      props.className
    )}
  >
    <div className="flex w-full max-w-screen-xl items-center px-4 text-2xl">
      <span className="font-bold">
        Plataforma <span className="font-light">ESG</span>
      </span>
    </div>
  </div>
)

export default ESGPlatformHeader
