import { FetchResult, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { equals } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useImperativeQuery } from '../../../../utils/hooks'
import PersonalizedReport from '../../components/PersonalizedReport'
import { GLOBAL_VARIABLES_SLUG } from '../../utils/constants'
import { usePersonalizedReportReducer } from './reducer'
import type {
  MetricWeighting,
  ReportChartData,
  UserBasket,
  UserPersonalizedReport,
} from './typings'

interface Props {
  className?: string
}

interface QueryResult {
  metricIndicators: WithGraphQLError<MetricIndicators>
  metricWeightings: MetricWeighting[]
  userBaskets: UserBasket[]
  userPersonalizedReports: UserPersonalizedReport[]
}

interface ComputeReportQueryResult {
  computeReport:
    | {
        metric: ReportChartData
        indicators: ReportChartData
        weights: ReportChartData
        error: null
      }
    | { error: string }
}

interface UserReportsQueryResult {
  userPersonalizedReports: UserPersonalizedReport[]
}

interface ReportQueryResult {
  personalizedReport: UserPersonalizedReport | null
}

interface CreateReportMutationResult {
  createUserPersonalizedReport:
    | {
        report: { id: string }
        error: null
      }
    | { error: string }
}

const query = loader('./query.graphql')
const reportQuery = loader('./reportQuery.graphql')
const computeReportQuery = loader('./computeReportQuery.graphql')
const userReportsQuery = loader('./userReportsQuery.graphql')
const createReportMutation = loader('./mutationCreateReport.graphql')
const deleteReportMutation = loader('./mutationDeleteReport.graphql')
const saveReportMutation = loader('./mutationSaveReport.graphql')

const PersonalizedReportContainer: React.FC<Props> = (props) => {
  const { search } = useLocation()
  const [state, dispatch] = usePersonalizedReportReducer()

  const reportId = useMemo(
    () => new URLSearchParams(search).get('id') ?? null,
    [search]
  )

  const makeQuery = useImperativeQuery(query)
  const makeReportQuery = useImperativeQuery(reportQuery)
  const makeComputeReportQuery = useImperativeQuery(computeReportQuery)
  const makeUserReportsQuery = useImperativeQuery(userReportsQuery, {
    fetchPolicy: 'network-only',
  })
  const [createReport] = useMutation(createReportMutation)
  const [deleteReport] = useMutation(deleteReportMutation)
  const [saveReport] = useMutation(saveReportMutation)

  useEffect(() => {
    dispatch({ type: 'SET_STATE', loadingSettingsData: true })

    const reportPromise = reportId
      ? makeReportQuery({ id: reportId })
          .then((data: ReportQueryResult) => data.personalizedReport)
          .catch((err) => {
            console.error('Failed to fetch report:', err)
            return null
          })
      : null

    makeQuery()
      .then(async (data: QueryResult) => {
        if (data.metricIndicators.error !== null) {
          console.error(
            'Failed to fetch metric indicators:',
            data.metricIndicators.error
          )
        } else {
          dispatch({
            type: 'SET_STATE',
            metricIndicatorsData: data.metricIndicators,
          })
        }
        dispatch({
          type: 'SET_STATE',
          metricWeightingsData: data.metricWeightings,
          basketOptions: data.userBaskets.map((basket) => ({
            label: basket.name,
            value: basket.id,
          })),
          userReports: data.userPersonalizedReports,
        })

        const report = await reportPromise

        if (report) {
          dispatch({ type: 'LOAD_REPORTS_SETTINGS', report })
        }
      })
      .catch((err) => {
        console.error(
          'Failed PersonalizedReportContainer settings data query:',
          err
        )
      })
      .finally(() =>
        dispatch({ type: 'SET_STATE', loadingSettingsData: false })
      )
  }, [dispatch, makeQuery, makeReportQuery, reportId])

  const handleSubmit = () => {
    if (
      !state.indicator ||
      !state.basket ||
      !state.weighting ||
      !state.weight
    ) {
      return
    }

    dispatch({
      type: 'SET_STATE',
      loadingMetricChartData: true,
      reportMetricData: null,
      reportIndicatorsData: null,
      reportWeightsData: null,
    })

    makeComputeReportQuery({
      input: {
        indicatorPath: state.indicator.value,
        basketId: state.basket.value,
        weighting: {
          name: state.weighting.value,
          weight: state.weight.value,
        },
      },
    })
      .then((data: ComputeReportQueryResult) => {
        if (data.computeReport.error !== null) {
          throw new Error(data.computeReport.error)
        } else {
          dispatch({
            type: 'SET_STATE',
            reportMetricData: data.computeReport.metric,
            reportIndicatorsData: data.computeReport.indicators,
            reportWeightsData:
              state.weight?.value === 'equal_weight'
                ? null
                : data.computeReport.weights,
          })
        }
      })
      .catch((err) => {
        console.error('Failed to fetch report data:', err)
      })
      .finally(() =>
        dispatch({ type: 'SET_STATE', loadingMetricChartData: false })
      )
  }

  const handleSave = async () => {
    const savedReport = state.userReports.find(
      (report) => report.name === state.reportName
    )

    const promise = savedReport
      ? saveReport({
          variables: {
            input: {
              id: savedReport.id,
              ...currentReport,
            },
          },
        })
      : createReport({
          variables: {
            input: currentReport,
          },
        })

    await promise.then((res) => {
      const error =
        res.data.createUserPersonalizedReport?.error ??
        res.data.saveUserPersonalizedReport?.error
      if (error) {
        throw new Error(error)
      }
      updateUserReports()
    })
  }

  const handleOpenReport = (id: string) => {
    const report = state.userReports.find((report) => report.id === id)

    if (report) {
      dispatch({ type: 'LOAD_REPORTS_SETTINGS', report })
    }
  }

  const handleDeleteReport = async (id: string) =>
    deleteReport({
      variables: { input: { id } },
    }).then(updateUserReports)

  const handleAnonymousShare = async () =>
    createReport({
      variables: {
        input: {
          ...currentReport,
          createAsAnonymous: true,
        },
      },
    })
      .then((res: FetchResult<CreateReportMutationResult>) => {
        const data = res.data?.createUserPersonalizedReport

        if (!data) {
          throw new Error('No data returned from mutation')
        } else if (data.error !== null) {
          throw new Error(data.error)
        } else {
          return data.report.id
        }
      })
      .catch((err) => {
        console.error('Error creating report:', err)
        return null
      })

  const updateUserReports = async () =>
    makeUserReportsQuery()
      .then((data: UserReportsQueryResult) =>
        dispatch({
          type: 'SET_STATE',
          userReports: data.userPersonalizedReports,
        })
      )
      .catch((err) => {
        console.error('Failed to fetch user reports:', err)
      })

  const currentReport = useMemo(
    () => ({
      name: state.reportName,
      indicatorPath: state.indicator?.value ?? null,
      basketId: state.basket?.value ?? null,
      weighting: {
        name: state.weighting?.value ?? null,
        weight: state.weight?.value ?? null,
      },
    }),
    [state]
  )

  const sectionOptions = useMemo(
    () => state.metricIndicatorsData.sections,
    [state.metricIndicatorsData]
  )

  const subsectionOptions = useMemo(() => {
    if (!state.section || state.section.value[0] === GLOBAL_VARIABLES_SLUG) {
      return []
    }

    return state.metricIndicatorsData.subsections.filter(
      (subsection) => subsection.value[0] === state.section!.value[0]
    )
  }, [state.section, state.metricIndicatorsData])

  const indicatorOptions = useMemo(() => {
    if (state.section?.value[0] === GLOBAL_VARIABLES_SLUG) {
      return state.metricIndicatorsData.indicators.filter(
        (indicator) => indicator.value[0] === state.section!.value[0]
      )
    } else if (!state.subsection) {
      return []
    } else {
      return state.metricIndicatorsData.indicators.filter((indicator) =>
        equals(indicator.value.slice(0, 2), state.subsection!.value)
      )
    }
  }, [state.section, state.subsection, state.metricIndicatorsData])

  const weightingOptions = useMemo(
    () =>
      state.metricWeightingsData.map((weighting) => ({
        label: weighting.label,
        value: weighting.name,
      })),
    [state.metricWeightingsData]
  )

  const weightOptions = useMemo(
    () =>
      state.weighting
        ? state.metricWeightingsData.find(
            (weighting) => weighting.name === state.weighting!.value
          )?.weights ?? []
        : [],
    [state.weighting, state.metricWeightingsData]
  )

  return (
    <PersonalizedReport
      className={props.className}
      loadingMetricChartData={state.loadingMetricChartData}
      loadingSettingsData={state.loadingSettingsData}
      basket={state.basket}
      basketOptions={state.basketOptions}
      indicator={state.indicator}
      indicatorOptions={indicatorOptions}
      section={state.section}
      sectionOptions={sectionOptions}
      subsection={state.subsection}
      subsectionOptions={subsectionOptions}
      weight={state.weight}
      weightOptions={weightOptions}
      weighting={state.weighting}
      weightingOptions={weightingOptions}
      reportName={state.reportName}
      reportMetricData={state.reportMetricData}
      reportIndicatorsData={state.reportIndicatorsData}
      reportWeightsData={state.reportWeightsData}
      userReports={state.userReports}
      onBasketChange={(basket) => dispatch({ type: 'SET_STATE', basket })}
      onIndicatorChange={(indicator) =>
        dispatch({ type: 'SET_STATE', indicator })
      }
      onSectionChange={(section) =>
        dispatch({ type: 'CHANGE_SECTION', section })
      }
      onSubsectionChange={(subsection) =>
        dispatch({ type: 'CHANGE_SUBSECTION', subsection })
      }
      onWeightChange={(weight) => dispatch({ type: 'SET_STATE', weight })}
      onWeightingChange={(weighting) =>
        dispatch({ type: 'CHANGE_WEIGHTING', weighting })
      }
      onSubmit={handleSubmit}
      onReportNameChange={(reportName) =>
        dispatch({ type: 'SET_STATE', reportName })
      }
      onSave={handleSave}
      onOpenReport={handleOpenReport}
      onDeleteReport={handleDeleteReport}
      onAnonymousShare={handleAnonymousShare}
    />
  )
}

export default PersonalizedReportContainer
