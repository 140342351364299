import { RegularChart } from '@jgp-er-dev/charts'
import classNames from 'classnames'
import React, { useMemo } from 'react'

import { commonPieChartOptions } from '../../utils/constants'

export interface Props {
  subsections: SubsectionData[]
  className?: string
}

interface SubsectionData {
  name: string
  weight: number
}

const formatData = (subsections: SubsectionData[]) => {
  const weightSum = subsections.reduce(
    (acc, subsection) => acc + subsection.weight,
    0
  )

  return subsections.map((subsection) => ({
    name: subsection.name,
    y: (100 * subsection.weight) / weightSum,
  }))
}

export const getChartConfig = (props: Props) => {
  const series = [
    {
      name: 'Peso',
      data: formatData(props.subsections),
      dataLabels: {
        format: '{point.y:.0f}%',
        distance: 5,
        connectorWidth: 0,
      },
      showInLegend: true,
      tooltip: {
        valueSuffix: '%',
        valueDecimals: 2,
      },
    },
  ]

  return {
    options: {
      ...commonPieChartOptions,
      title: { text: '' },
      series: series as any,
    },
  }
}

const SubsectionPieChart: React.FC<Props> = (props) => {
  const chartProps = useMemo(() => getChartConfig(props), [props])

  return (
    <div
      className={classNames('grid h-chart grid-cols-1 p-2', props.className)}
    >
      <RegularChart chartProps={chartProps} />
    </div>
  )
}

export default SubsectionPieChart
