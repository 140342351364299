import type { ColDef } from 'ag-grid-community'
import { type CustomCellRendererProps, AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useState } from 'react'

import { TrashIcon } from '../../../../components/icons'
import { useToast } from '../../../../contexts/Toast'
import {
  deleteControversy,
  getControversyList,
} from '../../clients/controversies'
import ControversiesActionModal, { Action } from '../ControversiesActionModal'

const ControversiesLibrary: React.FC = () => {
  const [controversies, setControversies] = useState<
    Awaited<ReturnType<typeof getControversyList>>
  >([])
  const [error, setError] = useState<string | null>(null)
  const [curAction, setCurAction] = useState<
    (Action & { company: Pick<Company, 'id' | 'name'> }) | null
  >(null)
  const [isPending, setIsPending] = useState<boolean>(false)
  const [filterStr, setFilterStr] = useState<string>('')

  const { enqueueToast } = useToast()

  const refetchControversies = useCallback(() => {
    getControversyList().then(setControversies)
  }, [])

  useEffect(() => {
    refetchControversies()
  }, [refetchControversies])

  const columnDefs: ColDef[] = [
    {
      headerName: 'Empresa',
      field: 'company.name',
    },
    {
      headerName: 'Tipo',
      field: 'controversy.type',
      initialWidth: 140,
    },
    {
      flex: 1,
      headerName: 'Título',
      field: 'controversy.data.title',
    },
    {
      flex: 1,
      headerName: 'Snippet',
      field: 'controversy.data.snippet',
    },
    {
      headerName: 'Link',
      field: 'controversy.data.link',
      cellRenderer: (props: CustomCellRendererProps) => (
        <a className="text-jgp-success-main underline" href={props.value}>
          {props.value}
        </a>
      ),
    },
    {
      sortable: false,
      resizable: false,
      headerName: '',
      suppressHeaderMenuButton: true,
      width: 50,
      cellRenderer: (props: CustomCellRendererProps) => (
        <button
          onClick={() => {
            setCurAction({
              intent: 'remove',
              hit: props.data.controversy.data,
              company: props.data.company,
            })
          }}
          title="Remover controvérsia"
        >
          <TrashIcon className="h-4 w-4 text-jgp-error-dark" />
        </button>
      ),
    },
  ]

  const defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    cellClass: 'leading-tight flex items-center',
  }

  const handleAction = () => {
    if (!curAction || curAction.intent !== 'remove') {
      return
    }

    setIsPending(true)
    setError(null)

    deleteControversy({
      companyId: curAction.company.id,
      controversy: { data: curAction.hit },
    })
      .then(() => {
        enqueueToast({
          message: `Controvérsia removida com sucesso`,
          type: 'success',
        })
        setCurAction(null)
      })
      .then(refetchControversies)
      .catch((err) => {
        console.error('Failed to insert controversy:', err)
        setError('Ocorreu um erro inexperado. Por favor, tente novamente.')
      })
      .finally(() => setIsPending(false))
  }

  return (
    <div className="relative pb-20 pl-10 pr-4 font-work-sans flex flex-col gap-y-4 flex-grow">
      <h1 className="text-3xl font-semibold">Biblioteca de controvérsias</h1>
      <div className="flex items-center gap-x-2">
        <span>Busca:</span>
        <input
          value={filterStr}
          className="flex-grow border px-1 h-[32px] focus:border-opacity-0 focus:outline-none focus:ring-2 focus:ring-jgp-success-dark"
          onChange={(event) => setFilterStr(event.target.value)}
        />
      </div>
      <div className="ag-theme-alpine h-[80vh]">
        <AgGridReact
          quickFilterText={filterStr}
          rowData={controversies}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={(params) => params.data._id}
          overlayNoRowsTemplate="<span>Não há controvérsias para exibir</span>"
        />
      </div>
      {curAction && (
        <ControversiesActionModal
          action={curAction}
          isPending={isPending}
          company={curAction.company}
          error={error}
          onClose={() => {
            setCurAction(null)
            setError(null)
          }}
          onAction={handleAction}
        />
      )}
    </div>
  )
}

export default ControversiesLibrary
