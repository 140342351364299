import React from 'react'

const DatabaseIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.25 3.75V14.25C2.25 15.495 5.25 16.5 9 16.5C12.75 16.5 15.75 15.495 15.75 14.25V3.75"
      fill="currentColor"
    />
    <path
      d="M2.25 3.75V14.25C2.25 15.495 5.25 16.5 9 16.5C12.75 16.5 15.75 15.495 15.75 14.25V3.75"
      stroke="#F9F9F9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 6C12.7279 6 15.75 4.99264 15.75 3.75C15.75 2.50736 12.7279 1.5 9 1.5C5.27208 1.5 2.25 2.50736 2.25 3.75C2.25 4.99264 5.27208 6 9 6Z"
      fill="currentColor"
      stroke="#F9F9F9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 9C15.75 10.245 12.75 11.25 9 11.25C5.25 11.25 2.25 10.245 2.25 9"
      stroke="#F9F9F9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default DatabaseIcon
