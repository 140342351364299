import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import Spinner from '../Spinner'

interface Props {
  type: 'overlay' | 'local'
  className?: string
  show?: boolean
}

const LoadingOverlay: React.FC<Props> = (props) => {
  const [active, setActive] = useState<boolean>(!!props.show)

  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    if (!props.show) {
      timeoutRef.current = setTimeout(
        () => setActive(false),
        300
      ) as unknown as number
    } else {
      setActive(true)
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [props.show])

  return active ? (
    <div
      className={classNames(
        'z-50 bg-white',
        {
          'fixed inset-0 flex items-center justify-center':
            props.type === 'overlay',
          'opacity-0 transition-opacity duration-300': !props.show,
        },
        props.className
      )}
    >
      <Spinner className="text-jgp-success-main" />
    </div>
  ) : null
}

export default LoadingOverlay
