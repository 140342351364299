import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import { slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import ESGSection from '../../components/ESGSection'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface Props {
  section: ESGSection
}

interface QueryResult {
  companyFramework: {
    sections: {
      title: string
      score: number
      ranking: number[]
      peerAverageScore: { value: number }
      weight: number
      subsections: {
        title: string
      }[]
    }[]
  }
}

const query = loader('./query.graphql')

const ESGSectionContainer: React.FC<Props> = (props) => {
  const [score, setScore] = useState<number | null>(null)
  const [ranking, setRanking] = useState<number[]>([])
  const [peerAverageScore, setPeerAverageScore] = useState<number | null>(null)
  const [weight, setWeight] = useState<number | null>(null)
  const [subsections, setSubsections] = useState<
    { id: string; label: string }[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    setLoading(true)

    if (loadingCompany) {
      return
    }

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        const section = data.companyFramework.sections.find(
          (section) => slugify(section.title) === props.section
        )

        if (!section) {
          throw new Error(`Section ${props.section} does not exist`)
        }

        setScore(section.score)
        setRanking(section.ranking)
        setPeerAverageScore(section.peerAverageScore.value)
        setWeight(section.weight)
        setSubsections([
          { id: 'overview', label: 'Visão geral' },
          ...(section?.subsections.map((subsection) => ({
            id: slugify(subsection.title),
            label: subsection.title,
          })) ?? []),
        ])
        setLoading(false)
      }
    )
  }, [props.section, loadingCompany, makeQuery, companyData, year])

  return (
    <ESGSection
      loading={loading}
      section={props.section}
      subsections={subsections}
      score={score}
      ranking={ranking}
      peerAverageScore={peerAverageScore}
      weight={weight}
    />
  )
}

export default ESGSectionContainer
