export const formatNumber = (
  value: number,
  {
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    ...rest
  }: Intl.NumberFormatOptions = {}
) => {
  const options = {
    minimumFractionDigits,
    maximumFractionDigits,
    ...rest,
  }

  return new Intl.NumberFormat('pt-BR', options).format(value)
}

export const parseFormattedNumber = (value: string) =>
  parseFloat(value.replaceAll('.', '').replaceAll(',', '.'))
