import classNames from 'classnames'
import React from 'react'

interface Props {
  label: string
  className?: string
}

const LabeledComponent: React.FC<Props> = (props) => (
  <div className={classNames('flex flex-col', props.className)}>
    <span className="px-1 text-xs">{props.label}</span>
    {props.children}
  </div>
)

export default LabeledComponent
