import { loader } from 'graphql.macro'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useImperativeQuery } from '../../utils/hooks'

interface ICompaniesContext {
  loading: boolean
  companies: CompanyBase[]
}

interface QueryResult {
  companies: {
    id: string
    name: string
    sector: {
      name: string
    }
    frameworks: {
      score: number
    }[]
  }[]
}

const query = loader('./query.graphql')

const CompaniesContext = createContext<ICompaniesContext>(
  {} as ICompaniesContext
)

export const CompaniesContextProvider: React.FC = (props) => {
  const [companies, setCompanies] = useState<CompanyBase[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    makeQuery().then((data: QueryResult) => {
      setCompanies(
        data.companies.map((company) => ({
          id: company.id,
          name: company.name,
          peerGroup: company.sector.name,
          desc: '',
          ticker: null,
          hasQuantDataOnly: company.frameworks.every((fw) => fw.score === 0),
        }))
      )
      setLoading(false)
    })
  }, [makeQuery])

  const value = useMemo(() => ({ companies, loading }), [companies, loading])

  return (
    <CompaniesContext.Provider value={value}>
      {props.children}
    </CompaniesContext.Provider>
  )
}

export const useCompanies = () => {
  const ctx = useContext<ICompaniesContext>(CompaniesContext)

  if (!ctx) {
    throw new Error(
      'useCompanies must be used within a CompaniesContextProvider'
    )
  }

  return ctx
}
