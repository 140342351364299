import ColumnChart from '../../../../components/ColumnChart'
import { ArrowSmRightIcon } from '../../../../components/icons'
import { useWatchLoading } from '../../../../contexts/Loading'

interface Props {
  className?: string
  loading: boolean
  goals: DecarbGoal[]
}

export interface DecarbGoal {
  description: string
  indicator: string | null
  unitOfMeasurement: string | null
  comments: string | null
  source: string | null
  data: {
    referenceDate: number
    value: number
  }[]
  goals: {
    referenceDate: number
    value: number
  }[]
}

const DecarbonizationGoals: React.FC<Props> = (props) => {
  useWatchLoading(props.loading)

  return (
    <div className={props.className}>
      {props.goals.length === 0 && (
        <div className="flex items-center justify-center py-8 text-gray-400">
          Sem dados para exibir
        </div>
      )}
      <div className="flex flex-col gap-8">
        {props.goals.map((goal) => (
          <div
            key={goal.description}
            className="grid grid-cols-[min-content_auto] items-center gap-x-2 gap-y-4"
          >
            <ArrowSmRightIcon className="h-8 w-8 text-jgp-success-dark" />
            <div className="text-lg">{goal.description}</div>
            <div className="col-start-2">
              <ColumnChart
                type="vertical"
                title={goal.indicator ?? undefined}
                data={[
                  ...goal.data.map((data) => ({
                    name: data.referenceDate.toString(),
                    value: data.value,
                    type: 'default' as const,
                  })),
                  ...goal.goals.map((data) => ({
                    name: `${data.referenceDate.toString()} (meta)`,
                    value: data.value,
                    type: 'highlighted' as const,
                  })),
                ]}
                unit={goal.unitOfMeasurement ?? undefined}
                dataLabels
              />
              {goal.comments && <div>Comentário: {goal.comments}</div>}
              {goal.source && <div>Fonte: {goal.source}</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DecarbonizationGoals
