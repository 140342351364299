import { loader } from 'graphql.macro'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useImperativeQuery } from '../../utils/hooks'

interface ISectorsContext {
  loading: boolean
  sectors: string[]
}

interface QueryResult {
  peerGroups: string[]
}

const query = loader('./query.graphql')

const SectorsContext = createContext<ISectorsContext>({} as ISectorsContext)

export const SectorsContextProvider: React.FC = (props) => {
  const [sectors, setSectors] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    makeQuery().then((data: QueryResult) => {
      setSectors(data.peerGroups)
      setLoading(false)
    })
  }, [makeQuery])

  const value = useMemo(() => ({ loading, sectors }), [loading, sectors])

  return (
    <SectorsContext.Provider value={value}>
      {props.children}
    </SectorsContext.Provider>
  )
}

export const useSectors = () => {
  const ctx = useContext<ISectorsContext>(SectorsContext)

  if (!ctx) {
    throw new Error('useSectors must be used within a SectorsContextProvider')
  }

  return ctx
}
