import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import ChevronLeft from '../../../../components/icons/ChevronLeft'
import ChevronRight from '../../../../components/icons/ChevronRight'

interface Props {
  list: {
    label: string
    url: string
  }[]
  maxLength: number
  className?: string
}

const PaginatedList: React.FC<Props> = (props) => {
  const [curPage, setCurPage] = useState<number>(0)

  const numPages = useMemo(
    () => Math.ceil(props.list.length / props.maxLength),
    [props.list, props.maxLength]
  )

  const displayedElements = useMemo(() => {
    const skippedElements = props.maxLength * curPage
    return props.list.slice(skippedElements, skippedElements + props.maxLength)
  }, [props.maxLength, curPage, props.list])

  return (
    <div className={classNames('font-work-sans', props.className)}>
      {[...new Array(props.maxLength)].map((_, idx) => {
        const element = displayedElements[idx]
        return (
          <div
            key={`${idx}-${element?.label}`}
            className={classNames(
              'flex h-11 items-center border-b border-jgp-secondary-light border-opacity-50 px-4 text-sm font-semibold',
              {
                invisible: element === undefined,
              }
            )}
          >
            <Link to={element?.url ?? '#'}>{element?.label}</Link>
          </div>
        )
      })}
      {numPages > 1 && (
        <div className="mt-3 flex items-center justify-end">
          <button
            className="focus:outline-none"
            onClick={() => setCurPage((page) => page - 1)}
            disabled={curPage === 0}
          >
            <ChevronLeft
              className={classNames('h-6 w-6', {
                'text-jgp-secondary-dark': curPage === 0,
              })}
            />
          </button>
          <button
            className="focus:outline-none"
            onClick={() => setCurPage((page) => page + 1)}
            disabled={curPage === numPages - 1}
          >
            <ChevronRight
              className={classNames('h-6 w-6', {
                'text-jgp-secondary-dark': curPage === numPages - 1,
              })}
            />
          </button>
        </div>
      )}
    </div>
  )
}

export default PaginatedList
