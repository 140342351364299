import React from 'react'

import { useActive } from '../../contexts/Active'

interface Props {
  data: { name: string }
}

const TableCheckbox: React.FC<Props> = (props) => {
  const { activeSeries, changeActive } = useActive()

  return (
    <span
      style={{ height: 26, width: 26 }}
      className="flex items-center justify-center"
    >
      <input
        className="cursor-pointer"
        type="checkbox"
        checked={activeSeries[props.data.name]}
        onChange={(event) =>
          changeActive(props.data.name, event.target.checked)
        }
      />
    </span>
  )
}

export default TableCheckbox
