import React from 'react'
import { useHistory } from 'react-router-dom'

import { login } from '../../../../clients/user'
import { useUser } from '../../../../contexts/User'
import { Path } from '../../../../utils/constants'
import Login from '../../components/Login'

interface Props {
  className?: string
}

const LoginContainer: React.FC<Props> = (props) => {
  const { refetch } = useUser()
  const history = useHistory()

  const handleLogin = (email: string, password: string, rememberMe: boolean) =>
    login(email, password, rememberMe)
      .then(refetch)
      .then(() => {
        history.push(Path.REPORTS)
      })

  return <Login className={props.className} onLogin={handleLogin} />
}

export default LoginContainer
