import classNames from 'classnames'
import type { PropsWithChildren } from 'react'

interface Props<T extends string> {
  className?: string
  tabs: {
    id: T
    label: string
  }[]
  activeId: string
  onTabChange?: (id: T) => any
}

const PlainTabs = <T extends string>(props: PropsWithChildren<Props<T>>) => (
  <div className={classNames('flex gap-4', props.className)}>
    {props.tabs.map((tab) => (
      <button
        key={tab.id}
        className={classNames(
          {
            'font-bold underline': props.activeId === tab.id,
          },
          'hover:underline'
        )}
        onClick={() => props.onTabChange?.(tab.id)}
      >
        {tab.label}
      </button>
    ))}
  </div>
)

export default PlainTabs
