import classNames from 'classnames'
import React from 'react'

import ButtonWithLoading from '../ButtonWithLoading'
import { DownloadIcon } from '../icons'

interface Props {
  className?: string
  onClick: () => Promise<void>
}

const DownloadButton: React.FC<Props> = (props) => (
  <ButtonWithLoading
    className={classNames('focus:outline-none', props.className)}
    innerClass="flex items-center text-white"
    onClick={props.onClick}
    spinnerProps={{ className: 'text-white', size: 24 }}
  >
    <DownloadIcon className="mr-1.5 h-6 w-6" />
    Download
  </ButtonWithLoading>
)

export default DownloadButton
