import React from 'react'

const GovernanceIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props
) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5343 4.37611C17.5741 4.29491 17.6138 4.21372 17.6536 4.13252C17.3526 3.93654 17.0686 3.70695 16.7478 3.55016C14.5073 2.45824 12.2414 1.40831 10.0208 0.277181C9.20872 -0.137191 8.53858 -0.0643962 7.7577 0.330377C5.65926 1.39431 3.52107 2.38264 1.41411 3.42697C1.02509 3.62016 0.479895 3.67055 0.346436 4.37611C6.10507 4.37611 11.8183 4.37611 17.5343 4.37611Z"
      fill="currentColor"
    />
    <path
      d="M9.00132 5.29165C14.3992 5.29165 18.7751 5.17883 18.7751 5.03966C18.7751 4.9005 14.3992 4.78768 9.00132 4.78768C3.60341 4.78768 -0.772461 4.9005 -0.772461 5.03966C-0.772461 5.17883 3.60341 5.29165 9.00132 5.29165Z"
      fill="currentColor"
    />
    <path
      d="M3.32785 14.7578H2.30561C2.16363 14.7578 2.05005 14.6458 2.05005 14.5058V5.87961C2.05005 5.73962 2.16363 5.62762 2.30561 5.62762H3.32785C3.46983 5.62762 3.58341 5.73962 3.58341 5.87961V14.503C3.58625 14.643 3.46983 14.7578 3.32785 14.7578Z"
      fill="currentColor"
    />
    <path
      d="M7.03342 14.7578H6.01118C5.8692 14.7578 5.75562 14.6458 5.75562 14.5058V5.87961C5.75562 5.73962 5.8692 5.62762 6.01118 5.62762H7.03342C7.1754 5.62762 7.28898 5.73962 7.28898 5.87961V14.503C7.28898 14.643 7.1754 14.7578 7.03342 14.7578Z"
      fill="currentColor"
    />
    <path
      d="M11.1081 14.7578H10.0859C9.94391 14.7578 9.83032 14.6458 9.83032 14.5058V5.87961C9.83032 5.73962 9.94391 5.62762 10.0859 5.62762H11.1081C11.2501 5.62762 11.3637 5.73962 11.3637 5.87961V14.503C11.3637 14.643 11.2473 14.7578 11.1081 14.7578Z"
      fill="currentColor"
    />
    <path
      d="M15.6147 14.7578H14.5925C14.4505 14.7578 14.3369 14.6458 14.3369 14.5058V5.87961C14.3369 5.73962 14.4505 5.62762 14.5925 5.62762H15.6147C15.7567 5.62762 15.8703 5.73962 15.8703 5.87961V14.503C15.8703 14.643 15.7567 14.7578 15.6147 14.7578Z"
      fill="currentColor"
    />
    <path
      d="M16.7052 16.0961H1.2977C1.19831 16.0961 1.11597 16.0149 1.11597 15.9169V15.385C1.11597 15.287 1.19831 15.2058 1.2977 15.2058H16.7052C16.8046 15.2058 16.8869 15.287 16.8869 15.385V15.9169C16.8841 16.0149 16.8046 16.0961 16.7052 16.0961Z"
      fill="currentColor"
    />
    <path
      d="M17.2473 17.0313H0.752297C0.610319 17.0313 0.493896 16.9165 0.493896 16.7765V16.3257C0.493896 16.1857 0.610319 16.0709 0.752297 16.0709H17.2502C17.3921 16.0709 17.5086 16.1857 17.5086 16.3257V16.7765C17.5086 16.9165 17.3921 17.0313 17.2473 17.0313Z"
      fill="currentColor"
    />
    <path
      d="M17.7785 18H0.221486C0.0993848 18 0 17.902 0 17.7816V17.2049C0 17.0845 0.0993848 16.9865 0.221486 16.9865H17.7785C17.9006 16.9865 18 17.0845 18 17.2049V17.7816C18 17.902 17.9006 18 17.7785 18Z"
      fill="currentColor"
    />
  </svg>
)

export default GovernanceIcon
