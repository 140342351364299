import { loader } from 'graphql.macro'
import { useEffect, useState } from 'react'

import { useImperativeQuery } from '../../../../utils/hooks'
import { getMetrics } from '../../clients'
import Statistics from '../../components/Statistics'
import type { ScoreStatisticsMetrics } from '../../typings/metrics'

interface Props {
  className?: string
}

interface QueryResult {
  companies: Pick<Company, 'id' | 'name'>[]
  peerGroups: string[]
  userBaskets: Pick<UserBasket, 'id' | 'name'>[]
  frameworksYearRange: {
    min: number
    max: number
  }
}

const query = loader('./query.graphql')

const StatisticsContainer: React.FC<Props> = (props) => {
  const [queryData, setQueryData] = useState<QueryResult>({
    companies: [],
    peerGroups: [],
    userBaskets: [],
    frameworksYearRange: {
      min: 0,
      max: -1,
    },
  })
  const [metricsData, setMetricsData] = useState<ScoreStatisticsMetrics>({
    quant: [],
    quali: [],
  })

  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    makeQuery().then(setQueryData)
    getMetrics().then(setMetricsData)
  }, [makeQuery])

  return (
    <Statistics
      className={props.className}
      {...queryData}
      metrics={metricsData}
    />
  )
}

export default StatisticsContainer
