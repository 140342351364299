import { RegularChart } from '@jgp-er-dev/charts'
import classNames from 'classnames'
import { uniq } from 'ramda'
import React, { useMemo } from 'react'

import { colorPalette } from '../../utils/constants'

export interface Props {
  series: {
    name: string
    data: { year: number; value: number | null }[]
    type: 'default' | 'peerAverage'
  }[]
  className?: string
  title?: string
  unit?: string
  decimals?: number
  enableLegend?: boolean
}

export const getChartConfig = (props: Props) => {
  const series = props.series.map((s) => ({
    name: s.name,
    type: 'line',
    color: s.type === 'peerAverage' ? 'gray' : undefined,
    data: s.data
      .map((point) => ({ x: point.year, y: point.value }))
      .sort((a, b) => a.x - b.x),
    dashStyle: s.type === 'peerAverage' ? 'ShortDash' : 'Solid',
    tooltip: {
      valueSuffix: props.unit && ` ${props.unit}`,
      valueDecimals: props.decimals ?? 0,
    },
    marker: {
      enabled: s.data.length === 1,
    },
  }))

  const isLongUnit = props.unit && props.unit.length > 6

  return {
    options: {
      chart: {
        style: { fontFamily: 'Work Sans' },
      },
      colors: colorPalette,
      series: series as any,
      yAxis: {
        id: 'default',
        opposite: false,
        min: 0,
        title: {
          align: 'high' as const,
          text: props.unit && !isLongUnit ? `(${props.unit})` : undefined,
          rotation: 0,
          offset: 15,
          y: -20,
        },
      },
      xAxis: {
        type: 'category' as const,
        categories: uniq(
          props.series.map((s) => s.data.map((p) => p.year)).flat()
        )
          .sort()
          .map((year) => year.toString()),
      },
      title: {
        text: props.title
          ? isLongUnit
            ? `${props.title} (${props.unit})`
            : props.title
          : 'Evolução do score',
        style: { fontSize: '14px' },
      },
      legend: { enabled: props.enableLegend },
      credits: { text: '' },
      navigation: {
        buttonOptions: { enabled: false },
      },
    },
  }
}

const ScoreHistoryChart: React.FC<Props> = (props) => {
  const chartProps = useMemo(() => getChartConfig(props), [props])

  return (
    <div
      className={classNames(
        'relative grid h-chart grid-cols-1 p-2',
        props.className
      )}
    >
      <RegularChart chartProps={chartProps} />
      {props.series.length === 0 && (
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-sm text-gray-500">Sem dados para exibir</span>
        </div>
      )}
    </div>
  )
}

export default ScoreHistoryChart
