import type { CustomCellRendererProps } from 'ag-grid-react'
import classNames from 'classnames'

export interface Props {
  onClick?: (companyName: string) => any
}

const CellRenderer: React.FC<Props & CustomCellRendererProps> = (props) => {
  const isCompanyRow = !props.node.rowPinned

  return (
    <span
      className={classNames({ 'cursor-pointer underline': isCompanyRow })}
      onClick={() => isCompanyRow && props.onClick?.(props.data.companyName)}
    >
      {props.value}
    </span>
  )
}

export default CellRenderer
