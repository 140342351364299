import classNames from 'classnames'
import React from 'react'
import { Redirect } from 'react-router-dom'
import Select from 'react-select'

import NotFound from '../../../../components/NotFound'
import ScoreCard from '../../../../components/ScoreCard'
import { useWatchLoading } from '../../../../contexts/Loading'
import { sectionIdToLabel, stringToSelectOption } from '../../../../utils'
import { Path } from '../../../../utils/constants'
import ESGSectionOverviewContainer from '../../containers/ESGSectionOverview'
import ESGSubsectionContainer from '../../containers/ESGSubsection'
import { useActiveCompanies } from '../../contexts/ActiveCompanies'
import { useSectoralPath } from '../../utils/hooks'

interface Props {
  loading: boolean
  section: ESGSection
  subsections: { id: string; label: string }[]
  weight: number | null
  className?: string
}

const ESGSection: React.FC<Props> = (props) => {
  const { sector, section, subsection } = useSectoralPath()
  const { activeCompanies, allCompanies, setActiveCompanies } =
    useActiveCompanies()
  useWatchLoading(props.loading)

  if (!subsection) {
    return (
      <Redirect
        to={[Path.REPORTS_SECTORAL, sector, section, 'overview'].join('/')}
      />
    )
  }

  const renderBody = () => {
    if (subsection === 'overview') {
      return (
        <ESGSectionOverviewContainer
          className="mt-20"
          section={props.section}
        />
      )
    }

    const label = props.subsections.find((tab) => tab.id === subsection)?.label

    return label ? (
      <ESGSubsectionContainer
        key={label}
        className="mt-20"
        section={props.section}
        subsection={label}
      />
    ) : (
      <NotFound />
    )
  }

  return props.loading ? null : (
    <div
      className={classNames('pb-20 pl-10 pr-4 font-work-sans', props.className)}
    >
      <div className="flex-col">
        <span className="text-xs">Empresas:</span>
        <Select
          options={allCompanies.map(stringToSelectOption)}
          value={activeCompanies.map(stringToSelectOption)}
          onChange={(values) =>
            setActiveCompanies(values.map(({ value }) => value))
          }
          isMulti
        />
      </div>
      <div className="mt-10 text-3xl font-semibold">
        {sectionIdToLabel(props.section)}
      </div>
      {props.weight !== null && (
        <div className="mt-8 flex">
          <ScoreCard
            color="primary"
            title="Peso"
            value={(100 * props.weight!).toFixed(0) + '%'}
          />
        </div>
      )}
      {renderBody()}
    </div>
  )
}

export default ESGSection
