import { CustomHeaderGroupProps } from 'ag-grid-react'

export interface Props {
  field: string | null
  onClick?: () => any
}

const TableHeader: React.FC<Props & CustomHeaderGroupProps> = (props) => {
  return (
    <span className="cursor-pointer underline" onClick={props.onClick}>
      {props.displayName}
    </span>
  )
}

export default TableHeader
