import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import { useImperativeQuery } from '../../../../utils/hooks'
import Controversies from '../../components/Controversies'
import { useSectoralReport } from '../../contexts/SectoralReport'

interface Props {
  className?: string
}

interface QueryResult {
  companiesInPeerGroup: {
    name: string
    controversies: Controversy[]
  }[]
}

const query = loader('./query.graphql')

const ControversiesContainer: React.FC<Props> = (props) => {
  const [companies, setCompanies] = useState<
    { name: string; controversies: Controversy[] }[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingSector, sector } = useSectoralReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    setLoading(true)

    if (loadingSector) {
      return
    }

    makeQuery({ peerGroup: sector }).then((data: QueryResult) => {
      setCompanies(data.companiesInPeerGroup)
      setLoading(false)
    })
  }, [loadingSector, sector, makeQuery])

  return (
    <Controversies
      className={props.className}
      loading={loading}
      companies={companies}
    />
  )
}

export default ControversiesContainer
