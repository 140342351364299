import React from 'react'

const BlankStarIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 1.94164L14.7358 8.8227L14.8705 9.23729H15.3064H22.5416L16.6882 13.49L16.3355 13.7462L16.4703 14.1608L18.706 21.0419L12.8527 16.7892L12.5 16.5329L12.1473 16.7892L6.29395 21.0419L8.52974 14.1608L8.66445 13.7462L8.31178 13.49L2.4584 9.23729H9.69357H10.1295L10.2642 8.8227L12.5 1.94164Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
)

export default BlankStarIcon
