export const Path = {
  CONFIG: '/config',
  LOGIN: '/login',
  REPORTS: '/reports',
  REPORTS_COMPANY: '/reports/company',
  REPORTS_COMPARATIVE: '/reports/comparative',
  REPORTS_PERSONALIZED: '/reports/personalized',
  REPORTS_PERSONALIZED_BASKETS: '/reports/personalized/baskets',
  REPORTS_SECTORAL: '/reports/sector',
  REPORTS_STATISTICS: '/reports/statistics',
  REPORTS_THEMES: '/reports/themes',
}

export const GLOBAL_VARIABLES = 'Variáveis globais'
export const ENVIRONMENTAL = 'Environmental'
export const SOCIAL = 'Social'
export const GOVERNANCE = 'Governance'

export const ESG_SECTIONS = ['environmental', 'social', 'governance'] as const

export const colorPalette = [
  '#30ba09',
  '#000000',
  '#59c83a',
  '#83d66b',
  '#ace39d',
  '#7e7f74',
  '#a8ab9b',
  '#c3c6b9',
  '#16164d',
]

export const commonPieChartOptions = {
  colors: colorPalette,
  chart: {
    type: 'pie',
    style: { fontFamily: 'Work Sans' },
  },
  credits: { text: '' },
  navigation: {
    buttonOptions: { enabled: false },
  },
  plotOptions: {
    pie: {
      innerSize: '25%',
      showInLegend: true,
      borderWidth: 1,
      borderColor: null as any,
    },
    series: {
      point: {
        events: {
          legendItemClick: () => false,
        },
      },
    },
  },
  legend: {
    align: 'right' as const,
    layout: 'vertical' as const,
    verticalAlign: 'middle' as const,
    width: 250,
    itemWidth: 250,
    itemMarginBottom: 8,
    itemStyle: {
      textOverflow: 'initial',
      fontWeight: '500',
    },
    symbolRadius: 0,
    symbolHeight: 12,
    symbolWidth: 30,
    squareSymbol: false,
    enabled: true,
  },
}
