export const validateCnpj = (cnpj: string) => {
  if (cnpj.length === 0) {
    return true
  }

  const digits = cnpj.replace(/\D/g, '').split('').map(Number)

  if (digits.length !== 14) {
    return false
  }

  const computeDv = (list: number[]) => {
    const dv =
      list.reverse().reduce((sum, d, i) => sum + d * (2 + (i % 8)), 0) % 11
    return dv < 2 ? 0 : 11 - dv
  }

  const dv0 = computeDv(digits.slice(0, 12))
  const dv1 = computeDv(digits.slice(0, 12).concat(dv0))

  return dv0 === digits[12] && dv1 === digits[13]
}
