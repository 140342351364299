import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { equals } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useImperativeQuery } from '../../../../utils/hooks'
import RemunerationSum from '../../components/RemunerationSum'
import {
  CUSTOM_ESTIMATE_YEARS,
  getInitialCustomEstimates,
  useCompaniesData,
  useCompaniesList,
} from '../../utils/remuneration'
import { computeCompanySums } from './utils'

interface SavedReportsQueryResult {
  userSavedRemunerationSumReports: Pick<
    UserSavedRemunerationSumReport,
    'id' | 'name'
  >[]
}

interface SavedReportQueryResult {
  savedRemunerationSumReport: UserSavedRemunerationSumReport | null
}

const savedReportsQuery = loader('./savedReportsQuery.graphql')
const savedReportQuery = loader('./savedReportQuery.graphql')
const saveReportMutation = loader('./saveReportMutation.graphql')
const renameReportMutation = loader('./renameReportMutation.graphql')
const deleteReportMutation = loader('./deleteReportMutation.graphql')

const RemunerationSumContainer: React.FC = () => {
  const [companiesData, setCompaniesData] = useState<RemunerationData>({})
  const [originalCompaniesData, setOriginalCompaniesData] =
    useState<RemunerationData>({})
  const [customEstimates, setCustomEstimates] = useState<
    Record<string, RemunerationEstimates>
  >({})
  const [companiesList, setCompaniesList] = useState<RemunerationCompany[]>([])
  const [summedCompanies, setSummedCompanies] = useState<RemunerationCompany[]>(
    []
  )
  const [peers, setPeers] = useState<RemunerationCompany[]>([])
  const [includeIncompleteSum, setIncludeIncompleteSum] =
    useState<boolean>(false)
  const [userSavedReports, setUserSavedReports] = useState<
    Pick<UserSavedRemunerationReport, 'id' | 'name'>[]
  >([])
  const [lastReportName, setLastReportName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const makeSavedReportsQuery = useImperativeQuery(savedReportsQuery, {
    fetchPolicy: 'network-only',
  })
  const makeSavedReportQuery = useImperativeQuery(savedReportQuery, {
    fetchPolicy: 'network-only',
  })

  const [saveReport] = useMutation(saveReportMutation)
  const [renameReport] = useMutation(renameReportMutation)
  const [deleteReport] = useMutation(deleteReportMutation)

  const getCompaniesData = useCompaniesData()
  const getCompaniesList = useCompaniesList()

  const updateUserReports = useCallback(
    async () =>
      makeSavedReportsQuery()
        .then((data: SavedReportsQueryResult) => {
          setUserSavedReports(data.userSavedRemunerationSumReports)
        })
        .catch((err) =>
          console.error('Failed to query saved user reports:', err)
        ),
    [makeSavedReportsQuery]
  )

  useEffect(() => {
    setLoading(true)

    Promise.all([getCompaniesList(), updateUserReports()])
      .then(([list]) => setCompaniesList(list))
      .catch((err) => {
        console.error('Failed to query company list:', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getCompaniesList, updateUserReports])

  /*
   * Consolidated sum: Only includes consolidated values
   * Estimated sum: Includes both consolidated and estimated values
   * Incomplete sum: Whether a year should be included in the case we don't have
   * values for every company being summed
   */
  const { summedConsolidatedCompaniesData, summedEstimatedCompaniesData } =
    useMemo(
      () =>
        computeCompanySums({
          summedCompanies,
          peers,
          companiesData,
          includeIncompleteSum,
        }),
      [companiesData, summedCompanies, peers, includeIncompleteSum]
    )

  const handleActiveCompaniesChange = useCallback(
    async (
      sumCompanies: RemunerationCompany[],
      peerCompanies: RemunerationCompany[]
    ) => {
      if (sumCompanies.length + peerCompanies.length === 0) {
        setCompaniesData({})
        setOriginalCompaniesData({})
        setSummedCompanies([])
        setPeers([])
        return
      }

      setLoading(true)

      getCompaniesData(
        sumCompanies.concat(peerCompanies).map((company) => company.ticker)
      )
        .then((data) => {
          setCompaniesData(data)
          setPeers(peerCompanies)
          setOriginalCompaniesData(data)
          setSummedCompanies(sumCompanies)
          setCustomEstimates({})
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [getCompaniesData]
  )

  const handleSaveReport = useCallback(
    async (name: string) => {
      const savedReport = userSavedReports.find(
        (report) => report.name === name
      )

      const res = await saveReport({
        variables: {
          input: {
            id: savedReport?.id ?? null,
            name,
            summedCompanies: summedCompanies.map((company) => company.ticker),
            peers: peers.map((company) => company.ticker),
            companiesData,
            estimates: customEstimates,
            includeIncompleteSum,
          },
        },
      })

      const report = res.data?.saveUserRemunerationSumReport?.savedReport

      if (!report) {
        throw new Error(
          res.data?.saveUserRemunerationSumReport?.error ??
            'Report saving failed with unknown error'
        )
      }

      setLastReportName(name)

      return updateUserReports()
    },
    [
      userSavedReports,
      saveReport,
      summedCompanies,
      peers,
      companiesData,
      customEstimates,
      includeIncompleteSum,
      updateUserReports,
    ]
  )

  const handleRenameReport = useCallback(
    async (id: string, name: string) => {
      const res = await renameReport({
        variables: { input: { id, name } },
      })

      const report = res.data?.renameUserRemunerationSumReport?.report

      if (!report) {
        throw new Error(
          res.data?.renameUserRemunerationSumReport?.error ??
            'Report renaming failed with unknown error'
        )
      }

      return updateUserReports()
    },
    [renameReport, updateUserReports]
  )

  const handleLoadReport = useCallback(
    async (id: string) =>
      makeSavedReportQuery({ id }).then(
        async (data: SavedReportQueryResult) => {
          if (!data.savedRemunerationSumReport) {
            throw new Error('Report not found')
          }

          setSummedCompanies(data.savedRemunerationSumReport.summedCompanies)
          setPeers(data.savedRemunerationSumReport.peers)
          setCompaniesData(data.savedRemunerationSumReport.companiesData)
          setCustomEstimates(data.savedRemunerationSumReport.estimates)
          setIncludeIncompleteSum(
            data.savedRemunerationSumReport.includeIncompleteSum
          )
          setLastReportName(data.savedRemunerationSumReport.name)

          return getCompaniesData(
            data.savedRemunerationSumReport.summedCompanies
              .concat(data.savedRemunerationSumReport.peers)
              .map((company) => company.ticker)
          ).then((data) => setOriginalCompaniesData(data))
        }
      ),
    [makeSavedReportQuery, getCompaniesData]
  )

  const handleDeleteReport = useCallback(
    async (id: string) =>
      deleteReport({
        variables: { input: { id } },
      }).then((res) => {
        const error = res.data?.deleteUserRemunerationSumReport.error

        if (error) {
          throw new Error('Failed to delete report: ' + error)
        }

        return updateUserReports()
      }),
    [deleteReport, updateUserReports]
  )

  useEffect(() => {
    if (equals(customEstimates, {})) {
      setCustomEstimates(
        Object.fromEntries(
          CUSTOM_ESTIMATE_YEARS.map((year) => [
            year,
            getInitialCustomEstimates(summedEstimatedCompaniesData, year),
          ])
        )
      )
    }
  }, [customEstimates, summedEstimatedCompaniesData])

  return (
    <RemunerationSum
      loading={loading}
      companiesData={companiesData}
      originalCompaniesData={originalCompaniesData}
      consolidatedSummedData={summedConsolidatedCompaniesData}
      estimatedSummedData={summedEstimatedCompaniesData}
      companiesList={companiesList}
      summedCompanies={summedCompanies}
      peers={peers}
      includeIncompleteSum={includeIncompleteSum}
      customEstimates={customEstimates}
      savedReports={userSavedReports}
      lastReportName={lastReportName}
      onActiveCompaniesChange={handleActiveCompaniesChange}
      onCompaniesDataChange={setCompaniesData}
      onIncludeIncompleteSumChange={setIncludeIncompleteSum}
      onCustomEstimatesChange={setCustomEstimates}
      onSaveReport={handleSaveReport}
      onLoadReport={handleLoadReport}
      onRenameReport={handleRenameReport}
      onDeleteReport={handleDeleteReport}
    />
  )
}

export default RemunerationSumContainer
