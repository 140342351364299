import classNames from 'classnames'
import React from 'react'

import ScoreCard from '../../../../components/ScoreCard'
import { useWatchLoading } from '../../../../contexts/Loading'
import ESGSubsectionQualiContainer from '../../containers/ESGSubsectionQuali'
import ESGSubsectionQuantContainer from '../../containers/ESGSubsectionQuant'
import { formatRanking } from '../../utils'

interface Props {
  loading: boolean
  section: ESGSection
  subsection: string
  score: number | null
  ranking: number[]
  peerAverageScore: number | null
  weight: number | null
  hasQuant: boolean
  hasQuali: boolean
  className?: string
}

const ESGSubsection: React.FC<Props> = (props) => {
  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div className={classNames('font-work-sans', props.className)}>
      <div className="text-xl font-semibold">{props.subsection}</div>
      {props.hasQuali && (
        <div className="mt-8 flex">
          <ScoreCard
            color="primary"
            title="Score"
            value={props.score!.toFixed(0)}
          />
          <ScoreCard
            className="ml-6"
            color="secondary"
            title="Ranking"
            value={formatRanking(props.ranking[0], props.ranking[1])}
          />
          <ScoreCard
            className="ml-6"
            color="secondary"
            title="Peso"
            value={(100 * props.weight!).toFixed(0) + '%'}
          />
          <ScoreCard
            className="ml-6"
            color="secondary"
            title="Média Peers"
            value={props.peerAverageScore!.toFixed(0)}
          />
        </div>
      )}
      {props.hasQuali && (
        <ESGSubsectionQualiContainer
          className="mt-10"
          section={props.section}
          subsection={props.subsection}
        />
      )}
      {props.hasQuant && (
        <ESGSubsectionQuantContainer
          className="mt-10"
          section={props.section}
          subsection={props.subsection}
        />
      )}
    </div>
  )
}

export default ESGSubsection
