import { Path } from '../../../utils/constants'

export const BASKET_SHARED_MSG =
  'Um link compartilhável do basket foi copiado para sua área de transferência'
export const REPORT_SHARED_MSG =
  'Um link compartilhável da análise foi copiado para sua área de transferência'

export const getReportShareableUrl = (id: string) =>
  `${window.location.protocol}//${window.location.host}${Path.REPORTS_PERSONALIZED}?id=${id}`
export const getBasketShareableUrl = (id: string) =>
  `${window.location.protocol}//${window.location.host}${Path.REPORTS_PERSONALIZED_BASKETS}?id=${id}`
