import React from 'react'

import Logo from '../Logo'

const UpperFooter: React.FC = () => (
  <div className="flex justify-center bg-jgp-success-main px-4 pt-8 pb-12 font-work-sans text-white">
    <div className="w-full max-w-screen-xl">
      <div className="flex flex-col items-start justify-between md:flex-row">
        <Logo className="mb-10 h-11 md:mb-0" />
        <div className="mb-10 flex flex-col items-start md:mb-0">
          <div className="mb-4 text-xl">
            A <span className="font-bold">JGP</span>
          </div>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/quem-somos"
          >
            Quem Somos
          </a>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/quem-somos#filosofia"
          >
            Filosofia de Investimentos
          </a>
          <a
            className="flex h-8 items-center text-sm"
            href="https://bit.ly/jgp-esg-lp-f%E2%80%8B"
          >
            ESG
          </a>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/informacoes-regulatorias"
          >
            Informações Regulatórias
          </a>
        </div>
        <div className="mb-10 flex flex-col md:mb-0">
          <div className="mb-4 text-xl">
            <span className="font-bold">Asset </span>
            Management
          </div>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/asset-management"
          >
            Todos os fundos
          </a>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/processo-investimento-asset"
          >
            Processo de Investimento
          </a>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/asset-management"
          >
            Como Investir
          </a>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/relatorio-gestao"
          >
            Relatórios de Gestão
          </a>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/cotas-rentabilidades"
          >
            Cotas e Rentabilidades
          </a>
        </div>
        <div className="flex flex-col">
          <div className="mb-4 text-xl">
            <span className="font-bold">Wealth </span>
            Management
          </div>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/wealth-management"
          >
            Sobre nós
          </a>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/wealth-management"
          >
            Serviços
          </a>
          <a
            className="flex h-8 items-center text-sm"
            href="https://www.jgp.com.br/processo-investimento-wealth"
          >
            Processo de Investimento
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default UpperFooter
