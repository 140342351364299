import type { PermissionName } from '@jgp-er-dev/permissions'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { Link, matchPath, Redirect, useLocation } from 'react-router-dom'

import ChevronDownIcon from '../icons/ChevronDown'
import ChevronRightIcon from '../icons/ChevronRight'

interface Props {
  section: Section
  basePath: string
}

export interface Section {
  Icon?: React.FC<React.SVGAttributes<SVGSVGElement>>
  path: string
  label: string
  permissions?: PermissionName[]
  subsections?: {
    path: string
    label: string
    permissions?: PermissionName[]
  }[]
  autoRedirect?: boolean
}

const SidebarSection: React.FC<Props> = (props) => {
  const { pathname } = useLocation()

  const active = useMemo(
    () => pathname.startsWith(props.basePath + props.section.path),
    [props.basePath, props.section.path, pathname]
  )

  const [expanded, setExpanded] = useState<boolean>(active)

  if (
    !!matchPath(pathname, {
      path: props.basePath + props.section.path,
      exact: true,
    }) &&
    props.section.autoRedirect &&
    props.section.subsections?.length
  ) {
    return <Redirect to={props.basePath + props.section.subsections[0].path} />
  }

  const renderContent = () => (
    <div
      className={classNames('border-l-4', {
        'border-jgp-success-main': active,
        'border-transparent': !active,
      })}
    >
      <div className="flex items-center justify-between border-b border-jgp-secondary-light border-opacity-50 p-4">
        <span
          className={classNames('flex items-center', {
            'text-jgp-success-main': active,
          })}
        >
          {props.section.Icon ? (
            <props.section.Icon className="h-5 w-5" />
          ) : (
            <span className="h-5 w-5" />
          )}
          <span className="ml-2.5 font-work-sans font-bold">
            {props.section.label}
          </span>
        </span>
        {props.section.subsections &&
          (expanded ? (
            <ChevronDownIcon className="h-5 w-5" />
          ) : (
            <ChevronRightIcon className="h-5 w-5" />
          ))}
      </div>
    </div>
  )

  return props.section.subsections ? (
    <div
      className={classNames({
        'border-b border-jgp-secondary-light border-opacity-50': expanded,
      })}
    >
      <button
        className="w-full focus:outline-none"
        onClick={() => setExpanded((curValue) => !curValue)}
      >
        {renderContent()}
      </button>
      {expanded && (
        <div className="py-2">
          {props.section.subsections.map((subsection) => (
            <div
              key={subsection.path}
              className="flex items-center border-l-4 border-transparent px-4 py-2"
            >
              <span className="w-5 flex-shrink-0" />
              <span
                className={classNames('ml-2.5 font-work-sans', {
                  'text-jgp-success-main':
                    pathname === props.basePath + subsection.path,
                })}
              >
                <Link to={props.basePath + subsection.path}>
                  {subsection.label}
                </Link>
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <Link to={props.basePath + props.section.path}>{renderContent()}</Link>
  )
}

export default SidebarSection
