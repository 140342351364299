import React from 'react'

import LowerFooter from './LowerFooter'
import UpperFooter from './UpperFooter'

interface Props {
  className?: string
}

const Footer: React.FC<Props> = (props) => {
  return (
    <div className={props.className}>
      <UpperFooter />
      <LowerFooter />
    </div>
  )
}

export default Footer
