import { uniq } from 'ramda'
import { FunctionComponent, useMemo } from 'react'

import RemunerationReportChartSection from '../../components/RemunerationReportChartSection'
import { getConsolidatedDate, isEstimateDate } from '../../utils/remuneration'

interface Props {
  className?: string
  data: RemunerationData
  includeEstimates?: boolean
}

const convertToPercentage = <T extends { value: number }>(data: T[]) =>
  data.map((item) => ({ ...item, value: item.value * 100 }))

const getTimeSeriesChartConfig = (args: {
  data: RemunerationData
  title: string
  unit?: string
  decimals?: number
  getSeries: (
    companyData: CompanyRemunerationData
  ) => { year: string; value: number; isEstimate?: boolean }[]
}) => {
  const series = Object.values(args.data).map((companyData) => ({
    name: companyData.company.name,
    data: args.getSeries(companyData),
  }))

  const years = uniq(
    series
      .map((s) => s.data.map((value) => value.year))
      .flat()
      .sort((ya, yb) => parseInt(ya) - parseInt(yb))
  )

  return {
    type: 'timeseries' as const,
    series,
    years,
    title: args.title,
    unit: args.unit,
    decimals: args.decimals,
  }
}

const computeFieldRatio = (args: {
  fields: TimeSeriesRemunerationDataField[]
  data: CompanyRemunerationData
  includeEstimates?: boolean
}) => {
  const fieldsData = args.fields.map((field) => args.data[field].data)

  const years = uniq(
    fieldsData
      .map((fieldData) =>
        fieldData.map((value) => getConsolidatedDate(value.date))
      )
      .flat()
  ).sort((ya, yb) => parseInt(ya) - parseInt(yb))

  return years
    .map((year) => {
      const points = fieldsData.map((fieldData) =>
        fieldData.find((point) => getConsolidatedDate(point.date) === year)
      )

      if (points.some((point) => point?.value == null)) {
        return null
      }

      return {
        year,
        value: points[0]!.value / points[1]!.value,
        isEstimate: points.some((point) => isEstimateDate(point!.date)),
      }
    })
    .filter(
      (item): item is NonNullable<typeof item> =>
        item !== null && (args.includeEstimates || !item.isEstimate)
    )
}

const getColumnChartConfig = (args: {
  data: RemunerationData
  title: string
  unit?: string
  decimals?: number
  type: 'council' | 'directors'
  subtype: 'breakdown' | 'stats'
  includeEstimates?: boolean
  stackColumns?: boolean
}) => {
  const subfields =
    args.subtype === 'breakdown'
      ? [
          { field: 'long_term', label: 'Remuneração de Longo Prazo' },
          { field: 'fixed', label: 'Remuneração Fixa' },
          { field: 'variable', label: 'Remuneração Variável' },
        ]
      : [
          { field: 'max', label: 'Remuneração Máxima' },
          { field: 'min', label: 'Remuneração Mínima' },
          { field: 'avg', label: 'Remuneração Média' },
        ]

  const fieldName =
    args.subtype === 'breakdown'
      ? (`${args.type}RemunerationBreakdown` as const)
      : (`${args.type}RemunerationStats` as const)

  const availableYears = uniq(
    Object.values(args.data)
      .map((companyData) =>
        companyData[fieldName].data.map((value) => value.date)
      )
      .flat()
      .filter((year) => args.includeEstimates || !year.endsWith('E'))
      .sort((ya, yb) => parseInt(yb) - parseInt(ya))
  )

  const allCompanies = Object.keys(args.data)

  const chartData = Object.fromEntries(
    availableYears.map((year) => {
      const companies = allCompanies.filter((ticker) =>
        args.data[ticker][fieldName].data.some((value) => value.date === year)
      )

      const series = subfields.map(({ field, label }) => ({
        name: label,
        data: companies.map(
          (ticker) =>
            args.data[ticker][fieldName].data.find(
              (value) => value.date === year
            )!.value[field]
        ),
      }))

      return [
        year,
        {
          series,
          companies: companies.map((ticker) => args.data[ticker].company.name),
        },
      ]
    })
  )

  return {
    type: 'breakdown' as const,
    chartData,
    title: args.title,
    unit: args.unit,
    decimals: args.decimals,
    stackColumns: args.stackColumns,
  }
}

const RemunerationReportChartSectionContainer: FunctionComponent<Props> = (
  props
) => {
  const charts = useMemo(() => {
    return [
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Conselho/Mkt Cap',
        decimals: 2,
        unit: '%',
        getSeries: (companyData: CompanyRemunerationData) =>
          convertToPercentage(
            computeFieldRatio({
              fields: ['councilRemuneration', 'mktcap'],
              data: companyData,
              includeEstimates: props.includeEstimates,
            })
          ),
      }),
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Diretoria/Mkt Cap',
        decimals: 2,
        unit: '%',
        getSeries: (companyData: CompanyRemunerationData) =>
          convertToPercentage(
            computeFieldRatio({
              fields: ['directorsRemuneration', 'mktcap'],
              data: companyData,
              includeEstimates: props.includeEstimates,
            })
          ),
      }),
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Conselho/EBITDA',
        decimals: 2,
        unit: '%',
        getSeries: (companyData: CompanyRemunerationData) =>
          convertToPercentage(
            computeFieldRatio({
              fields: ['councilRemuneration', 'ebitda'],
              data: companyData,
              includeEstimates: props.includeEstimates,
            })
          ),
      }),
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Diretoria/EBITDA',
        decimals: 2,
        unit: '%',
        getSeries: (companyData: CompanyRemunerationData) =>
          convertToPercentage(
            computeFieldRatio({
              fields: ['directorsRemuneration', 'ebitda'],
              data: companyData,
              includeEstimates: props.includeEstimates,
            })
          ),
      }),
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Conselho/Lucro',
        decimals: 2,
        unit: '%',
        getSeries: (companyData: CompanyRemunerationData) =>
          convertToPercentage(
            computeFieldRatio({
              fields: ['councilRemuneration', 'profit'],
              data: companyData,
              includeEstimates: props.includeEstimates,
            })
          ),
      }),
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Diretoria/Lucro',
        decimals: 2,
        unit: '%',
        getSeries: (companyData: CompanyRemunerationData) =>
          convertToPercentage(
            computeFieldRatio({
              fields: ['directorsRemuneration', 'profit'],
              data: companyData,
              includeEstimates: props.includeEstimates,
            })
          ),
      }),
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Total Conselho',
        decimals: 2,
        unit: 'BRL',
        getSeries: (companyData: CompanyRemunerationData) =>
          companyData.councilRemuneration.data
            .map((value) => ({
              year: getConsolidatedDate(value.date),
              value: value.value,
              isEstimate: isEstimateDate(value.date),
            }))
            .filter((value) => props.includeEstimates || !value.isEstimate),
      }),
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Média Conselho',
        decimals: 2,
        unit: 'BRL',
        getSeries: (companyData: CompanyRemunerationData) =>
          computeFieldRatio({
            fields: ['councilRemuneration', 'councilMemberCount'],
            data: companyData,
            includeEstimates: props.includeEstimates,
          }),
      }),
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Total Diretoria',
        decimals: 2,
        unit: 'BRL',
        getSeries: (companyData: CompanyRemunerationData) =>
          companyData.directorsRemuneration.data
            .map((value) => ({
              year: getConsolidatedDate(value.date),
              value: value.value,
              isEstimate: isEstimateDate(value.date),
            }))
            .filter((value) => props.includeEstimates || !value.isEstimate),
      }),
      getTimeSeriesChartConfig({
        data: props.data,
        title: 'Remuneração Média Diretoria',
        decimals: 2,
        unit: 'BRL',
        getSeries: (companyData: CompanyRemunerationData) =>
          computeFieldRatio({
            fields: ['directorsRemuneration', 'directorsMemberCount'],
            data: companyData,
            includeEstimates: props.includeEstimates,
          }),
      }),
      getColumnChartConfig({
        data: props.data,
        title: 'Breakdown da Remuneração - Conselho',
        decimals: 2,
        unit: 'BRL',
        type: 'council',
        subtype: 'breakdown',
        includeEstimates: props.includeEstimates,
        stackColumns: true,
      }),
      getColumnChartConfig({
        data: props.data,
        title: 'Breakdown da Remuneração - Diretoria',
        decimals: 2,
        unit: 'BRL',
        type: 'directors',
        subtype: 'breakdown',
        includeEstimates: props.includeEstimates,
        stackColumns: true,
      }),
      getColumnChartConfig({
        data: props.data,
        title: 'Remuneração Máxima e Mínima - Conselho',
        decimals: 2,
        unit: 'BRL',
        type: 'council',
        subtype: 'stats',
        includeEstimates: props.includeEstimates,
      }),
      getColumnChartConfig({
        data: props.data,
        title: 'Remuneração Máxima e Mínima - Diretoria',
        decimals: 2,
        unit: 'BRL',
        type: 'directors',
        subtype: 'stats',
        includeEstimates: props.includeEstimates,
      }),
    ]
  }, [props.data, props.includeEstimates])

  return (
    <RemunerationReportChartSection
      className={props.className}
      charts={charts}
    />
  )
}

export default RemunerationReportChartSectionContainer
