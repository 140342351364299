import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import { computeAverage, slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import ESGSubsectionQuant, {
  ChartProps,
} from '../../components/ESGSubsectionQuant'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface Props {
  section: ESGSection
  subsection: string
  className?: string
}

interface QueryResult {
  companyFramework: {
    sections: {
      title: string
      subsections: {
        title: string
        quantIndicators: {
          title: string
          values: {
            year: number
            value: number
          }[]
          peerValues: {
            company: {
              name: string
            }
            value: number
          }[]
          unit: string
        }[]
      }[]
    }[]
  }
}

const query = loader('./query.graphql')

export const getIndicatorColumnChartsPropsList = (
  data: QueryResult,
  args: {
    section: ESGSection
    subsection: string
    companyName: string
  }
): [
  ChartProps['indicatorColumnChartProps'],
  ChartProps['vsPeersColumnChartProps']
][] => {
  const section = data.companyFramework.sections.find(
    (s) => slugify(s.title) === args.section
  )
  const subsection = section?.subsections.find(
    (s) => s.title === args.subsection
  )

  if (!section) {
    throw new Error(`Section ${args.section} does not exist`)
  } else if (!subsection) {
    throw new Error(
      `Subsection ${args.subsection} does not exist in section ${args.section}`
    )
  }

  return subsection.quantIndicators.map((indicator) => [
    {
      type: 'vertical' as const,
      title: indicator.title,
      unit: indicator.unit,
      data: indicator.values.map((point) => ({
        name: point.year.toString(),
        value: point.value,
      })),
      dataLabels: true,
    },
    {
      type: 'vertical' as const,
      title: `${indicator.title} x Peers`,
      unit: indicator.unit,
      data: indicator.peerValues
        .map((peer) => ({
          name: peer.company.name,
          value: peer.value,
          type:
            peer.company.name === args.companyName ? 'highlighted' : 'default',
        }))
        .sort((a, b) => b.value - a.value),
      average: computeAverage(indicator.peerValues.map((peer) => peer.value)),
      dataLabels: true,
    },
  ])
}

const ESGSubsectionQuantContainer: React.FC<Props> = (props) => {
  const [chartProps, setChartProps] = useState<ChartProps[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingCompany) {
      return
    }

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        const propsList = getIndicatorColumnChartsPropsList(data, {
          section: props.section,
          subsection: props.subsection,
          companyName: companyData!.name,
        })

        setChartProps(
          propsList.map(
            ([indicatorColumnChartProps, vsPeersColumnChartProps]) => ({
              indicatorColumnChartProps,
              vsPeersColumnChartProps,
            })
          )
        )

        setLoading(false)
      }
    )
  }, [
    loadingCompany,
    makeQuery,
    companyData,
    year,
    props.section,
    props.subsection,
  ])

  return (
    <ESGSubsectionQuant
      className={props.className}
      loading={loading}
      company={companyData?.name ?? ''}
      chartProps={chartProps}
    />
  )
}

export default ESGSubsectionQuantContainer
