import classNames from 'classnames'
import React from 'react'

interface Props {
  title: string
  value: string
  color: 'primary' | 'secondary'
  className?: string
}

const ScoreCard: React.FC<Props> = (props) => (
  <div
    className={classNames(
      'flex h-28 w-40 flex-col items-center rounded-lg p-5',
      {
        'bg-jgp-success-main': props.color === 'primary',
        'border border-jgp-secondary-light border-opacity-50':
          props.color === 'secondary',
      },
      props.className
    )}
  >
    <span
      className={classNames('text-lg font-bold', {
        'text-white': props.color === 'primary',
        'text-jgp-secondary-main': props.color === 'secondary',
      })}
    >
      {props.title}
    </span>
    <span
      className={classNames('mt-2.5 text-4xl font-bold', {
        'text-white': props.color === 'primary',
        'text-black': props.color === 'secondary',
      })}
    >
      {props.value}
    </span>
  </div>
)

export default ScoreCard
