import classNames from 'classnames'
import React from 'react'

import ButtonWithLoading from '../ButtonWithLoading'

type Props = React.ButtonHTMLAttributes<any> & {
  variant: 'primary' | 'secondary' | 'danger'
  onClick?: () => any
}

const Button: React.FC<Props> = (props) => {
  const { className, variant, ...rest } = props

  const primaryClassName = classNames('text-white', {
    'bg-jgp-success-main': !rest.disabled,
    'bg-jgp-secondary-light': rest.disabled,
  })

  const secondaryClassName = classNames('border-2 bg-transparent', {
    'text-jgp-success-main border-jgp-success-main': !rest.disabled,
    'text-jgp-secondary-light border-jgp-secondary-light': rest.disabled,
  })

  const dangerClassName = classNames('border-2 bg-transparent', {
    'text-jgp-error-dark border-jgp-error-dark': !rest.disabled,
    'text-jgp-secondary-light border-jgp-secondary-light': rest.disabled,
  })

  return (
    <ButtonWithLoading
      className={classNames(
        'text-sm font-medium focus:outline-none',
        {
          [primaryClassName]: variant === 'primary',
          [secondaryClassName]: variant === 'secondary',
          [dangerClassName]: variant === 'danger',
        },
        className
      )}
      spinnerProps={{
        className:
          props.variant === 'primary' ? 'white' : 'text-jgp-success-main',
        size: 18,
      }}
      {...rest}
    >
      {rest.children}
    </ButtonWithLoading>
  )
}

export default Button
