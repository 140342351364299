import { uniq } from 'ramda'

export const stringToSelectOption = (s: string) => ({ label: s, value: s })

export const computeAverageEvolution = (
  evolutions: { values: { year: number; value: number }[] }[]
) => {
  const allYears = uniq(
    evolutions
      .map((evolution) => evolution.values.map(({ year }) => year))
      .flat()
  ).sort((x, y) => x - y)

  const data = allYears.map((year) => {
    const values = evolutions
      .map(
        (evolution) =>
          evolution.values.find((value) => value.year === year)?.value
      )
      .filter((value): value is number => value !== undefined)
    return {
      year,
      value:
        values.length > 0
          ? values.reduce((acc, value) => acc + value, 0) / values.length
          : null,
    }
  })

  return data
}
