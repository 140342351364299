import classNames from 'classnames'
import { loader } from 'graphql.macro'
import { useEffect, useMemo, useRef, useState } from 'react'

import LoadingOverlay from '../../../../components/LoadingOverlay'
import Modal from '../../../../components/Modal'
import Select from '../../../../components/Select'
import Spinner from '../../../../components/Spinner'
import { useToast } from '../../../../contexts/Toast'
import { useImperativeQuery } from '../../../../utils/hooks'
import { createCompany } from '../../clients/controversies'
import { validateCnpj } from '../../utils'

interface Props {
  name: string
  companies: Pick<Company, 'name'>[]
  onClose: () => any
  onCreated?: (name: string) => any
}

const peerGroupsQuery = loader('./peerGroupsQuery.graphql')

interface PeerGroupsQueryResult {
  peerGroups: string[]
}

interface Error {
  message: string
  subject: string
}

const CreateCompanyModal: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [peerGroups, setPeerGroups] = useState<string[]>([])
  const [peerGroupOption, setPeerGroupOption] = useState<SelectOption | null>(
    null
  )
  const [errors, setErrors] = useState<Error[]>([])
  const [pending, setPending] = useState<boolean>(false)

  const nameInputRef = useRef<HTMLInputElement>(null)
  const tickerInputRef = useRef<HTMLInputElement>(null)
  const cnpjInputRef = useRef<HTMLInputElement>(null)

  const { enqueueToast } = useToast()

  const makePeerGroupsQuery = useImperativeQuery(peerGroupsQuery)

  const peerGroupOptions = useMemo(
    () => peerGroups.map((pg) => ({ label: pg, value: pg })),
    [peerGroups]
  )

  useEffect(() => {
    makePeerGroupsQuery()
      .then((res: PeerGroupsQueryResult) => setPeerGroups(res.peerGroups))
      .finally(() => setLoading(false))
  }, [makePeerGroupsQuery])

  const validateForm = () => {
    const errors: Error[] = []
    if (!nameInputRef.current?.value) {
      errors.push({
        message: 'Nome é obrigatório',
        subject: 'name',
      })
    }

    if (props.companies.some((c) => c.name === nameInputRef.current?.value)) {
      errors.push({
        message: 'Já existe uma empresa cadastrada com este nome',
        subject: 'name',
      })
    }

    if (!peerGroupOption) {
      errors.push({
        message: 'Peer group é obrigatório',
        subject: 'peerGroup',
      })
    }

    if (!validateCnpj(cnpjInputRef.current?.value || '')) {
      errors.push({ message: 'CNPJ inválido', subject: 'cnpj' })
    }

    setErrors(errors)

    return errors.length === 0
  }

  const handleChangeCnpj = (newCnpj: string) => {
    const digits = newCnpj.replace(/\D/g, '').split('')
    const formatted = digits.slice(0, 2)

    if (digits.length >= 3) {
      formatted.push('.', ...digits.slice(2, 5))
    }

    if (digits.length >= 6) {
      formatted.push('.', ...digits.slice(5, 8))
    }

    if (digits.length >= 9) {
      formatted.push('/', ...digits.slice(8, 12))
    }

    if (digits.length >= 13) {
      formatted.push('-', ...digits.slice(12, 14))
    }

    if (cnpjInputRef.current) {
      cnpjInputRef.current.value = formatted.join('')
    }

    validateForm()
  }

  const handleSubmit = () => {
    if (pending || !validateForm()) {
      return
    }

    const name = nameInputRef.current!.value

    setPending(true)
    createCompany({
      name,
      peerGroup: peerGroupOption!.value,
      ticker: tickerInputRef.current?.value || '',
      cnpj: cnpjInputRef.current?.value || '',
    })
      .then(() => {
        enqueueToast({
          message: `Empresa ${name} criada com sucesso`,
          type: 'success',
        })
        props.onCreated?.(name)
        props.onClose()
      })
      .catch((err) => {
        const msg =
          err?.response?.data?.message ??
          'Ocorreu um erro inexperado. Por favor, tente novamente.'
        setErrors([{ message: msg, subject: '' }])
      })
      .finally(() => setPending(false))
  }

  return (
    <Modal
      className="p-8 relative"
      open={!!props.name}
      onClose={props.onClose}
      closeButton
    >
      {loading && <LoadingOverlay type="local" />}
      <h2 className="text-2xl">Cadastrar nova empresa</h2>
      <p className="mt-4 italic text-sm">
        Antes de cadastrar uma nova empresa, verifique no Compass se a empresa
        já existe com nome alternativo.
      </p>
      <p className="italic text-sm">
        O preenchimento do ticker/CNPJ não é obrigatório mas permite a emissão
        de alertas quando uma controvérsia é cadastrada no sistema.
      </p>
      <div className="mt-4 grid grid-cols-[max-content_auto] gap-2 items-center">
        <span>Nome:</span>
        <input
          ref={nameInputRef}
          className={classNames(
            'border px-2 h-[38px]',
            errors.some((e) => e.subject === 'name') && 'border-jgp-error-dark'
          )}
          defaultValue={props.name}
          onBlur={validateForm}
        />
        <span>Peer group:</span>
        <Select
          color="dark"
          icon={null}
          options={peerGroupOptions}
          value={peerGroupOption}
          onChange={setPeerGroupOption}
        />
        <span>Ticker:</span>
        <input
          ref={tickerInputRef}
          className="border px-2 h-[38px]"
          placeholder="Ex.: PETR4 BZ EQUITY"
        />
        <span>CNPJ:</span>
        <input
          ref={cnpjInputRef}
          className={classNames(
            'border px-2 h-[38px]',
            errors.some((e) => e.subject === 'cnpj') && 'border-jgp-error-dark'
          )}
          onBlur={(event) => handleChangeCnpj(event.target.value)}
        />
      </div>
      <ul className="mt-2 min-h-[20px] text-sm text-jgp-error-dark">
        {errors.map((error) => (
          <li key={error.message}>{error.message}</li>
        ))}
      </ul>
      <div className="flex justify-end gap-x-6 mt-4">
        <button
          className="text-jgp-success-dark flex items-center justify-center w-[4.5rem]"
          disabled={pending}
          onClick={handleSubmit}
        >
          {pending ? <Spinner size={18} /> : 'Confirmar'}
        </button>
        <button
          className="text-jgp-danger-dark"
          disabled={pending}
          onClick={props.onClose}
        >
          Cancelar
        </button>
      </div>
    </Modal>
  )
}

export default CreateCompanyModal
