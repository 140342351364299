import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useSectoralReport } from '../SectoralReport'

interface IActiveCompaniesContext {
  activeCompanies: string[]
  setActiveCompanies: (companies: string[]) => void
  allCompanies: string[]
  setAllCompanies: (companies: string[]) => void
}

const ActiveCompaniesContext = createContext<IActiveCompaniesContext>(
  {} as IActiveCompaniesContext
)

export const ActiveCompaniesContextProvider: React.FC = (props) => {
  const [activeCompanies, setActiveCompanies] = useState<string[]>([])
  const [allCompanies, setAllCompanies] = useState<string[]>([])

  const { loading, sector, companies, year } = useSectoralReport()

  useEffect(() => {
    if (loading) {
      return
    }

    if (!sector) {
      setAllCompanies([])
      setActiveCompanies([])
      return
    }

    const availableCompanies = companies
      .filter((company) => company.availableYears.includes(year!))
      .map((company) => company.name)
    setAllCompanies(availableCompanies)
    setActiveCompanies(availableCompanies)
  }, [loading, sector, companies, year])

  const value = useMemo(
    () => ({
      activeCompanies,
      setActiveCompanies,
      allCompanies,
      setAllCompanies,
    }),
    [activeCompanies, allCompanies]
  )

  return (
    <ActiveCompaniesContext.Provider value={value}>
      {props.children}
    </ActiveCompaniesContext.Provider>
  )
}

export const useActiveCompanies = () => {
  const context = useContext(ActiveCompaniesContext)

  if (!context) {
    throw new Error(
      'useActiveCompanies must be used within an ActiveCompaniesContextProvider'
    )
  }

  return context
}
