import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import { slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import ESGSection from '../../components/ESGSection'
import { useSectoralReport } from '../../contexts/SectoralReport'

interface Props {
  section: ESGSection
  className?: string
}

interface QueryResult {
  multiCompanyFramework: {
    sections: {
      title: string
      defaultWeight: number | null
      subsections: {
        title: string
      }[]
    }[]
  }
}

const query = loader('./query.graphql')

const ESGSectionContainer: React.FC<Props> = (props) => {
  const [weight, setWeight] = useState<number | null>(null)
  const [subsections, setSubsections] = useState<
    { id: string; label: string }[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingSector, companies, year } = useSectoralReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    setLoading(true)

    if (loadingSector) {
      return
    }

    makeQuery({
      companyIds: companies.map((company) => company.id),
      year,
    }).then((data: QueryResult) => {
      const section = data.multiCompanyFramework.sections.find(
        (s) => slugify(s.title) === props.section
      )

      if (!section) {
        throw new Error(`Section ${props.section} does not exist`)
      }

      setWeight(section.defaultWeight)
      setSubsections([
        { id: 'overview', label: 'Visão geral' },
        ...(section?.subsections.map((subsection) => ({
          id: slugify(subsection.title),
          label: subsection.title,
        })) ?? []),
      ])
      setLoading(false)
    })
  }, [loadingSector, makeQuery, companies, year, props.section])

  return (
    <ESGSection
      className={props.className}
      loading={loading}
      section={props.section}
      subsections={subsections}
      weight={weight}
    />
  )
}

export default ESGSectionContainer
