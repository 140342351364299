import classNames from 'classnames'
import type React from 'react'
import { useState } from 'react'

import { WarningIcon } from '../../../../components/icons'
import { useToast } from '../../../../contexts/Toast'
import type { Controversy, SearchItem } from '../../clients/controversies'
import type { Action } from '../ControversiesActionModal'
import ControversiesActionModal from '../ControversiesActionModal'

interface Props {
  hits: SearchItem[]
  company: Pick<Company, 'id' | 'name'>
  type: 'environmental' | 'social' | 'governance'
  controversies: Record<string, boolean>
  onInsertControversy: (args: {
    companyId: string
    controversy: Controversy
  }) => Promise<any>
  onRemoveControversy: (args: {
    companyId: string
    controversy: { data: Pick<Controversy['data'], 'link'> }
  }) => Promise<any>
}

const SearchResults: React.FC<Props> = (props) => {
  const [action, setAction] = useState<Action | null>(null)
  const [isPending, setIsPending] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const { enqueueToast } = useToast()

  const handleAction = (action: Action) => {
    const handler =
      action.intent === 'insert'
        ? props.onInsertControversy
        : props.onRemoveControversy

    setError(null)
    setIsPending(true)
    handler({
      companyId: props.company.id,
      controversy: { data: action.hit, type: props.type },
    })
      .then(() => {
        enqueueToast({
          message: `Controvérsia ${action.intent === 'insert' ? 'marcada' : 'removida'} com sucesso`,
          type: 'success',
        })
        setAction(null)
      })
      .catch((err) => {
        console.error('Failed to insert controversy:', err)
        setError('Ocorreu um erro inexperado. Por favor, tente novamente.')
      })
      .finally(() => setIsPending(false))
  }

  return (
    <ul className="flex flex-col gap-y-8">
      {props.hits.map((hit) => (
        <li
          key={hit.link}
          className="grid grid-cols-[auto_min-content] gap-x-4"
        >
          <div className="flex flex-col">
            <a
              className="text-jgp-success-dark text-lg"
              dangerouslySetInnerHTML={{ __html: hit.htmlTitle }}
              href={hit.link}
              target="_blank"
              rel="noopener noreferrer"
            />
            <span
              className="text-sm text-jgp-success-main"
              dangerouslySetInnerHTML={{ __html: hit.displayLink }}
            />
            <span dangerouslySetInnerHTML={{ __html: hit.htmlSnippet }} />
          </div>
          <button
            className={classNames({
              'opacity-10 hover:opacity-100 hover:text-yellow-500':
                !props.controversies[hit.link],
              'text-yellow-500': props.controversies[hit.link],
            })}
            title={
              props.controversies[hit.link]
                ? 'Desmarcar como controvérsia'
                : 'Marcar como controvérsia'
            }
            onClick={() =>
              setAction({
                intent: props.controversies[hit.link] ? 'remove' : 'insert',
                hit,
              })
            }
          >
            <WarningIcon className="w-6 h-6 self-center" />
          </button>
        </li>
      ))}
      <ControversiesActionModal
        action={action}
        company={props.company}
        error={error}
        isPending={isPending}
        onAction={handleAction}
        onClose={() => {
          setAction(null)
          setError(null)
        }}
      />
    </ul>
  )
}

export default SearchResults
