import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { uniq } from 'ramda'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { useImperativeQuery } from '../../../../utils/hooks'
import Baskets from '../../components/Baskets'
import type { BasketFilter, BasketFilterOption } from '../../typings/baskets'
import { useBasketsReducer } from './reducer'

interface Props {
  className?: string
}

interface QueryResult {
  basketCriteria: BasketFilterOption[]
  userBaskets: UserBasket[]
}

interface BasketResultQueryResult {
  basketResult: {
    id: string
    name: string
    sector: { name: string }
    frameworks: { metadata: { name: string } }[]
  }[]
}

interface UserBasketsQueryResult {
  userBaskets: UserBasket[]
}

interface BasketQueryResult {
  basket: UserBasket | null
}

const query = loader('./query.graphql')
const basketResultQuery = loader('./basketResult.graphql')
const userBasketsQuery = loader('./userBasketsQuery.graphql')
const basketQuery = loader('./basketQuery.graphql')
const createBasketMutation = loader('./mutationCreateBasket.graphql')
const deleteBasketMutation = loader('./mutationDeleteBasket.graphql')
const saveBasketMutation = loader('./mutationSaveBasket.graphql')

const convertFiltersToGraphQLInput = (filters: BasketFilter[]) =>
  filters.map((filter) => ({
    name: filter.filter.value,
    type: filter.mode.value,
    values: filter.values.map((value) => value.value),
  }))

const useCompanyCount = (filters: BasketFilter[]) => {
  const lastRequestIdRef = useRef<string>('')

  const makeBasketResultQuery = useImperativeQuery(basketResultQuery)

  const [companyCount, setCompanyCount] = useState<number | null>(null)

  useEffect(() => {
    setCompanyCount(null)

    const requestId = uuid()
    lastRequestIdRef.current = requestId

    makeBasketResultQuery({
      input: { criteria: convertFiltersToGraphQLInput(filters) },
    })
      .then(
        (data: BasketResultQueryResult) =>
          requestId === lastRequestIdRef.current &&
          setCompanyCount(data.basketResult.length)
      )
      .catch((err) => console.error('Failed to get company count:', err))
  }, [filters, makeBasketResultQuery])

  return companyCount
}

const BasketsContainer: React.FC<Props> = (props) => {
  const { search } = useLocation()
  const [state, dispatch] = useBasketsReducer()

  const basketId = useMemo(
    () => new URLSearchParams(search).get('id') ?? null,
    [search]
  )

  const makeQuery = useImperativeQuery(query)
  const makeBasketResultQuery = useImperativeQuery(basketResultQuery)
  const makeUserBasketsQuery = useImperativeQuery(userBasketsQuery, {
    fetchPolicy: 'network-only',
  })
  const makeBasketQuery = useImperativeQuery(basketQuery)
  const companyCount = useCompanyCount(state.filters)
  const [createBasket] = useMutation(createBasketMutation)
  const [deleteBasket] = useMutation(deleteBasketMutation)
  const [saveBasket] = useMutation(saveBasketMutation)

  useEffect(() => {
    dispatch({ type: 'SET_STATE', loadingFiltersData: true })

    const basketPromise = basketId
      ? makeBasketQuery({ id: basketId })
          .then((data: BasketQueryResult) => data.basket)
          .catch((err) => {
            console.error('Failed to fetch report:', err)
            return null
          })
      : null

    makeQuery()
      .then(async (data: QueryResult) => {
        dispatch({
          type: 'SET_STATE',
          filterOptions: data.basketCriteria,
          userBaskets: data.userBaskets,
        })

        const basket = await basketPromise

        if (basket) {
          dispatch({ type: 'LOAD_BASKET_SETTINGS', basket })
        }
      })
      .finally(() => dispatch({ type: 'SET_STATE', loadingFiltersData: false }))
  }, [dispatch, makeQuery, makeBasketQuery, basketId])

  const handleSubmit = async () => {
    dispatch({ type: 'SET_STATE', companies: null, loadingCompanies: true })
    return makeBasketResultQuery({
      input: {
        criteria: convertFiltersToGraphQLInput(state.filters),
      },
    })
      .then((data: BasketResultQueryResult) =>
        dispatch({
          type: 'SET_STATE',
          companies: data.basketResult.map((company) => ({
            id: company.id,
            name: company.name,
            sector: company.sector.name,
            frameworks: uniq(
              company.frameworks
                .map((fw) => fw.metadata.name)
                .sort((a, b) => a.localeCompare(b))
            ),
          })),
        })
      )
      .catch((err) => {
        console.error('Failed to get basket result:', err)
      })
      .finally(() => dispatch({ type: 'SET_STATE', loadingCompanies: false }))
  }

  const handleSave = async () => {
    const savedBasket = state.userBaskets.find(
      (basket) => basket.name === state.basketName
    )

    const promise = savedBasket
      ? saveBasket({
          variables: {
            input: {
              id: savedBasket.id,
              name: state.basketName,
              criteria: convertFiltersToGraphQLInput(state.filters),
            },
          },
        })
      : createBasket({
          variables: {
            input: {
              name: state.basketName,
              criteria: convertFiltersToGraphQLInput(state.filters),
            },
          },
        })

    await promise.then((res) => {
      const error =
        res.data.saveUserBasket?.error ?? res.data.createUserBasket?.error

      if (error) {
        throw new Error(error)
      }

      updateUserBaskets()
    })
  }

  const handleOpenBasket = (id: string) => {
    const basket = state.userBaskets.find((basket) => basket.id === id)

    if (basket) {
      dispatch({ type: 'LOAD_BASKET_SETTINGS', basket })
    }
  }

  const handleDeleteBasket = (id: string) =>
    deleteBasket({ variables: { input: { id } } }).then(updateUserBaskets)

  const updateUserBaskets = async () =>
    makeUserBasketsQuery()
      .then((data: UserBasketsQueryResult) =>
        dispatch({ type: 'SET_STATE', userBaskets: data.userBaskets })
      )
      .catch((err) => console.error('Failed to fetch user baskets:', err))

  return (
    <Baskets
      className={props.className}
      loadingFiltersData={state.loadingFiltersData}
      loadingCompanies={state.loadingCompanies}
      companyCount={companyCount}
      filters={state.filters}
      filterOptions={state.filterOptions}
      companies={state.companies}
      basketName={state.basketName}
      userBaskets={state.userBaskets}
      onAddFilter={() => dispatch({ type: 'ADD_FILTER' })}
      onRemoveFilter={(id) => dispatch({ type: 'REMOVE_FILTER', id })}
      onChangeFilterFilter={(id, filter) =>
        dispatch({ type: 'CHANGE_FILTER_FILTER', id, filter })
      }
      onChangeFilterMode={(id, mode) =>
        dispatch({ type: 'CHANGE_FILTER_MODE', id, mode })
      }
      onChangeFilterValues={(id, values) =>
        dispatch({ type: 'CHANGE_FILTER_VALUES', id, values })
      }
      onSubmit={handleSubmit}
      onBasketNameChange={(name) =>
        dispatch({ type: 'SET_STATE', basketName: name })
      }
      onSave={handleSave}
      onOpenBasket={handleOpenBasket}
      onDeleteBasket={handleDeleteBasket}
    />
  )
}

export default BasketsContainer
