import classNames from 'classnames'
import React from 'react'
import ReactDOM from 'react-dom'

import { XIcon } from '../icons'

interface Props {
  open: boolean
  onClose: () => void
  id?: string
  dismissable?: boolean
  closeButton?: boolean
  className?: string
}

const Modal: React.FC<Props> = (props) => {
  if (!props.open) {
    return null
  }

  return (
    <div
      id={props.id}
      className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-10 p-4"
      onMouseDown={() => props.dismissable && props.onClose()}
    >
      <div
        className={classNames(
          'relative flex max-h-full max-w-full flex-col bg-white shadow-xl',
          props.className
        )}
        onMouseDown={(event) => event.stopPropagation()}
      >
        {props.children}
        {props.closeButton && (
          <button
            className="absolute top-0 right-0 m-2 flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2"
            onClick={() => props.onClose()}
          >
            <XIcon className="h-4 w-4" />
          </button>
        )}
      </div>
    </div>
  )
}

const ModalWithPortal: React.FC<Props> = (props) =>
  ReactDOM.createPortal(<Modal {...props} />, document.body)

export default ModalWithPortal
