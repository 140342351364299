import React from 'react'
import { Redirect } from 'react-router-dom'

import NotFound from '../../../../components/NotFound'
import ScoreCard from '../../../../components/ScoreCard'
import { useWatchLoading } from '../../../../contexts/Loading'
import { sectionIdToLabel } from '../../../../utils'
import { Path } from '../../../../utils/constants'
import ESGSectionOverviewContainer from '../../containers/ESGSectionOverview'
import ESGSubsectionContainer from '../../containers/ESGSubsection'
import { formatRanking } from '../../utils'
import { useCompanyPath } from '../../utils/hooks'

interface Props {
  loading: boolean
  section: ESGSection
  subsections: { id: string; label: string }[]
  score: number | null
  ranking: number[]
  peerAverageScore: number | null
  weight: number | null
}

const ESGSection: React.FC<Props> = (props) => {
  const { companyName, section, subsection } = useCompanyPath()
  useWatchLoading(props.loading)

  if (!subsection) {
    return (
      <Redirect
        to={[Path.REPORTS_COMPANY, companyName, section, 'overview'].join('/')}
      />
    )
  }

  const renderBody = () => {
    if (subsection === 'overview') {
      return (
        <ESGSectionOverviewContainer
          className="mt-20"
          section={props.section}
        />
      )
    }

    const label = props.subsections.find((sub) => sub.id === subsection)

    return label ? (
      <ESGSubsectionContainer
        key={label.label}
        className="mt-20"
        section={props.section}
        subsection={label.label}
      />
    ) : (
      <NotFound />
    )
  }

  return props.loading ? null : (
    <div className="pb-20 pl-10 pr-4 font-work-sans">
      <div className="text-3xl font-semibold">
        {sectionIdToLabel(props.section)}
      </div>
      <div className="mt-8 flex">
        <ScoreCard
          color="primary"
          title="Score"
          value={props.score!.toFixed(0)}
        />
        <ScoreCard
          className="ml-6"
          color="secondary"
          title="Ranking"
          value={formatRanking(props.ranking[0], props.ranking[1])}
        />
        <ScoreCard
          className="ml-6"
          color="secondary"
          title="Peso"
          value={(100 * props.weight!).toFixed(0) + '%'}
        />
        <ScoreCard
          className="ml-6"
          color="secondary"
          title="Média Peers"
          value={props.peerAverageScore!.toFixed(0)}
        />
      </div>
      {renderBody()}
    </div>
  )
}

export default ESGSection
