import _slugify from 'slugify'

import { Path } from './constants'

export const sectionIdToLabel = (section: ESGSection) => {
  switch (section) {
    case 'environmental':
      return 'Ambiental'
    case 'social':
      return 'Social'
    case 'governance':
      return 'Governança'
  }
}

export const replaceParamsInPath = (
  path: string,
  replacements: Record<string, string>
) => {
  let result = path
  Object.entries(replacements).forEach(([key, value]) => {
    result = result.replace(':' + key, value)
  })

  return result
}

export const formatCompanyName = (company: Pick<Company, 'name' | 'ticker'>) =>
  company.ticker ? `[${company.ticker}] ${company.name}` : company.name

export const slugify = (str: string) =>
  _slugify(str, {
    lower: true,
    locale: 'pt',
  })

export const computeAverage = (values: number[]) =>
  values.length > 0 ? values.reduce((a, b) => a + b) / values.length : 0

export const stringToSelectOption = (s: string) => ({ label: s, value: s })

export const getCompanyUrl = (name: string) =>
  `${Path.REPORTS_COMPANY}/${slugify(name)}`

export const getSectorUrl = (sector: string) =>
  `${Path.REPORTS_SECTORAL}/${slugify(sector)}`
