import { CustomTooltipProps } from 'ag-grid-react'

import type { TableCellValue } from './types'

const CellTooltip: React.FC<CustomTooltipProps<any, TableCellValue>> = ({
  value,
}) => {
  if (typeof value === 'number' || value === null || value === undefined) {
    return <span />
  }

  return (
    <div className="border border-black bg-white p-2">{value.subjects}</div>
  )
}

export default CellTooltip
