import React from 'react'

const SocialIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.12432 16.0008C11.0989 15.9408 13.1769 15.7546 13.9981 13.6623C14.8631 11.4595 13.6941 9.61969 12.1771 8.03862C12.0705 7.92501 11.942 7.8335 11.8354 7.71673C11.2556 7.08241 10.7134 7.03507 10.2119 7.80825C9.30611 9.21259 8.40971 9.13054 7.47884 7.81456C6.91154 7.00983 6.30976 7.13606 5.69545 7.81141C4.20355 9.44296 3.1379 11.4153 3.96221 13.514C4.72697 15.4643 6.77677 15.9818 8.8109 16.0039C8.91746 16.0008 9.02089 16.0008 9.12432 16.0008ZM11.7257 3.95815C11.6975 2.43389 10.2809 1.03902 8.77955 1.0548C7.43496 1.07058 5.96813 2.63586 6.03082 3.9834C6.09977 5.4193 7.53839 6.82995 8.90492 6.80154C10.3529 6.77314 11.754 5.35933 11.7257 3.95815ZM18.0005 9.1116C17.7937 7.99444 17.4269 6.96565 16.6904 6.10411C16.0071 5.30884 15.3019 4.76289 14.3899 5.84533C14.1109 6.17669 13.7473 6.3692 13.2772 6.11358C13.0139 5.96841 12.666 5.76328 12.4404 6.11042C12.2241 6.44178 12.5124 6.70371 12.7412 6.912C13.8978 7.95342 14.7002 9.21574 15.1609 10.7116C15.2988 11.1629 15.5119 11.652 16.1576 11.409C17.217 11.0114 17.8877 10.2824 18.0005 9.1116ZM0.00992147 9.11791C-0.0872403 10.0236 0.596026 10.7463 1.58958 11.2733C2.0942 11.5416 2.51732 11.5037 2.69284 10.8883C3.15044 9.28517 3.97475 7.9187 5.14696 6.74158C5.48859 6.3976 5.3977 5.6907 4.89622 5.94316C3.87759 6.46072 3.36984 5.97472 2.74299 5.37827C2.30419 4.9617 1.83405 5.19839 1.50182 5.56446C0.677517 6.47649 0.091412 7.51476 0.00992147 9.11791ZM15.8442 2.50647C15.8661 1.22521 15.4774 0.515153 14.6093 0.190104C13.8602 -0.090764 13.0641 -0.100231 12.3996 0.448881C11.5753 1.13054 12.2836 1.75539 12.5187 2.39287C12.572 2.53803 12.6441 2.68004 12.688 2.82837C12.9074 3.56367 12.9857 4.51042 14.1078 4.24849C15.1609 4.00549 15.7877 3.29227 15.8442 2.50647ZM4.11892 0.0417804C2.70224 0.0733385 1.89987 0.88754 1.92808 2.112C1.95316 3.24178 2.98746 4.33369 3.97161 4.26742C4.46683 4.23271 5.80202 1.98261 5.70172 1.2189C5.57008 0.21535 4.78965 0.0701827 4.11892 0.0417804Z"
      fill="currentColor"
    />
  </svg>
)

export default SocialIcon
