import { DocumentNode, QueryHookOptions, useLazyQuery } from '@apollo/client'
import { useCallback, useEffect, useRef, useState } from 'react'

export const useImperativeQuery = (
  query: DocumentNode,
  options?: QueryHookOptions<any, Record<string, any>>
) => {
  const [running, setRunning] = useState<boolean>(false)
  const [makeQuery, { loading, data, error }] = useLazyQuery(
    query,
    options ?? {}
  )

  const promise = useRef<any>(null)

  useEffect(() => {
    if (running && !loading && data) {
      promise.current.resolve(data)
      setRunning(false)
    } else if (running && !loading && error) {
      promise.current.reject(error)
      setRunning(false)
    }
  }, [running, loading, data, error])

  const runQuery = useCallback(
    (variables?: Record<string, any>) => {
      // Delay state change to fix cached query not returning most recent value
      setTimeout(() => setRunning(true), 50)
      makeQuery({ variables })
      return new Promise<any>(
        (resolve, reject) => (promise.current = { resolve, reject })
      )
    },
    [makeQuery]
  )

  return runQuery
}

export const usePrevious = (value: any) => {
  const ref = useRef<any>()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}
