import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import React, { useCallback, useEffect, useState } from 'react'

import { useCompanies } from '../../../../contexts/Companies'
import { useSectors } from '../../../../contexts/Sectors'
import { slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import Config from '../../components/Config'

interface Props {
  className?: string
}

interface QueryResult {
  user: {
    favorites: {
      companies: string[]
      sectors: string[]
    }
    notifications: {
      companies: string[]
      sectors: string[]
    }
  }
}

const query = loader('./query.graphql')
const mutationSetFavorite = loader('./mutationSetFavorite.graphql')
const mutationSetNotification = loader('./mutationSetNotification.graphql')

const ConfigContainer: React.FC<Props> = (props) => {
  const [companyList, setCompanyList] = useState<CompanyBase[]>([])
  const [sectorList, setSectorList] = useState<string[]>([])
  const [favoriteCompanies, setFavoriteCompanies] = useState<string[]>([])
  const [favoriteSectors, setFavoriteSectors] = useState<string[]>([])
  const [notifiedCompanies, setNotifiedCompanies] = useState<string[]>([])
  const [notifiedSectors, setNotifiedSectors] = useState<string[]>([])

  const makeQuery = useImperativeQuery(query)
  const [changeFavorite] = useMutation(mutationSetFavorite)
  const [changeNotification] = useMutation(mutationSetNotification)
  const { loading: loadingCompanies, companies } = useCompanies()
  const { loading: loadingSectors, sectors } = useSectors()

  useEffect(() => {
    if (loadingCompanies || loadingSectors) {
      return
    }

    makeQuery().then((data: QueryResult) => {
      setCompanyList(
        companies
          .filter(
            (company) =>
              data.user.favorites.companies.includes(slugify(company.name)) ||
              data.user.notifications.companies.includes(slugify(company.name))
          )
          .sort((a, b) => a.name.localeCompare(b.name))
      )
      setSectorList(
        sectors
          .filter(
            (sector) =>
              data.user.favorites.sectors.includes(slugify(sector)) ||
              data.user.notifications.sectors.includes(slugify(sector))
          )
          .sort((a, b) => a.localeCompare(b))
      )
      setFavoriteCompanies(data.user.favorites.companies)
      setFavoriteSectors(data.user.favorites.sectors)
      setNotifiedCompanies(data.user.notifications.companies)
      setNotifiedSectors(data.user.notifications.sectors)
    })
  }, [loadingCompanies, loadingSectors, makeQuery, companies, sectors])

  const handleFavoriteChange = useCallback(
    (subject: string, type: 'COMPANY' | 'SECTOR', value: boolean) =>
      changeFavorite({
        variables: {
          subject,
          type,
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setFavoriteCompanies(res.data.setFavorite.favorites.companies)
        setFavoriteSectors(res.data.setFavorite.favorites.sectors)
      }),
    [changeFavorite]
  )

  const handleNotificationChange = useCallback(
    (subject: string, type: 'COMPANY' | 'SECTOR', value: boolean) =>
      changeNotification({
        variables: {
          subject,
          type,
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setNotifiedCompanies(res.data.setNotification.notifications.companies)
        setNotifiedSectors(res.data.setNotification.notifications.sectors)
      }),
    [changeNotification]
  )

  return (
    <Config
      className={props.className}
      loading={loadingCompanies || loadingSectors}
      companyList={companyList}
      sectorList={sectorList}
      favoriteCompanies={favoriteCompanies}
      favoriteSectors={favoriteSectors}
      notifiedCompanies={notifiedCompanies}
      notifiedSectors={notifiedSectors}
      onFavoriteChange={handleFavoriteChange}
      onNotificationChange={handleNotificationChange}
    />
  )
}

export default ConfigContainer
