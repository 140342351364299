import React from 'react'

import WarningIcon from '../icons/Warning'

const NotFound: React.FC = () => (
  <div className="flex h-full w-full items-center justify-center">
    <div className="flex flex-col items-center text-gray-400">
      <WarningIcon className="h-24 w-24" />
      <span className="text-2xl">Página não encontrada</span>
    </div>
  </div>
)

export default NotFound
