import classNames from 'classnames'
import React, { useState } from 'react'

import Spinner, { Props as SpinnerProps } from '../Spinner'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  spinnerProps: SpinnerProps
  innerClass?: string // DEPRECATED: Use className as normal
  onClick?: () => any
}

const ButtonWithLoading: React.FC<Props> = (props) => {
  const { spinnerProps, innerClass, disabled, ...remProps } = props

  const [loading, setLoading] = useState<boolean>(false)

  const handleClick = async () => {
    setLoading(true)
    await props.onClick?.()
    setLoading(false)
  }

  return (
    <button
      {...remProps}
      onClick={handleClick}
      className={classNames(
        'relative',
        { 'cursor-default': loading },
        props.className,
        props.innerClass
      )}
      disabled={disabled || loading}
    >
      {!loading && props.children}
      {loading && (
        <span className="absolute top-1/2 left-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center">
          <Spinner {...spinnerProps} />
        </span>
      )}
    </button>
  )
}

export default ButtonWithLoading
