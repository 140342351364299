import React from 'react'
import { useHistory } from 'react-router-dom'

import { useUser } from '../../contexts/User'
import { Path } from '../../utils/constants'
import ReportsContainer from './containers/Reports'

interface Props {
  className?: string
}

const ReportsPage: React.FC<Props> = (props) => {
  const { email } = useUser()
  const history = useHistory()

  if (!email) {
    history.push(Path.LOGIN)
  }

  return <ReportsContainer className={props.className} />
}

export default ReportsPage
