import { useCallback, useEffect, useState } from 'react'

import {
  type Controversy,
  type SearchArgs,
  type SearchItem,
  checkControversies,
  deleteControversy,
  getCompanies,
  getSearchResults,
  saveControversy,
} from '../../clients/controversies'
import ControversiesSearch from '../../components/ControversiesSearch'

const ControversiesSearchContainer: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [companies, setCompanies] = useState<Pick<Company, 'id' | 'name'>[]>([])
  const [searchResults, setSearchResults] = useState<SearchItem[] | null>(null)
  const [nextPage, setNextPage] = useState<[string, SearchArgs] | null>(null)
  const [controversies, setControversies] = useState<Record<string, boolean>>(
    {}
  )

  const fetchCompanies = useCallback(
    () =>
      getCompanies().then((companies: Pick<Company, 'id' | 'name'>[]) =>
        companies.sort((a, b) => a.name.localeCompare(b.name))
      ),
    []
  )

  useEffect(() => {
    fetchCompanies()
      .then(setCompanies)
      .finally(() => setLoading(false))
  }, [fetchCompanies])

  const handleSearch = useCallback(
    async (companyId: string, args: SearchArgs) => {
      return getSearchResults(args)
        .then((res) => {
          setSearchResults(res.items)
          setNextPage(res.nextPage ? [companyId, res.nextPage] : null)
          return checkControversies({
            companyId,
            links: res.items.map((item) => item.link),
          })
        })
        .then((controversies) =>
          setControversies((prev) => ({ ...prev, ...controversies }))
        )
    },
    []
  )

  const handleNextPage = useCallback(async () => {
    if (!searchResults?.length || !nextPage) {
      return
    }

    return getSearchResults(nextPage[1])
      .then((res) => {
        setSearchResults((prev) => [...(prev ?? []), ...res.items])
        setNextPage(res.nextPage ? [nextPage[0], res.nextPage] : null)
        return checkControversies({
          companyId: nextPage[0],
          links: res.items.map((item) => item.link),
        })
      })
      .then((controversies) =>
        setControversies((prev) => ({ ...prev, ...controversies }))
      )
  }, [nextPage, searchResults?.length])

  const handleInsertControversy = useCallback(
    async (args: { companyId: string; controversy: Controversy }) =>
      saveControversy(args).then(() => {
        setControversies((prev) => ({
          ...prev,
          [args.controversy.data.link]: true,
        }))
      }),
    []
  )

  const handleRemoveControversy = useCallback(
    async (args: {
      companyId: string
      controversy: { data: Pick<Controversy['data'], 'link'> }
    }) =>
      deleteControversy(args).then(() => {
        setControversies((prev) => ({
          ...prev,
          [args.controversy.data.link]: false,
        }))
      }),
    []
  )

  const handleCreatedCompany = useCallback(
    async (name: string) =>
      fetchCompanies().then((companies) => {
        setCompanies(companies)
        return companies.find((c) => c.name === name)!
      }),
    [fetchCompanies]
  )

  return (
    <ControversiesSearch
      loading={loading}
      companies={companies}
      searchResults={searchResults}
      controversies={controversies}
      onSearch={handleSearch}
      onNextPage={handleNextPage}
      onInsertControversy={handleInsertControversy}
      onRemoveControversy={handleRemoveControversy}
      onCreatedCompany={handleCreatedCompany}
    />
  )
}

export default ControversiesSearchContainer
