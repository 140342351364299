import classNames from 'classnames'
import React, { useMemo } from 'react'
import Select from 'react-select'

import { XIcon } from '../../../../components/icons'
import LabeledComponent from '../../../../components/LabeledComponent'
import type { BasketFilter, BasketFilterOption } from '../../typings/baskets'
import { SELECT_STYLES } from '../../utils/constants'
import styles from './FilterItem.module.css'

interface Props {
  filter: BasketFilter
  options: BasketFilterOption[]
  className?: string
  onChangeFilter?: (filter: SelectOption) => void
  onChangeMode?: (mode: SelectOption) => void
  onChangeValues?: (values: SelectOption[]) => void
  onRemove?: () => void
}

const FilterItem: React.FC<Props> = (props) => {
  const filterOptions = useMemo(
    () =>
      props.options.map(({ label, name }) => ({
        label,
        value: name,
      })),
    [props.options]
  )

  const selectedOption = useMemo(
    () =>
      props.options.find((option) => option.name === props.filter.filter.value),
    [props.options, props.filter]
  )

  return (
    <div
      className={classNames(
        'flex items-end justify-between gap-4',
        props.className
      )}
    >
      <div className="flex flex-grow flex-wrap items-end gap-x-4 gap-y-2">
        <LabeledComponent className="w-56" label="Filtro">
          <Select
            styles={SELECT_STYLES}
            placeholder="Escolha o filtro"
            options={filterOptions}
            value={props.filter.filter}
            isSearchable={false}
            onChange={(value) => value && props.onChangeFilter?.(value)}
          />
        </LabeledComponent>
        <Select
          className="w-32"
          styles={SELECT_STYLES}
          placeholder="Modo"
          options={selectedOption?.types ?? []}
          value={props.filter.mode}
          isSearchable={false}
          onChange={(value) => value && props.onChangeMode?.(value)}
        />
        <LabeledComponent
          className={classNames('flex-grow', styles.valuesSelect)}
          label="Valores"
        >
          <Select
            styles={SELECT_STYLES}
            placeholder="Escolha os valores"
            options={selectedOption?.values ?? []}
            value={props.filter.values}
            isMulti
            onChange={(values) =>
              props.onChangeValues?.(values as SelectOption[])
            }
          />
        </LabeledComponent>
      </div>
      <span className={classNames('flex items-center', styles.selectHeight)}>
        <button
          className="flex items-center justify-center self-center rounded-sm bg-jgp-success-main p-1"
          onClick={props.onRemove}
        >
          <XIcon className="h-4 w-4 text-white" />
        </button>
      </span>
    </div>
  )
}

export default FilterItem
