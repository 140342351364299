import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import React from 'react'

import { useCompanies } from '../../../../contexts/Companies'
import { useSectors } from '../../../../contexts/Sectors'
import Reports from '../../components/Reports'

interface Props {
  className?: string
}

interface QueryResult {
  user: {
    favorites: {
      companies: string[]
      sectors: string[]
    }
    recents: {
      companies: string[]
      sectors: string[]
    }
  }
}

const query = loader('./query.graphql')

const ReportsContainer: React.FC<Props> = (props) => {
  const { loading: loadingCompanies, companies } = useCompanies()
  const { loading: loadingSectors, sectors } = useSectors()
  const { loading: loadingUserData, data: userData } =
    useQuery<QueryResult>(query)

  return (
    <Reports
      className={props.className}
      loading={loadingCompanies || loadingSectors || loadingUserData}
      companies={companies}
      sectors={sectors}
      favoriteCompanies={userData?.user.favorites.companies ?? []}
      favoriteSectors={userData?.user.favorites.sectors ?? []}
      recentCompanies={userData?.user.recents.companies ?? []}
      recentSectors={userData?.user.recents.sectors ?? []}
    />
  )
}

export default ReportsContainer
