import React, { useRef } from 'react'

import Button from '../../../../components/Button'
import LabeledComponent from '../../../../components/LabeledComponent'
import Spinner from '../../../../components/Spinner'
import Modal from '../Modal'

interface Props {
  basketName: string
  saving: boolean
  onClose?: () => void
  onBasketNameChange?: (name: string) => void
  onSave?: () => void
}

const SaveModal: React.FC<Props> = (props) => {
  const basketNameInputRef = useRef<HTMLInputElement>(null)

  const handleSave = () => {
    if (!props.basketName) {
      basketNameInputRef.current?.focus()
    } else {
      props.onSave?.()
    }
  }

  return (
    <Modal title="Salvar basket" onClose={props.onClose}>
      <div className="flex flex-wrap items-end gap-4 bg-white px-4 py-8">
        <LabeledComponent label="Nome do basket">
          <input
            ref={basketNameInputRef}
            className="w-80 rounded-sm border px-1 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-jgp-success-main"
            placeholder="Escreva o nome do basket"
            value={props.basketName}
            onChange={(event) => props.onBasketNameChange?.(event.target.value)}
          />
        </LabeledComponent>
        <Button
          className="flex h-8 w-28 items-center justify-center"
          variant="secondary"
          onClick={handleSave}
        >
          {props.saving ? (
            <Spinner className="text-jgp-success-dark" size={18} />
          ) : (
            'Salvar'
          )}
        </Button>
      </div>
    </Modal>
  )
}

export default SaveModal
