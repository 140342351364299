import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { isMobile } from 'react-device-detect'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import apolloClient from './clients/graphql'
import Footer from './components/Footer'
import NotFound from './components/NotFound'
import TopBar from './components/TopBar'
import { ChartRendererContextProvider } from './contexts/ChartRenderer'
import { CompaniesContextProvider } from './contexts/Companies'
import { LoadingContextProvider } from './contexts/Loading'
import { SectorsContextProvider } from './contexts/Sectors'
import { ToastContextProvider } from './contexts/Toast'
import { UserContextProvider } from './contexts/User'
import CompanyReportPage from './pages/CompanyReport'
import ConfigPage from './pages/Config'
import LoginPage from './pages/Login'
import PersonalizedReportPage from './pages/PersonalizedReport'
import ReportsPage from './pages/Reports'
import ScoreStatisticsPage from './pages/ScoreStatistics'
import SectoralReportPage from './pages/SectoralReport'
import ThemesReportPage from './pages/ThemesReport'
import { Path } from './utils/constants'

const App: React.FC = () => {
  if (isMobile) {
    return <div>Este conteúdo não está disponível em dispositivos móveis.</div>
  }

  return (
    <Router>
      <ApolloProvider client={apolloClient}>
        <LoadingContextProvider type="overlay">
          <UserContextProvider>
            <CompaniesContextProvider>
              <SectorsContextProvider>
                <ChartRendererContextProvider>
                  <ToastContextProvider>
                    <div className="flex h-screen flex-col bg-white">
                      <Switch>
                        <Route path="/" exact>
                          <Redirect to={Path.LOGIN} />
                        </Route>
                        <Route path={Path.LOGIN}>
                          <LoginPage className="flex-grow" />
                          <Footer />
                        </Route>
                        <Route>
                          <TopBar className="flex-none overflow-y-scroll" />
                          <div className="flex flex-grow flex-col justify-between overflow-y-scroll">
                            <Switch>
                              <Route path={Path.CONFIG}>
                                <ConfigPage className="flex-grow" />
                              </Route>
                              <Route path={Path.REPORTS} exact>
                                <ReportsPage />
                              </Route>
                              <Route path={Path.REPORTS_COMPANY}>
                                <CompanyReportPage className="flex-grow" />
                              </Route>
                              <Route path={Path.REPORTS_PERSONALIZED}>
                                <PersonalizedReportPage className="flex-grow" />
                              </Route>
                              <Route path={Path.REPORTS_SECTORAL}>
                                <SectoralReportPage className="flex-grow" />
                              </Route>
                              <Route path={Path.REPORTS_STATISTICS}>
                                <ScoreStatisticsPage className="flex-grow" />
                              </Route>
                              <Route path={Path.REPORTS_THEMES}>
                                <ThemesReportPage className="flex-grow" />
                              </Route>
                              <Route>
                                <NotFound />
                              </Route>
                            </Switch>
                            <Footer />
                          </div>
                        </Route>
                      </Switch>
                    </div>
                  </ToastContextProvider>
                </ChartRendererContextProvider>
              </SectorsContextProvider>
            </CompaniesContextProvider>
          </UserContextProvider>
        </LoadingContextProvider>
      </ApolloProvider>
    </Router>
  )
}

export default App
