import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import { computeAverage } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import ScoreTable, { RowData } from '../../components/ScoreTable'
import { useSectoralReport } from '../../contexts/SectoralReport'

interface Props {
  className?: string
}

interface QueryResult {
  multiCompanyFramework: {
    sections: Section[]
  }
}

interface Section {
  title: string
  scores: {
    company: { name: string }
    value: number
  }[]
  defaultWeight: number
  subsections: Subsection[]
}

interface Subsection {
  title: string
  scores:
    | {
        company: { name: string }
        value: number
      }[]
    | null
  defaultWeight: number | null
}

const query = loader('./query.graphql')

const buildRowData = (
  framework: QueryResult['multiCompanyFramework']
): RowData[] => {
  const rows = framework.sections
    .filter((section) => section.scores !== null)
    .map(buildRowDataFromSection)
    .flat()
  return rows
}

const buildRowDataFromSection = (section: Section): RowData[] => {
  const rows = [
    {
      categoryPath: [section.title],
      companyScores: Object.fromEntries(
        section.scores.map((score) => [score.company.name, score.value])
      ),
      average: computeAverage(section.scores.map((score) => score.value)),
      weight: section.defaultWeight,
    },
    ...section.subsections
      .filter((subsection) => subsection.scores !== null)
      .map((subsection) =>
        buildRowDataFromSubsection([section.title], subsection)
      ),
  ]

  return rows
}

const buildRowDataFromSubsection = (
  path: string[],
  subsection: Subsection
): RowData => ({
  categoryPath: path.concat(subsection.title),
  companyScores: Object.fromEntries(
    subsection.scores!.map((score) => [score.company.name, score.value])
  ),
  average: computeAverage(subsection.scores!.map((score) => score.value)),
  weight: subsection.defaultWeight!,
})

const ScoreTableContainer: React.FC<Props> = (props) => {
  const [rowData, setRowData] = useState<RowData[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingSector, companies, year } = useSectoralReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    setLoading(true)

    if (loadingSector) {
      return
    }

    makeQuery({
      companyIds: companies.map((company) => company.id),
      year,
    }).then((data: QueryResult) => {
      setRowData(buildRowData(data.multiCompanyFramework))
      setLoading(false)
    })
  }, [loadingSector, makeQuery, companies, year])

  return (
    <ScoreTable
      className={props.className}
      loading={loading}
      companies={companies.map((company) => company.name)}
      rowData={rowData}
    />
  )
}

export default ScoreTableContainer
