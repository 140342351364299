import type { PermissionName } from '@jgp-er-dev/permissions'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { getUserData } from '../../clients/user'

interface IUserContext {
  email: string | null
  name: string | null
  refetch: () => Promise<void>
  checkPermissions: (permissions: PermissionName[]) => boolean
}

const UserContext = createContext<IUserContext>({} as IUserContext)

export const UserContextProvider: React.FC = (props) => {
  const [email, setEmail] = useState<string | null>(null)
  const [name, setName] = useState<string | null>(null)
  const [permissions, setPermissions] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const refetch = useCallback(() => {
    setLoading(true)
    return getUserData()
      .then((res) => {
        setEmail(res.data.email)
        setName(res.data.name)
        setPermissions(res.data.permissions)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const checkPermissions = useCallback(
    (perms: PermissionName[]) => {
      if (!email) {
        return false
      }

      return perms.every((permission) => permissions.includes(permission))
    },
    [email, permissions]
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  const value = useMemo(
    () => ({ name, email, refetch, checkPermissions }),
    [name, email, refetch, checkPermissions]
  )

  if (loading) {
    return null
  }

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}

export const useUser = () => {
  const ctx = useContext(UserContext)

  if (!ctx) {
    throw new Error('useUser must be used within a UserContextProvider')
  }

  return ctx
}
