import { FunctionComponent } from 'react'
import Select from 'react-select'

interface Props {
  activeCompanies: RemunerationCompany[]
  allCompanies: RemunerationCompany[]
  className?: string
  label?: string
  placeholder?: string
  onActiveCompaniesChange?: (companies: RemunerationCompany[]) => any
}

const companyToSelectOption = (company: RemunerationCompany) => ({
  label: company.name,
  value: company.ticker,
})

const RemunerationCompanySelector: FunctionComponent<Props> = (props) => (
  <div className={props.className}>
    {props.label && <span className="text-xs">{props.label}</span>}
    <Select
      placeholder={props.placeholder}
      options={props.allCompanies.map(companyToSelectOption)}
      value={props.activeCompanies.map(companyToSelectOption)}
      onChange={(values) =>
        props.onActiveCompaniesChange?.(
          values.map(({ label, value }) => ({ name: label, ticker: value }))
        )
      }
      isMulti
    />
  </div>
)

export default RemunerationCompanySelector
