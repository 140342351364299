import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Path } from '../../../utils/constants'

export const useThemesPath = () => {
  const { pathname } = useLocation()

  const value = useMemo(() => {
    const skipCount = Path.REPORTS_THEMES.split('/').length
    const path = pathname.split('/').slice(skipCount)

    return {
      theme: path[0],
      path,
    }
  }, [pathname])

  return value
}
