import classNames from 'classnames'
import React from 'react'

import { CogIcon } from '../icons'

interface Props {
  className?: string
  onClick?: () => void
}

const ManageButton: React.FC<Props> = (props) => (
  <button
    className={classNames(
      'flex items-center text-white focus:outline-none',
      props.className
    )}
    onClick={props.onClick}
  >
    <CogIcon className="mr-1.5 h-6 w-6" />
    Gerenciar
  </button>
)

export default ManageButton
