import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'
import type { SearchItem } from '../../clients/controversies'

export interface Action {
  intent: 'insert' | 'remove'
  hit: SearchItem
}
interface Props {
  action: Action | null
  company: Pick<Company, 'id' | 'name'>
  isPending: boolean
  error?: string | null
  onClose: () => any
  onAction: (action: Action) => any
}

const ControversiesActionModal: React.FC<Props> = (props) => (
  <Modal open={!!props.action} onClose={props.onClose}>
    <div className="p-8">
      <h2 className="text-2xl">
        {props.action?.intent === 'insert'
          ? 'Marcar controvérsia'
          : 'Remover controvérsia'}
      </h2>
      <p className="mt-4">
        {props.action?.intent === 'insert'
          ? `Deseja marcar o resultado abaixo como controvérsia grave da empresa `
          : `Deseja remover o resultado abaixo da lista de controvérsias graves da empresa `}
        <strong>{props.company.name}</strong>?
      </p>
      <div className="mt-4 ml-4 grid grid-cols-[min-content_auto] gap-x-2">
        <span>Título:</span>
        <span>{props.action?.hit.title}</span>
        <span>Link:</span>
        <a
          className="truncate text-jgp-success-main"
          href={props.action?.hit.link}
        >
          {props.action?.hit.link}
        </a>
      </div>
      {props.error && (
        <p className="text-sm text-jgp-props.error-main mt-4">{props.error}</p>
      )}
      <div className="flex justify-end gap-x-6 mt-4">
        <button
          className="text-jgp-success-dark flex items-center justify-center w-[4.5rem]"
          onClick={() => props.action && props.onAction(props.action)}
          disabled={props.isPending}
        >
          {props.isPending ? <Spinner size={18} /> : 'Confirmar'}
        </button>
        <button
          className="text-jgp-danger-dark"
          onClick={props.onClose}
          disabled={props.isPending}
        >
          Cancelar
        </button>
      </div>
    </div>
  </Modal>
)

export default ControversiesActionModal
