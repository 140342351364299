import classNames from 'classnames'
import React, { useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

import styles from './Scrollbar.module.css'

interface Props {
  className?: string
}

const Scrollbar: React.FC<Props> = (props) => {
  const [showScrollbar, setShowScrollbar] = useState<boolean>(false)

  const handleUpdate = (values: any) => {
    const { clientHeight, scrollHeight } = values
    setShowScrollbar(scrollHeight > clientHeight)
  }

  return (
    <Scrollbars
      className={props.className}
      renderThumbVertical={(props) => (
        <div {...props} className="rounded-full bg-jgp-success-main" />
      )}
      renderTrackVertical={
        showScrollbar
          ? (props) => (
              <div
                {...props}
                className={classNames(
                  'top-0 bottom-0 right-0 w-1 rounded-full',
                  styles.scrollbarTrack
                )}
              />
            )
          : undefined
      }
      onUpdate={handleUpdate}
    >
      <div className={classNames({ 'pr-4': showScrollbar })}>
        {props.children}
      </div>
    </Scrollbars>
  )
}

export default Scrollbar
