import classNames from 'classnames'
import { uniq } from 'ramda'
import React, { useMemo } from 'react'

import styles from './ControversiesTable.module.css'

interface Props {
  companies: {
    name: string
    controversies: Controversy[]
  }[]
  className?: string
}

const ControversiesTable: React.FC<Props> = (props) => {
  const subsectionList = useMemo(
    () =>
      uniq(
        props.companies
          .map((company) =>
            company.controversies.map((controversy) => controversy.subsection)
          )
          .flat()
      ),
    [props.companies]
  )

  return (
    <div
      className={classNames(
        'grid cursor-default overflow-x-auto border-2 border-gray-300',
        props.className
      )}
      style={{
        gridTemplateColumns: `max-content repeat(${props.companies.length}, auto)`,
      }}
    >
      <span />
      {props.companies.map((company) => (
        <span
          key={company.name}
          className="flex items-center justify-center border-l border-gray-300 p-2 font-bold"
        >
          {company.name}
        </span>
      ))}
      {subsectionList.map((subsection) => (
        <div key={subsection} className={styles.subsectionRow}>
          <span
            className={classNames(
              'col-start-1 border-t border-gray-300 p-2',
              styles.subsectionCell
            )}
          >
            {subsection}
          </span>
          {props.companies.map((company) => (
            <span
              key={company.name}
              className="flex items-center justify-center border-l border-t border-gray-300 p-2"
            >
              {company.controversies.some(
                (controversy) => controversy.subsection === subsection
              ) ? (
                <span className="h-3 w-3 rounded-full bg-jgp-error-main" />
              ) : null}
            </span>
          ))}
        </div>
      ))}
    </div>
  )
}

export default ControversiesTable
