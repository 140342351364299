import { uniq } from 'ramda'

import { CompanyRemunerationDataHandler } from '../../utils/CompanyRemunerationDataHandler'
import {
  EXTENDED_TIME_SERIES_FIELDS,
  getConsolidatedDate,
  getEstimatedDate,
  TIME_SERIES_FIELDS,
} from '../../utils/remuneration'

export const SUM_COMPANY: RemunerationCompany = {
  name: 'Soma',
  ticker: '__SUM__',
}

export const computeCompanySums = (args: {
  summedCompanies: RemunerationCompany[]
  peers: RemunerationCompany[]
  companiesData: RemunerationData
  includeIncompleteSum: boolean
}) => {
  const someSummedCompanyData = Object.values(args.companiesData).find((data) =>
    args.summedCompanies.some(
      (summed) => data?.company.ticker === summed.ticker
    )
  )

  if (!someSummedCompanyData) {
    return {
      summedConsolidatedCompaniesData: args.companiesData,
      summedEstimatedCompaniesData: args.companiesData,
    }
  }

  const summedData = {
    company: SUM_COMPANY,
    referenceFormUrl: null,
  } as CompanyRemunerationData

  TIME_SERIES_FIELDS.forEach((field) => {
    summedData[field] = {
      unit: someSummedCompanyData[field].unit,
      data: [],
    }
  })

  const consolidatedSumHandler = new CompanyRemunerationDataHandler(summedData)
  const estimatedSumHandler = new CompanyRemunerationDataHandler(summedData)
  const companyHandlers = args.summedCompanies
    .filter((company) => !!args.companiesData[company.ticker])
    .map(
      (company) =>
        new CompanyRemunerationDataHandler(args.companiesData[company.ticker])
    )

  EXTENDED_TIME_SERIES_FIELDS.forEach((field) => {
    const availableYears = uniq(
      companyHandlers
        .map((handler) =>
          handler.getAvailableYears(field).map(getConsolidatedDate)
        )
        .flat()
    )

    availableYears.forEach((year) => {
      let consolidatedSum: number | null = null
      let estimatedSum: number | null = null
      let missingConsolidatedValues = false
      let missingEstimatedValues = false

      companyHandlers.forEach((handler) => {
        const consolidatedValue = handler.getValue(year, field)
        const estimatedValue = handler.getValue(getEstimatedDate(year), field, {
          allowConsolidatedValueForEstimatedYear: false,
        })

        if (consolidatedValue == null) {
          missingConsolidatedValues = true
          if (estimatedValue == null) {
            missingEstimatedValues = true
          } else {
            estimatedSum = (estimatedSum ?? 0) + estimatedValue
          }
        } else {
          consolidatedSum = (consolidatedSum ?? 0) + consolidatedValue
        }
      })

      if (
        !missingConsolidatedValues ||
        (args.includeIncompleteSum && consolidatedSum !== null)
      ) {
        consolidatedSumHandler.setValue(year, field, consolidatedSum)
      }

      if (
        !missingEstimatedValues ||
        (args.includeIncompleteSum &&
          (consolidatedSum !== null || estimatedSum !== null))
      ) {
        estimatedSumHandler.setValue(
          estimatedSum !== null ? getEstimatedDate(year) : year,
          field,
          (consolidatedSum ?? 0) + (estimatedSum ?? 0)
        )
      }
    })
  })

  const buildData = (handler: CompanyRemunerationDataHandler) =>
    Object.fromEntries([
      ...args.peers
        .filter((peer) => !!args.companiesData[peer.ticker])
        .map((peer) => [peer.ticker, args.companiesData[peer.ticker]]),
      [summedData.company.ticker, handler.getData()],
    ])

  return {
    summedConsolidatedCompaniesData: buildData(consolidatedSumHandler),
    summedEstimatedCompaniesData: buildData(estimatedSumHandler),
  }
}
