import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { usePrevious } from '../../utils/hooks'

interface Props {
  onVisible?: () => any
}

const IntersectionObserver: React.FC<Props> = (props) => {
  const { ref, inView } = useInView()

  const prevInView = usePrevious(inView)

  useEffect(() => {
    const onVisible = props.onVisible

    if (inView && !prevInView && onVisible) {
      onVisible()
    }
  }, [inView, prevInView, props.onVisible])

  return <div ref={ref} />
}

export default IntersectionObserver
