import React from 'react'

import LoginContainer from './containers/Login'

interface Props {
  className?: string
}

const LoginPage: React.FC<Props> = (props) => (
  <LoginContainer className={props.className} />
)

export default LoginPage
