import { loader } from 'graphql.macro'
import React, { useEffect, useMemo, useState } from 'react'

import type { Props as ColumnChartProps } from '../../../../components/ColumnChart'
import type { Props as ESGPieChartProps } from '../../../../components/ESGPieChart'
import { useImperativeQuery } from '../../../../utils/hooks'
import Overview from '../../components/Overview'
import { useSectoralReport } from '../../contexts/SectoralReport'

interface Props {
  sector: string
  className?: string
}

interface QueryResult {
  peerGroupFrameworks: Framework[]
}

interface Framework {
  company: {
    name: string
  }
  score: number
  sections: {
    title: string
    weight: number
    subsections: {
      title: string
      weight: number
    }[]
  }[]
}

const query = loader('./query.graphql')

const buildPieChartData = (framework: Framework) =>
  Object.fromEntries(
    ['Environmental', 'Social', 'Governance'].map((title) => {
      const section = framework.sections.find((s) => s.title === title)!
      return [
        title.toLocaleLowerCase(),
        {
          weight: section.weight,
          subsections: section.subsections.map((subsection) => ({
            name: subsection.title,
            weight: subsection.weight,
          })),
        },
      ]
    })
  ) as ESGPieChartProps['data']

export const getEsgPieChartProps = (data: QueryResult) => {
  const sampleFramework = data.peerGroupFrameworks[0]

  if (!sampleFramework) {
    return null
  }

  return {
    title: '',
    data: buildPieChartData(sampleFramework),
  }
}

export const getColumnChartProps = (data: QueryResult) => {
  const responses = data.peerGroupFrameworks.filter((fw) => fw.score > 0)

  return {
    type: 'vertical' as const,
    title: '',
    data: responses
      .map((fw) => ({
        name: fw.company.name,
        value: fw.score,
      }))
      .sort((a, b) => b.value - a.value),
    average:
      responses.length === 0
        ? 0
        : responses.reduce((acc, fw) => acc + fw.score, 0) / responses.length,
    dataLabels: true,
  }
}

const OverviewContainer: React.FC<Props> = (props) => {
  const [esgPieChartProps, setEsgPieChartProps] =
    useState<ESGPieChartProps | null>(null)
  const [columnChartProps, setColumnChartProps] =
    useState<ColumnChartProps | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const {
    loading: loadingSector,
    sector,
    companies,
    year,
  } = useSectoralReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    setLoading(true)

    if (loadingSector) {
      return
    }

    makeQuery({ peerGroup: sector, year }).then((data: QueryResult) => {
      setEsgPieChartProps(getEsgPieChartProps(data))
      setColumnChartProps(getColumnChartProps(data))
      setLoading(false)
    })
  }, [loadingSector, makeQuery, sector, year])

  const companyNames = useMemo(
    () =>
      companies
        .filter((company) => !company.hasQuantDataOnly)
        .map((company) => company.name),
    [companies]
  )

  return (
    <Overview
      className={props.className}
      loading={loading}
      companies={companyNames}
      esgPieChartProps={esgPieChartProps}
      columnChartProps={columnChartProps}
    />
  )
}

export default OverviewContainer
