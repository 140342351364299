import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import { useImperativeQuery } from '../../../../utils/hooks'
import Data from '../../components/Data'
import type { RowData } from '../../components/DataTable'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface Props {
  className?: string
}

interface QueryResult {
  companyFramework: {
    sections: Section[]
  }
}

interface Section {
  title: string
  score: number
  peerAverageScore: {
    value: number
  }
  weight: number
  subsections: Subsection[]
}

interface Subsection {
  title: string
  score: number
  peerAverageScore: {
    value: number
  }
  weight: number
  qualiIndicators: QualiIndicator[]
}

interface QualiIndicator {
  title: string
  score: number
  peerAverageScore: {
    value: number
  }
  weight: number
}

const query = loader('./query.graphql')

const buildRowData = (sections: Section[]) => {
  return sections.map(buildSectionRowData).flat()
}

const buildSectionRowData = (section: Section) => {
  return [
    {
      categoryPath: [section.title],
      companyScore: section.score,
      peerAverage: section.peerAverageScore.value,
      weight: section.weight,
      isIndicator: false,
    },
    ...section.subsections.map((subsection) =>
      buildSubsectionRowData([section.title], subsection)
    ),
  ].flat()
}

const buildSubsectionRowData = (path: string[], subsection: Subsection) => {
  if (subsection.score === null) {
    return []
  }

  return [
    {
      categoryPath: path.concat(subsection.title),
      companyScore: subsection.score,
      peerAverage: subsection.peerAverageScore.value,
      weight: subsection.weight,
      isIndicator: false,
    },
    ...subsection.qualiIndicators.map((indicator) =>
      buildIndicatorRowData(path.concat(subsection.title), indicator)
    ),
  ].flat()
}

const buildIndicatorRowData = (path: string[], indicator: QualiIndicator) => {
  return {
    categoryPath: path.concat(indicator.title),
    companyScore: indicator.score,
    peerAverage: indicator.peerAverageScore.value,
    weight: indicator.weight,
    isIndicator: true,
  }
}

const DataContainer: React.FC<Props> = (props) => {
  const [rowData, setRowData] = useState<RowData[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    setLoading(true)

    if (loadingCompany) {
      return
    }

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        setRowData(buildRowData(data.companyFramework.sections))
        setLoading(false)
      }
    )
  }, [loadingCompany, makeQuery, companyData, year])

  return (
    <Data
      className={props.className}
      loading={loading}
      companyName={companyData?.name ?? ''}
      rowData={rowData}
    />
  )
}

export default DataContainer
