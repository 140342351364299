import { equals, omit } from 'ramda'
import { useReducer } from 'react'

import { GLOBAL_VARIABLES_SLUG } from '../../utils/constants'
import type {
  MetricWeighting,
  ReportChartData,
  UserPersonalizedReport,
} from './typings'

interface State {
  basket: SelectOption | null
  basketOptions: SelectOption[]
  indicator: SelectOption<string[]> | null
  loadingMetricChartData: boolean
  loadingSettingsData: boolean
  metricIndicatorsData: MetricIndicators
  metricWeightingsData: MetricWeighting[]
  reportIndicatorsData: ReportChartData | null
  reportMetricData: ReportChartData | null
  reportName: string
  reportWeightsData: ReportChartData | null
  section: SelectOption<string[]> | null
  subsection: SelectOption<string[]> | null
  userReports: UserPersonalizedReport[]
  weight: SelectOption | null
  weighting: SelectOption | null
}

interface ChangeSectionAction {
  type: 'CHANGE_SECTION'
  section: State['section']
}

interface ChangeSubsectionAction {
  type: 'CHANGE_SUBSECTION'
  subsection: State['subsection']
}

interface ChangeWeightingAction {
  type: 'CHANGE_WEIGHTING'
  weighting: State['weighting']
}

interface LoadReportSettingsAction {
  type: 'LOAD_REPORTS_SETTINGS'
  report: UserPersonalizedReport
}

interface ResetSettingsAction {
  type: 'RESET_SETTINGS'
}

interface SetStateAction extends Partial<State> {
  type: 'SET_STATE'
}

type Action =
  | ChangeSectionAction
  | ChangeSubsectionAction
  | ChangeWeightingAction
  | LoadReportSettingsAction
  | ResetSettingsAction
  | SetStateAction

const INITIAL_STATE: State = {
  basket: null,
  basketOptions: [],
  indicator: null,
  loadingMetricChartData: false,
  loadingSettingsData: false,
  metricIndicatorsData: { sections: [], subsections: [], indicators: [] },
  metricWeightingsData: [],
  reportIndicatorsData: null,
  reportMetricData: null,
  reportName: '',
  reportWeightsData: null,
  section: null,
  subsection: null,
  userReports: [],
  weight: null,
  weighting: null,
}

const changeSection: React.Reducer<State, ChangeSectionAction> = (
  state,
  action
) =>
  action.section?.value !== state.subsection?.value
    ? {
        ...state,
        section: action.section,
        subsection: null,
        indicator: null,
      }
    : state

const changeSubsection: React.Reducer<State, ChangeSubsectionAction> = (
  state,
  action
) =>
  action.subsection?.value !== state.subsection?.value
    ? {
        ...state,
        subsection: action.subsection,
        indicator: null,
      }
    : state

const changeWeighting: React.Reducer<State, ChangeWeightingAction> = (
  state,
  action
) => {
  if (action.weighting?.value === state.weighting?.value) {
    return state
  }

  const weightingData = state.metricWeightingsData.find(
    (weighting) => weighting.name === action.weighting?.value
  )

  if (
    !weightingData?.weights.some(
      (weight) => weight.value === state.weight?.value
    )
  ) {
    return {
      ...state,
      weighting: action.weighting,
      weight: null,
    }
  } else {
    return { ...state, weighting: action.weighting }
  }
}

const loadReportSettings: React.Reducer<State, LoadReportSettingsAction> = (
  state,
  action
) => {
  const nextState = resetSettings(state, { type: 'RESET_SETTINGS' })

  nextState.reportName = action.report.name

  const section = state.metricIndicatorsData.sections.find((section) =>
    equals(section.value, action.report.indicatorPath?.slice(0, 1))
  )
  const subsection = state.metricIndicatorsData.subsections.find((subsection) =>
    equals(subsection.value, action.report.indicatorPath?.slice(0, 2))
  )
  const indicator = state.metricIndicatorsData.indicators.find((indicator) =>
    equals(indicator.value, action.report.indicatorPath)
  )
  const weighting = state.metricWeightingsData.find(
    (weighting) => weighting.name === action.report.weighting?.name
  )
  const weight = weighting?.weights.find(
    (weight) => weight.value === action.report.weighting?.weight
  )

  if (section) {
    nextState.section = section
    if (subsection && section.value[0] !== GLOBAL_VARIABLES_SLUG) {
      nextState.subsection = subsection
      if (indicator) {
        nextState.indicator = indicator
      }
    } else if (indicator && section.value[0] === GLOBAL_VARIABLES_SLUG) {
      nextState.indicator = indicator
    }
  }

  if (action.report.basketName && action.report.basketId) {
    nextState.basket = {
      label: action.report.basketName,
      value: action.report.basketId,
    }
  }

  if (weighting) {
    nextState.weighting = { label: weighting.label, value: weighting.name }
    if (weight) {
      nextState.weight = weight
    }
  }

  return nextState
}

const resetSettings: React.Reducer<State, ResetSettingsAction> = (state) => ({
  ...state,
  basket: null,
  indicator: null,
  section: null,
  subsection: null,
  weight: null,
  weighting: null,
})

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'CHANGE_SECTION':
      return changeSection(state, action)
    case 'CHANGE_SUBSECTION':
      return changeSubsection(state, action)
    case 'CHANGE_WEIGHTING':
      return changeWeighting(state, action)
    case 'LOAD_REPORTS_SETTINGS':
      return loadReportSettings(state, action)
    case 'RESET_SETTINGS':
      return resetSettings(state, action)
    case 'SET_STATE':
      return { ...state, ...omit(['type'], action) }
  }
}

export const usePersonalizedReportReducer = () =>
  useReducer(reducer, INITIAL_STATE)
