import React from 'react'

import ListItem, { Props as ListItemProps } from './ListItem'

interface Props {
  title: string
  items: ListItemProps[]
  className?: string
}

const CompanySectorList: React.FC<Props> = (props) => {
  return (
    <div className={props.className}>
      <div className="border-b border-black px-4 pb-6 text-2xl font-bold">
        {props.title}
      </div>
      <div className="grid grid-cols-2 gap-x-10">
        {props.items.map((itemProps) => (
          <ListItem key={itemProps.url} {...itemProps} />
        ))}
      </div>
    </div>
  )
}

export default CompanySectorList
