import React from 'react'

import ESGPieChart, {
  Props as ESGPieChartProps,
} from '../../../../components/ESGPieChart'
import ScoreCard from '../../../../components/ScoreCard'
import ScoreHistoryChart, {
  Props as ScoreHistoryChartProps,
} from '../../../../components/ScoreHistoryChart'
import { useWatchLoading } from '../../../../contexts/Loading'
import { formatRanking } from '../../utils'
import SubsectionVsPeersChart, {
  Props as SubsectionVsPeersChartProps,
} from '../SubsectionVsPeersChart'

interface Props {
  loading: boolean
  company: Company
  score: number
  ranking: number[]
  peerAverage: number
  esgPieChartProps: ESGPieChartProps | null
  scoreHistoryChartProps: ScoreHistoryChartProps | null
  subsectionVsPeersChartProps: SubsectionVsPeersChartProps | null
}

const Overview: React.FC<Props> = (props) => {
  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div className="pb-20 pl-10 pr-4 font-work-sans">
      <div className="text-3xl font-semibold">Visão geral</div>
      {props.company.desc && (
        <div className="mt-4 mb-8 text-justify text-sm">
          {props.company.desc}
        </div>
      )}
      <div className="mt-8 flex">
        <ScoreCard
          color="primary"
          title="Score"
          value={props.score.toFixed(0)}
        />
        <ScoreCard
          className="ml-6"
          color="secondary"
          title="Ranking"
          value={formatRanking(props.ranking[0], props.ranking[1])}
        />
        <ScoreCard
          className="ml-6"
          color="secondary"
          title="Média Peers"
          value={props.peerAverage.toFixed(0)}
        />
      </div>
      <div className="mt-20">
        <div className="grid grid-cols-2 gap-x-4 gap-y-16">
          <ESGPieChart
            className="col-span-2 w-5/6"
            {...props.esgPieChartProps!}
          />
          <ScoreHistoryChart {...props.scoreHistoryChartProps!} />
          <SubsectionVsPeersChart {...props.subsectionVsPeersChartProps!} />
        </div>
      </div>
    </div>
  )
}

export default Overview
