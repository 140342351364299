import classNames from 'classnames'
import React from 'react'
import { Redirect } from 'react-router-dom'

import NotFound from '../../../../components/NotFound'
import ReportHeader from '../../../../components/ReportHeader'
import ReportSidebar from '../../../../components/ReportSidebar'
import type { Section } from '../../../../components/ReportSidebar/SidebarSection'
import { Path } from '../../../../utils/constants'
import Remuneration from '../../components/Remuneration'
import { useThemesPath } from '../../utils/hooks'
import Controversies from '../Controversies'

interface Props {
  className?: string
}

const SIDEBAR_SECTIONS: Section[] = [
  {
    path: '/remuneration',
    label: 'Remuneração',
    subsections: [
      {
        path: '/remuneration',
        label: 'Padrão',
      },
      {
        path: '/remuneration/sum',
        label: 'Soma',
      },
    ],
  },
  {
    path: '/controversies',
    label: 'Controvérsias',
    permissions: ['v2:esg_reports:themes:controversies'],
    autoRedirect: true,
    subsections: [
      {
        path: '/controversies/search',
        label: 'Busca',
        permissions: ['v2:esg_reports:themes:controversies:search'],
      },
      {
        path: '/controversies/library',
        label: 'Biblioteca',
        permissions: ['v2:esg_reports:themes:controversies:library'],
      },
    ],
  },
]

const ThemesReport: React.FC<Props> = (props) => {
  const { theme } = useThemesPath()

  if (!theme) {
    return <Redirect to={[Path.REPORTS_THEMES, 'remuneration'].join('/')} />
  }

  const renderBody = () => {
    switch (theme) {
      case 'remuneration':
        return <Remuneration />
      case 'controversies':
        return <Controversies />
      default:
        return <NotFound />
    }
  }

  return (
    <>
      <ReportHeader className="flex-none" type="themes" />
      <div className="flex flex-grow justify-center">
        <div
          className={classNames(
            'flex w-full max-w-screen-xl items-stretch',
            props.className
          )}
        >
          <ReportSidebar
            className="mt-4 flex-shrink-0"
            basePath={Path.REPORTS_THEMES}
            sections={SIDEBAR_SECTIONS}
          />
          <div className="mt-8 flex flex-grow flex-col">{renderBody()}</div>
        </div>
      </div>
    </>
  )
}

export default ThemesReport
