import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import type { Props as ESGPieChartProps } from '../../../../components/ESGPieChart'
import type { Props as ScoreHistoryChartProps } from '../../../../components/ScoreHistoryChart'
import { useImperativeQuery } from '../../../../utils/hooks'
import Overview from '../../components/Overview'
import type { Props as SubsectionVsPeersChartProps } from '../../components/SubsectionVsPeersChart'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface QueryResult {
  companyFramework: {
    score: number
    ranking: number[]
    peerAverageScore: {
      value: number
    }
    sections: {
      title: string
      score: number
      weight: number
      subsections: {
        title: string
        weight: number | null
      }[]
    }[]
    topBottomSubsections: {
      title: string
      score: number
      peerAverageScore: {
        value: number
      }
    }[]
  }
  companyFrameworks: {
    year: number
    score: number
  }[]
}

const query = loader('./query.graphql')

const buildEsgPieChartData = (
  sections: QueryResult['companyFramework']['sections']
) => {
  const sectionTitles = {
    environmental: 'Environmental',
    social: 'Social',
    governance: 'Governance',
  }

  const buildSection = (section: typeof sections[number]) => ({
    weight: section.weight,
    score: section.score,
    subsections: section.subsections
      .filter((subsection) => subsection.weight !== null)
      .map(buildSubsection),
  })

  const buildSubsection = (
    subsection: typeof sections[number]['subsections'][number]
  ) => ({
    name: subsection.title,
    weight: subsection.weight,
  })

  const data = Object.fromEntries(
    Object.entries(sectionTitles).map(([id, title]) => [
      id,
      buildSection(sections.find((section) => section.title === title)!),
    ])
  )

  return data
}

export const getEsgPieChartProps = (data: QueryResult) => ({
  title: '',
  data: buildEsgPieChartData(
    data.companyFramework.sections
  ) as ESGPieChartProps['data'],
})

export const getScoreHistoryChartProps = (
  data: QueryResult,
  args: { companyName: string }
) => ({
  title: '',
  series: [
    {
      name: args.companyName,
      type: 'default' as const,
      data: data.companyFrameworks.map((fw) => ({
        year: fw.year,
        value: fw.score,
      })),
    },
  ],
})

export const getSubsectionVsPeersChartProps = (data: QueryResult) => ({
  subsections: data.companyFramework.topBottomSubsections.map((subsection) => ({
    name: subsection.title,
    score: subsection.score,
  })),
  peerAverage: 50,
})

const OverviewContainer: React.FC = () => {
  const [score, setScore] = useState<number | null>(null)
  const [ranking, setRanking] = useState<number[] | null>(null)
  const [peerAverage, setPeerAverage] = useState<number | null>(null)
  const [esgPieChartProps, setEsgPieChartProps] =
    useState<ESGPieChartProps | null>(null)
  const [scoreHistoryChartProps, setScoreHistoryChartProps] =
    useState<ScoreHistoryChartProps | null>(null)
  const [subsectionVsPeersChartProps, setSubsectionVsPeersChartProps] =
    useState<SubsectionVsPeersChartProps | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    setLoading(true)

    if (loadingCompany) {
      return
    }

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        setScore(data.companyFramework.score)
        setRanking(data.companyFramework.ranking)
        setPeerAverage(data.companyFramework.peerAverageScore.value)
        setEsgPieChartProps(getEsgPieChartProps(data))
        setScoreHistoryChartProps(
          getScoreHistoryChartProps(data, { companyName: companyData!.name })
        )
        setSubsectionVsPeersChartProps(getSubsectionVsPeersChartProps(data))
        setLoading(false)
      }
    )
  }, [loadingCompany, companyData, year, makeQuery])

  return (
    <Overview
      loading={loading}
      company={companyData!}
      score={score!}
      ranking={ranking!}
      peerAverage={peerAverage!}
      esgPieChartProps={esgPieChartProps}
      scoreHistoryChartProps={scoreHistoryChartProps}
      subsectionVsPeersChartProps={subsectionVsPeersChartProps}
    />
  )
}

export default OverviewContainer
