import { useMutation } from '@apollo/client'
import { genPPT } from '@jgp-er-dev/charts'
import { getTheme } from '@jgp-er-dev/themes'
import { loader } from 'graphql.macro'
import React, { useCallback, useEffect, useState } from 'react'

import { getChartConfig as getColumnChartConfig } from '../../../../components/ColumnChart'
import { getChartConfig as getESGPieChartConfig } from '../../../../components/ESGPieChart'
import ControversyIcon from '../../../../components/icons/Controversy'
import DatabaseIcon from '../../../../components/icons/Database'
import EnvironmentalIcon from '../../../../components/icons/Environmental'
import GovernanceIcon from '../../../../components/icons/Governance'
import OverviewIcon from '../../../../components/icons/Overview'
import SocialIcon from '../../../../components/icons/Social'
import NotFound from '../../../../components/NotFound'
import type { Section } from '../../../../components/ReportSidebar/SidebarSection'
import { getChartConfig as getScoreHistoryChartConfig } from '../../../../components/ScoreHistoryChart'
import { getChartConfig as getSubsectionPieChartConfig } from '../../../../components/SubsectionPieChart'
import { useChartRenderer } from '../../../../contexts/ChartRenderer'
import { ENVIRONMENTAL, GOVERNANCE, SOCIAL } from '../../../../utils/constants'
import { useImperativeQuery } from '../../../../utils/hooks'
import { slugify } from '../../../../utils/index'
import CompanyReport from '../../components/CompanyReport'
import { getChartConfig as getSubsectionScoresChartConfig } from '../../components/SubsectionScoresChart'
import { getChartConfig as getSubsectionVsPeersChartConfig } from '../../components/SubsectionVsPeersChart'
import { useCompanyReport } from '../../contexts/CompanyReport'
import * as ESGSectionOverview from '../ESGSectionOverview'
import { getIndicatorColumnChartsPropsList } from '../ESGSubsectionQuant'
import * as Overview from '../Overview'

interface Props {
  className?: string
}

interface QueryResult {
  companyFramework: {
    sections: {
      title: string
      subsections: {
        title: string
      }[]
    }[]
  }
  user: {
    favorites: { companies: string[] }
    notifications: { companies: string[] }
  }
}

interface FullReportQueryResult {
  companyFramework: {
    score: number
    ranking: number[]
    peerAverageScore: {
      value: number
    }
    sections: {
      title: string
      score: number
      weight: number
      subsections: {
        title: string
        score: number | null
        weight: number | null
        quantIndicators: {
          title: string
          values: {
            year: number
            value: number
          }[]
          peerValues: {
            company: {
              name: string
            }
            value: number
          }[]
          unit: string
        }[]
      }[]
    }[]
    topBottomSubsections: {
      title: string
      score: number
      peerAverageScore: {
        value: number
      }
    }[]
  }
  companyFrameworks: {
    year: number
    score: number
    sections: {
      title: string
      score: number
    }[]
  }[]
}

const query = loader('./query.graphql')
const fullReportQuery = loader('./fullReportQuery.graphql')
const mutationSetFavorite = loader('./mutationSetFavorite.graphql')
const mutationSetNotification = loader('./mutationSetNotification.graphql')
const mutationSetRecent = loader('./mutationSetRecent.graphql')

const CompanyReportContainer: React.FC<Props> = (props) => {
  const [sections, setSections] = useState<Section[]>([])
  const [isFavorite, setIsFavorite] = useState<boolean>(false)
  const [isNotified, setIsNotified] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)
  const makeFullReportQuery = useImperativeQuery(fullReportQuery)
  const [changeFavorite] = useMutation(mutationSetFavorite)
  const [changeNotification] = useMutation(mutationSetNotification)
  const [updateRecent] = useMutation(mutationSetRecent)
  const { getChartImages } = useChartRenderer()

  useEffect(() => {
    if (loadingCompany || !companyData) {
      return
    }

    updateRecent({ variables: { companySlug: slugify(companyData!.name) } })

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        const environmentalSection = data.companyFramework.sections.find(
          (section) => section.title === ENVIRONMENTAL
        )
        const socialSection = data.companyFramework.sections.find(
          (section) => section.title === SOCIAL
        )
        const governanceSection = data.companyFramework.sections.find(
          (section) => section.title === GOVERNANCE
        )

        setSections([
          {
            Icon: OverviewIcon,
            path: '/overview',
            label: 'Visão Geral',
          },
          {
            Icon: EnvironmentalIcon,
            path: '/environmental',
            label: 'Ambiental',
            subsections: [
              {
                label: 'Visão geral',
                path: '/environmental/overview',
              },
              ...environmentalSection!.subsections.map((subsection) => ({
                label: subsection.title,
                path: `/environmental/${slugify(subsection.title)}`,
              })),
            ],
          },
          {
            Icon: SocialIcon,
            path: '/social',
            label: 'Social',
            subsections: [
              {
                label: 'Visão geral',
                path: '/social/overview',
              },
              ...socialSection!.subsections.map((subsection) => ({
                label: subsection.title,
                path: `/social/${slugify(subsection.title)}`,
              })),
            ],
          },
          {
            Icon: GovernanceIcon,
            path: '/governance',
            label: 'Governança',
            subsections: [
              {
                label: 'Visão geral',
                path: '/governance/overview',
              },
              ...governanceSection!.subsections.map((subsection) => ({
                label: subsection.title,
                path: `/governance/${slugify(subsection.title)}`,
              })),
            ],
          },
          {
            Icon: ControversyIcon,
            path: '/controversies',
            label: 'Controvérsias',
          },
          {
            Icon: DatabaseIcon,
            path: '/data',
            label: 'Dados',
          },
          {
            path: '/decarb',
            label: 'Descarbonização',
          },
          {
            path: '/goals',
            label: 'Metas',
          },
        ])
        setIsFavorite(
          data.user.favorites.companies.includes(slugify(companyData!.name))
        )
        setIsNotified(
          data.user.notifications.companies.includes(slugify(companyData!.name))
        )
        setLoading(false)
      }
    )
  }, [loadingCompany, companyData, year, makeQuery, updateRecent])

  const handleFavoriteChange = useCallback(
    async (value: boolean) => {
      if (loadingCompany) {
        return
      }

      return changeFavorite({
        variables: {
          companySlug: slugify(companyData!.name),
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setIsFavorite(
          res.data.setFavorite.favorites.companies.includes(
            slugify(companyData!.name)
          )
        )
      })
    },
    [loadingCompany, changeFavorite, companyData]
  )

  const handleNotificationChange = useCallback(
    async (value: boolean) => {
      if (loadingCompany) {
        return
      }

      return changeNotification({
        variables: {
          companySlug: slugify(companyData!.name),
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setIsNotified(
          res.data.setNotification.notifications.companies.includes(
            slugify(companyData!.name)
          )
        )
      })
    },
    [loadingCompany, changeNotification, companyData]
  )

  const handleDownload = useCallback(async () => {
    if (loadingCompany) {
      return
    }

    const data: FullReportQueryResult = await makeFullReportQuery({
      companyId: companyData!.id,
      year,
    })

    const overviewChartConfigs = [
      getESGPieChartConfig(Overview.getEsgPieChartProps(data)),
      getScoreHistoryChartConfig(
        Overview.getScoreHistoryChartProps(data, {
          companyName: companyData!.name,
        })
      ),
      getSubsectionVsPeersChartConfig(
        Overview.getSubsectionVsPeersChartProps(data)
      ),
    ]

    const esgSectionChartConfigs = [
      'environmental' as const,
      'social' as const,
      'governance' as const,
    ]
      .map((section: ESGSection) => {
        const sectionData = data.companyFramework.sections.find(
          (s) => slugify(s.title) === section
        )

        const sectionIndicatorChartConfigs = sectionData!.subsections
          .map((subsection) =>
            getIndicatorColumnChartsPropsList(data, {
              section,
              subsection: subsection.title,
              companyName: companyData!.name,
            }).flat()
          )
          .flat()

        return [
          getSubsectionPieChartConfig(
            ESGSectionOverview.getSubsectionPieChartProps(data, { section })
          ),
          getScoreHistoryChartConfig(
            ESGSectionOverview.getScoreHistoryChartProps(data, {
              section,
              companyName: companyData!.name,
            })
          ),
          getSubsectionScoresChartConfig(
            ESGSectionOverview.getSubsectionScoresChartProps(data, { section })
          ),
          ...sectionIndicatorChartConfigs.map(getColumnChartConfig),
        ]
      })
      .flat()

    const chartConfigs = [...overviewChartConfigs, ...esgSectionChartConfigs]
    const theme = await getTheme('jgp')
    await getChartImages(chartConfigs).then((svgs) =>
      genPPT({
        svgs,
        imagesPerPage: 1,
        theme,
        title: `Análise Empresarial - ${companyData!.name}`,
      })
    )
  }, [loadingCompany, makeFullReportQuery, getChartImages, companyData, year])

  if (!loadingCompany && (!companyData || !year)) {
    return <NotFound />
  }

  return (
    <CompanyReport
      className={props.className}
      loading={loading}
      company={companyData}
      sections={sections}
      isFavorite={isFavorite}
      isNotified={isNotified}
      onFavoriteChange={handleFavoriteChange}
      onNotificationChange={handleNotificationChange}
      onDownload={handleDownload}
    />
  )
}

export default CompanyReportContainer
