import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import type { Props as ColumnChartProps } from '../../../../components/ColumnChart'
import type { Props as ScoreHistoryChartProps } from '../../../../components/ScoreHistoryChart'
import { computeAverage, slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import ESGSubsection from '../../components/ESGSubsection'
import { useActiveCompanies } from '../../contexts/ActiveCompanies'
import { useSectoralReport } from '../../contexts/SectoralReport'
import { computeAverageEvolution } from '../../utils'

interface Props {
  section: ESGSection
  subsection: string
  className?: string
}

interface QueryResult {
  multiCompanyFramework: {
    sections: {
      title: string
      defaultWeight: number
      subsections: {
        title: string
        defaultWeight: number | null
        scores:
          | {
              company: { name: string }
              value: number
            }[]
          | null
        scoreHistories: {
          company: { name: string }
          values: {
            year: number
            value: number
          }[]
        }[]
        quantIndicators: QuantIndicator[]
      }[]
    }[]
  }
}

interface QuantIndicator {
  title: string
  unit: string
  values: {
    company: { name: string }
    values: {
      year: number
      value: number
    }[]
  }[]
}

const query = loader('./query.graphql')

const buildQuantData = (indicator: QuantIndicator, year: number) => {
  const currentValues = indicator.values
    .filter((value) => value.values.some((point) => point.year === year))
    .map((value) => ({
      company: value.company.name,
      value: value.values.find((point) => point.year === year)!.value,
    }))

  const currentAverage = computeAverage(
    currentValues.map((value) => value.value)
  )

  const evolutions = indicator.values.map((value) => ({
    company: value.company.name,
    values: value.values,
  }))

  return {
    title: indicator.title,
    unit: indicator.unit,
    values: currentValues,
    averageValue: currentAverage,
    evolutions,
  }
}

export const getScoresColumnChartProps = (
  data: QueryResult,
  args: { section: ESGSection; subsection: string; companies: string[] }
) => {
  const section = data.multiCompanyFramework.sections.find(
    (s) => slugify(s.title) === args.section
  )
  const subsection = section?.subsections.find(
    (sub) => sub.title === args.subsection
  )

  if (!subsection?.scores) {
    return null
  }

  const scores = subsection.scores
    .filter((score) => args.companies.includes(score.company.name))
    .map((score) => ({
      name: score.company.name,
      value: score.value,
    }))
    .sort((a, b) => b.value - a.value)

  const average =
    scores.reduce((acc, { value }) => acc + value, 0) / scores.length

  return {
    type: 'vertical' as const,
    title: `Notas ${args.subsection}`,
    data: scores,
    average,
    dataLabels: true,
  }
}

export const getScoreHistoryChartProps = (
  data: QueryResult,
  args: { section: ESGSection; subsection: string; companies: string[] }
) => {
  const section = data.multiCompanyFramework.sections.find(
    (s) => slugify(s.title) === args.section
  )
  const subsection = section?.subsections.find(
    (sub) => sub.title === args.subsection
  )

  if (!subsection?.scoreHistories) {
    return null
  }

  const scoreHistories = subsection.scoreHistories
    .filter((history) => args.companies.includes(history.company.name))
    .map((history) => ({
      company: history.company.name,
      values: history.values,
    }))

  const averageEvolution = {
    name: 'Média peers',
    data: computeAverageEvolution(scoreHistories),
    type: 'peerAverage' as const,
  }

  return {
    series: [
      ...scoreHistories
        .filter((evolution) => evolution.values.length > 0)
        .map((evolution) => ({
          name: evolution.company,
          data: evolution.values,
          type: 'default' as const,
        })),
      averageEvolution,
    ],
    enableLegend: true,
  }
}

export const getIndicatorChartPropsList = (
  data: QueryResult,
  args: {
    section: ESGSection
    subsection: string
    year: number
    companies: string[]
  }
) => {
  const section = data.multiCompanyFramework.sections.find(
    (s) => slugify(s.title) === args.section
  )
  const subsection = section?.subsections.find(
    (sub) => sub.title === args.subsection
  )

  if (!subsection) {
    return []
  }

  const quantData = subsection.quantIndicators.map((indicator) =>
    buildQuantData(indicator, args.year)
  )
  return quantData.map((indicator) => {
    const valuesData = indicator.values
      .filter(({ company }) => args.companies.includes(company))
      .map((point) => ({
        name: point.company,
        value: point.value,
      }))
      .sort((a, b) => b.value - a.value)

    const companyEvolutions = indicator.evolutions.filter(({ company }) =>
      args.companies.includes(company)
    )

    const averageEvolution = {
      name: 'Média peers',
      data: computeAverageEvolution(companyEvolutions),
      type: 'peerAverage' as const,
    }

    const evolutionData =
      averageEvolution.data.length === 0
        ? []
        : [
            ...companyEvolutions
              .filter((evolution) => evolution.values.length > 0)
              .map((evolution) => ({
                name: evolution.company,
                data: evolution.values,
                type: 'default' as const,
              })),
            averageEvolution,
          ]

    return [
      {
        type: 'vertical' as const,
        title: indicator.title,
        unit: indicator.unit,
        data: valuesData,
        average: indicator.averageValue,
        dataLabels: true,
      },
      {
        title: `Evolução ${indicator.title}`,
        unit: indicator.unit,
        series: evolutionData,
        enableLegend: true,
      },
    ] as [ColumnChartProps, ScoreHistoryChartProps]
  })
}

const ESGSubsectionContainer: React.FC<Props> = (props) => {
  const [subsectionWeight, setSubsectionWeight] = useState<number | null>(null)
  const [scoresColumnChartProps, setScoresColumnChartProps] =
    useState<ColumnChartProps | null>(null)
  const [scoreHistoryChartProps, setScoreHistoryChartProps] =
    useState<ScoreHistoryChartProps | null>(null)
  const [indicatorChartPropsList, setIndicatorChartPropsList] = useState<
    [ColumnChartProps, ScoreHistoryChartProps][]
  >([])
  const [loading, setLoading] = useState<boolean>(true)

  const { activeCompanies } = useActiveCompanies()
  const { loading: loadingSector, companies, year } = useSectoralReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingSector) {
      return
    }

    makeQuery({
      companyIds: companies.map((company) => company.id),
      year,
    }).then((data: QueryResult) => {
      const section = data.multiCompanyFramework.sections.find(
        (s) => slugify(s.title) === props.section
      )
      const subsection = section?.subsections.find(
        (sub) => sub.title === props.subsection
      )

      if (!section) {
        throw new Error(`Section ${props.section} does not exist`)
      } else if (!subsection) {
        throw new Error(
          `Section ${props.subsection} does not exist in section ${props.section}`
        )
      }

      setSubsectionWeight(subsection.defaultWeight)
      setScoresColumnChartProps(
        getScoresColumnChartProps(data, {
          section: props.section,
          subsection: props.subsection,
          companies: activeCompanies,
        })
      )
      setScoreHistoryChartProps(
        getScoreHistoryChartProps(data, {
          section: props.section,
          subsection: props.subsection,
          companies: activeCompanies,
        })
      )
      setIndicatorChartPropsList(
        getIndicatorChartPropsList(data, {
          section: props.section,
          subsection: props.subsection,
          year: year!,
          companies: activeCompanies,
        })
      )
      setLoading(false)
    })
  }, [
    loadingSector,
    makeQuery,
    companies,
    year,
    props.section,
    props.subsection,
    activeCompanies,
  ])

  return (
    <ESGSubsection
      className={props.className}
      loading={loading}
      subsection={props.subsection}
      subsectionWeight={subsectionWeight}
      scoresColumnChartProps={scoresColumnChartProps}
      scoreHistoryChartProps={scoreHistoryChartProps}
      indicatorChartPropsList={indicatorChartPropsList}
    />
  )
}

export default ESGSubsectionContainer
