import classNames from 'classnames'
import React from 'react'

import ControversyList from '../../../../components/ControversyList'
import { useWatchLoading } from '../../../../contexts/Loading'
import ControversiesTable from './ControversiesTable'

interface Props {
  loading: boolean
  companies: {
    name: string
    controversies: Controversy[]
  }[]
  className?: string
}

const Controversies: React.FC<Props> = (props) => {
  useWatchLoading(props.loading)

  return props.loading ? null : props.companies.every(
      (company) => company.controversies.length === 0
    ) ? (
    <div className="pt-8 pb-20 pl-10 pr-4">
      Não há controvérsias registradas para as empresas deste setor.
    </div>
  ) : (
    <div
      className={classNames(
        'flex flex-col items-center pt-8 pb-20 pl-10 pr-4',
        props.className
      )}
    >
      <ControversiesTable
        className="w-full max-w-screen-md"
        companies={props.companies}
      />
      <div className="mt-4 flex w-full max-w-screen-xl items-center">
        <span className="flex-grow border-t-2 border-gray-400" />
        <span className="px-2">Detalhes</span>
        <span className="flex-grow border-t-2 border-gray-400" />
      </div>
      {props.companies.map(
        (company) =>
          company.controversies.length > 0 && (
            <ControversyList
              key={company.name}
              className="mt-4 max-w-screen-md"
              title={company.name}
              list={company.controversies}
            />
          )
      )}
    </div>
  )
}

export default Controversies
