import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import React, { useCallback, useEffect, useState } from 'react'

import { useImperativeQuery } from '../../../../utils/hooks'
import RemunerationDefault from '../../components/RemunerationDefault'
import {
  CUSTOM_ESTIMATE_YEARS,
  getInitialCustomEstimates,
  useCompaniesData,
  useCompaniesList,
} from '../../utils/remuneration'

interface Props {
  className?: string
}

interface SavedReportsQueryResult {
  userSavedRemunerationReports: Pick<
    UserSavedRemunerationReport,
    'id' | 'name'
  >[]
}

interface SavedReportQueryResult {
  savedRemunerationReport: UserSavedRemunerationReport | null
}

const savedReportsQuery = loader('./savedReportsQuery.graphql')
const savedReportQuery = loader('./savedReportQuery.graphql')
const saveReportMutation = loader('./saveReportMutation.graphql')
const renameReportMutation = loader('./renameReportMutation.graphql')
const deleteReportMutation = loader('./deleteReportMutation.graphql')

const RemunerationContainer: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [allCompanies, setAllCompanies] = useState<RemunerationCompany[]>([])
  const [activeCompanies, setActiveCompanies] = useState<RemunerationCompany[]>(
    []
  )
  const [companiesData, setCompaniesData] = useState<RemunerationData>({})
  const [originalCompaniesData, setOriginalCompaniesData] =
    useState<RemunerationData>({})
  const [customEstimates, setCustomEstimates] = useState<
    Record<string, RemunerationEstimates>
  >({})
  const [userSavedReports, setUserSavedReports] = useState<
    Pick<UserSavedRemunerationReport, 'id' | 'name'>[]
  >([])
  const [lastReportName, setLastReportName] = useState<string>('')

  const makeSavedReportsQuery = useImperativeQuery(savedReportsQuery, {
    fetchPolicy: 'network-only',
  })
  const makeSavedReportQuery = useImperativeQuery(savedReportQuery, {
    fetchPolicy: 'network-only',
  })

  const [saveReport] = useMutation(saveReportMutation)
  const [renameReport] = useMutation(renameReportMutation)
  const [deleteReport] = useMutation(deleteReportMutation)

  const getCompaniesData = useCompaniesData()
  const getCompaniesList = useCompaniesList()

  const updateUserReports = useCallback(
    async () =>
      makeSavedReportsQuery()
        .then((data: SavedReportsQueryResult) => {
          setUserSavedReports(data.userSavedRemunerationReports)
        })
        .catch((err) =>
          console.error('Failed to query saved user reports:', err)
        ),
    [makeSavedReportsQuery]
  )

  useEffect(() => {
    setLoading(true)

    Promise.all([getCompaniesList(), updateUserReports()])
      .then(([companiesList]) => {
        setAllCompanies(companiesList)
      })
      .catch((err) => {
        console.error('Failed to query companies data:', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getCompaniesList, updateUserReports])

  const handleActiveCompaniesChange = useCallback(
    async (companies: RemunerationCompany[]) => {
      setActiveCompanies(companies)

      if (companies.length === 0) {
        setCompaniesData({})
        setOriginalCompaniesData({})
        return
      }

      setLoading(true)
      getCompaniesData(companies.map((company) => company.ticker))
        .then((data) => {
          setCompaniesData(data)
          setOriginalCompaniesData(data)
          setCustomEstimates(
            Object.fromEntries(
              CUSTOM_ESTIMATE_YEARS.map((year) => [
                year,
                getInitialCustomEstimates(data, year),
              ])
            )
          )
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [getCompaniesData]
  )

  const handleSaveReport = useCallback(
    async (name: string) => {
      const savedReport = userSavedReports.find(
        (report) => report.name === name
      )

      const res = await saveReport({
        variables: {
          input: {
            id: savedReport?.id ?? null,
            name,
            companies: activeCompanies.map((company) => company.ticker),
            companiesData,
            estimates: customEstimates,
          },
        },
      })

      const report = res.data.saveUserRemunerationReport?.savedReport

      if (!report) {
        throw new Error(
          res.data.saveUserRemunerationReport?.error ??
            'Report saving failed with unknown error'
        )
      }

      setLastReportName(name)

      return updateUserReports()
    },
    [
      userSavedReports,
      saveReport,
      activeCompanies,
      companiesData,
      customEstimates,
      updateUserReports,
    ]
  )

  const handleRenameReport = useCallback(
    async (id: string, name: string) => {
      const res = await renameReport({
        variables: {
          input: { id, name },
        },
      })

      const report = res.data.renameUserRemunerationReport?.report

      if (!report) {
        throw new Error(
          res.data.renameUserRemunerationReport?.error ??
            'Report renaming failed with unknown error'
        )
      }

      return updateUserReports()
    },
    [renameReport, updateUserReports]
  )

  const handleLoadReport = useCallback(
    async (id: string) =>
      makeSavedReportQuery({ id }).then(
        async (data: SavedReportQueryResult) => {
          if (!data.savedRemunerationReport) {
            throw new Error('Report not found')
          }

          setActiveCompanies(data.savedRemunerationReport.companies)
          setCompaniesData(data.savedRemunerationReport.companiesData)
          setCustomEstimates(data.savedRemunerationReport!.estimates)
          setLastReportName(data.savedRemunerationReport.name)

          return getCompaniesData(
            data.savedRemunerationReport.companies.map(
              (company) => company.ticker
            )
          ).then((data) => setOriginalCompaniesData(data))
        }
      ),
    [makeSavedReportQuery, getCompaniesData]
  )

  const handleDeleteReport = useCallback(
    async (id: string) =>
      deleteReport({
        variables: {
          input: { id },
        },
      }).then((res) => {
        const error = res.data.deleteUserRemunerationReport.error

        if (error) {
          throw new Error('Failed to delete report: ' + error)
        }

        return updateUserReports()
      }),
    [deleteReport, updateUserReports]
  )

  return (
    <RemunerationDefault
      className={props.className}
      loading={loading}
      allCompanies={allCompanies}
      activeCompanies={activeCompanies}
      companiesData={companiesData}
      originalCompaniesData={originalCompaniesData}
      customEstimates={customEstimates}
      savedReports={userSavedReports}
      lastReportName={lastReportName}
      onSaveReport={handleSaveReport}
      onLoadReport={handleLoadReport}
      onRenameReport={handleRenameReport}
      onDeleteReport={handleDeleteReport}
      onCustomEstimatesChange={setCustomEstimates}
      onActiveCompaniesChange={handleActiveCompaniesChange}
      onCompaniesDataChange={setCompaniesData}
    />
  )
}

export default RemunerationContainer
