import classNames from 'classnames'
import React from 'react'
import Tooltip from 'react-tooltip'

import ButtonWithLoading from '../ButtonWithLoading'
import { BlankStarIcon, StarIcon } from '../icons'

interface Props {
  className?: string
  onClick: () => Promise<void>
  isFavorite?: boolean
}

const TOOLTIP_ID = 'favorite'

const FavoriteButton: React.FC<Props> = (props) => (
  <span className={classNames('flex items-center', props.className)}>
    <ButtonWithLoading
      className="focus:outline-none"
      innerClass="flex items-center text-white"
      onClick={props.onClick}
      spinnerProps={{ className: 'text-white', size: 24 }}
      data-tip
      data-for={TOOLTIP_ID}
    >
      {props.isFavorite ? (
        <StarIcon className="mr-1.5 h-6 w-6" />
      ) : (
        <BlankStarIcon className="mr-1.5 h-6 w-6" />
      )}
      Favoritar
    </ButtonWithLoading>
    <Tooltip id={TOOLTIP_ID} effect="solid" place="bottom">
      {props.isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'}
    </Tooltip>
  </span>
)

export default FavoriteButton
