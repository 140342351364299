import { RegularChart } from '@jgp-er-dev/charts'
import type { Options, SeriesLineOptions } from 'highcharts'
import { range } from 'ramda'
import { useMemo } from 'react'

import { colorPalette } from '../../../../utils/constants'
import type { getStatisticsData } from '../../clients'

interface Props {
  config: ChartConfig
  statisticsData: Awaited<ReturnType<typeof getStatisticsData>>
}

export interface ChartConfig {
  type: 'metric' | 'company'
  subject: string
}

const buildMetricSeries = (
  statisticsData: Props['statisticsData'],
  field: string
) =>
  statisticsData.data.map((row): SeriesLineOptions => {
    const values = Object.fromEntries(
      row.values[field]?.map(({ year, value }) => [year, value]) ?? []
    )

    return {
      tooltip: { valueDecimals: 2 },
      name: row.companyName,
      type: 'line',
      data: range(
        statisticsData.yearRange.min,
        statisticsData.yearRange.max + 1
      ).map((year) => values[year] ?? null),
    }
  })

const buildCompanySeries = (
  statisticsData: Props['statisticsData'],
  companyName: string
) => {
  const row = statisticsData.data.find((row) => row.companyName === companyName)

  if (!row) {
    return []
  }

  return statisticsData.columns.map((col): SeriesLineOptions => {
    const values = Object.fromEntries(
      row.values[col.field]?.map(({ year, value }) => [year, value]) ?? []
    )

    return {
      tooltip: { valueDecimals: 2 },
      name: col.name,
      type: 'line',
      data: range(
        statisticsData.yearRange.min,
        statisticsData.yearRange.max + 1
      ).map((year) => values[year] ?? null),
    }
  })
}

const Chart: React.FC<Props> = (props) => {
  const series = useMemo(
    () =>
      props.config.type === 'metric'
        ? buildMetricSeries(props.statisticsData, props.config.subject)
        : buildCompanySeries(props.statisticsData, props.config.subject),
    [props.config, props.statisticsData]
  )

  const title = useMemo(
    () =>
      props.config.type === 'metric'
        ? props.statisticsData.columns.find(
            (col) => col.field === props.config.subject
          )?.name ?? ''
        : props.config.subject,
    [props.config, props.statisticsData]
  )

  const options = useMemo(
    (): Options => ({
      colors: colorPalette,
      title: { text: title },
      series,
      xAxis: {
        type: 'category',
        categories: range(
          props.statisticsData.yearRange.min,
          props.statisticsData.yearRange.max + 1
        ).map((year) => year.toString()),
      },
      yAxis: { title: { text: null } },
      credits: { enabled: false },
      navigation: { buttonOptions: { enabled: false } },
    }),
    [props.statisticsData.yearRange, series, title]
  )

  return <RegularChart chartProps={{ options }} />
}

export default Chart
