import classNames from 'classnames'
import { Fragment, useEffect, useState } from 'react'

import IndicatorDetails from './IndicatorDetails'

interface Props {
  className?: string
  companyName: string
  isSingleTopic?: boolean
  topics: {
    name: string
    indicators: QualiIndicator[]
  }[]
}

export interface QualiIndicator {
  title: string
  status: 'green' | 'yellow' | 'red'
  reason: string
}

const QualiIndicatorTable: React.FC<Props> = (props) => {
  const [activeTopic, setActiveTopic] = useState<string | null>(null)
  const [activeIndicator, setActiveIndicator] =
    useState<QualiIndicator | null>(null)

  const handleIndicatorClick = (topic: string, indicator: QualiIndicator) => {
    setActiveTopic(topic)
    setActiveIndicator(indicator)
  }

  useEffect(() => {
    const handleKeypress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setActiveIndicator(null)
      }
    }

    document.addEventListener('keydown', handleKeypress)

    return () => document.removeEventListener('keydown', handleKeypress)
  }, [])

  return (
    <div
      className={classNames(
        'grid cursor-default rounded-sm border-2 border-gray-300',
        {
          'grid-cols-max-auto-max': !props.isSingleTopic,
          'grid-cols-auto-max': props.isSingleTopic,
        },
        props.className
      )}
    >
      {!props.isSingleTopic && (
        <span className="h-full max-w-[10rem] border-r border-gray-300 p-2" />
      )}
      <span className="flex h-full w-full items-center justify-center border-r border-gray-300 p-2 text-center font-bold">
        Critérios
      </span>
      <span className="flex h-full max-w-[10rem] items-center justify-center p-2 text-center font-bold">
        Respostas
      </span>
      {props.topics.map((topic) => (
        <Fragment key={topic.name}>
          {!props.isSingleTopic && (
            <span
              className="flex h-full max-w-[10rem] items-center justify-center border-t border-r border-gray-300 p-2 text-center font-bold"
              style={{
                gridRow: `span ${topic.indicators.length}`,
              }}
            >
              {topic.name}
            </span>
          )}
          {topic.indicators.map((indicator) => (
            <div key={indicator.title} className="contents">
              <span
                className="flex h-full cursor-pointer items-center border-t border-r border-gray-300 p-2 hover:bg-gray-100"
                onClick={() => handleIndicatorClick(topic.name, indicator)}
              >
                {indicator.title}
              </span>
              <span
                className={classNames(
                  'flex h-full max-w-[10rem] cursor-pointer items-center justify-center border-t border-gray-300 p-2'
                )}
                onClick={() => handleIndicatorClick(topic.name, indicator)}
              >
                <div
                  className={classNames('h-6 w-6 rounded-full', {
                    'bg-jgp-success-main': indicator.status === 'green',
                    'bg-jgp-warning-main': indicator.status === 'yellow',
                    'bg-jgp-error-main': indicator.status === 'red',
                  })}
                />
              </span>
            </div>
          ))}
        </Fragment>
      ))}
      {activeIndicator && (
        <div
          className="fixed inset-0 z-10 flex items-center justify-center p-2"
          onClick={() => setActiveIndicator(null)}
        >
          <div className="flex max-w-screen-lg flex-grow justify-center pl-64">
            <IndicatorDetails
              className="max-w-2xl flex-grow"
              companyName={props.companyName}
              topic={activeTopic!}
              indicator={activeIndicator.title}
              reason={activeIndicator.reason}
              onClose={() => setActiveIndicator(null)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default QualiIndicatorTable
