import NotFound from '../../../../components/NotFound'
import RemunerationDefaultContainer from '../../containers/RemunerationDefault'
import RemunerationSumContainer from '../../containers/RemunerationSum'
import { useThemesPath } from '../../utils/hooks'

const Remuneration: React.FC = () => {
  const { path } = useThemesPath()

  const [, type] = path

  switch (type) {
    case undefined:
      return <RemunerationDefaultContainer />

    case 'sum':
      return <RemunerationSumContainer />

    default:
      return <NotFound />
  }
}

export default Remuneration
