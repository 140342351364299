import classNames from 'classnames'
import React from 'react'

import ColumnChart, {
  Props as ColumnChartProps,
} from '../../../../components/ColumnChart'
import SubsectionPieChart, {
  Props as SubsectionPieChartProps,
} from '../../../../components/SubsectionPieChart'
import { useWatchLoading } from '../../../../contexts/Loading'

interface Props {
  loading: boolean
  subsectionPieChartProps: SubsectionPieChartProps | null
  scoresColumnChartProps: ColumnChartProps | null
  subsectionScoresColumnChartPropsList: ColumnChartProps[]
  className?: string
}

export interface BarChartData {
  title: string
  data: { name: string; value: number }[]
  average: number
}

const ESGSectionOverview: React.FC<Props> = (props) => {
  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div
      className={classNames(
        'grid grid-cols-2 gap-x-4 gap-y-16',
        props.className
      )}
    >
      <SubsectionPieChart
        className="col-span-2 w-5/6"
        {...props.subsectionPieChartProps!}
      />
      <ColumnChart className="col-span-2" {...props.scoresColumnChartProps!} />
      {props.subsectionScoresColumnChartPropsList.map((chartProps) => (
        <ColumnChart key={chartProps.title} {...chartProps} />
      ))}
    </div>
  )
}

export default ESGSectionOverview
