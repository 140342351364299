import { RegularChart } from '@jgp-er-dev/charts'
import classNames from 'classnames'
import React, { useMemo } from 'react'

import { commonPieChartOptions } from '../../utils/constants'

export interface Props {
  data: {
    environmental: ESGPieChartSectionData
    social: ESGPieChartSectionData
    governance: ESGPieChartSectionData
  }
  className?: string
  title?: string
}

interface ESGPieChartSectionData {
  weight: number
  score?: number
  subsections: ESGPieChartSubsectionData[]
}

interface ESGPieChartSubsectionData {
  name: string
  weight: number
}

const formatData = (data: Props['data']) => {
  const weightSum =
    data.environmental.weight + data.social.weight + data.governance.weight

  return [
    {
      name: 'Environmental',
      y: (100 * data.environmental.weight) / weightSum,
      drilldown: 'environmental',
      color: '#30ba09',
    },
    {
      name: 'Social',
      y: (100 * data.social.weight) / weightSum,
      drilldown: 'social',
      color: '#16164d',
    },
    {
      name: 'Governance',
      y: (100 * data.governance.weight) / weightSum,
      drilldown: 'governance',
      color: '#7e7f74',
    },
  ]
}

const formatSubsectionData = (data: ESGPieChartSubsectionData[]) => {
  const weightSum = data.reduce((acc, subsection) => acc + subsection.weight, 0)

  return data.map((subsection) => ({
    name: subsection.name,
    y: (100 * subsection.weight) / weightSum,
  }))
}

export const getChartConfig = (props: Props) => {
  const series = [
    {
      name: 'Peso',
      data: formatData(props.data),
      dataLabels: {
        formatter: function () {
          const _this = this as unknown as {
            y: number
            color: string
            key: string
          }

          const sectionData =
            props.data[_this.key.toLocaleLowerCase() as keyof Props['data']]

          return `
              <div class="flex flex-col items-center transform -translate-y-1/2 no-underline">
                <span class="text-2xl" style="color: ${_this.color}">
                  ${_this.key.charAt(0)}
                </span>
                <div class="w-16 flex">
                  <span class="w-10">Peso:</span>
                  <span style="color: ${_this.color}">${_this.y.toFixed(
            0
          )}%</span>
                </div>
                ${
                  sectionData.score !== undefined
                    ? '<div class="w-16 flex"><span class="w-10">Score:</span><span style="color:' +
                      _this.color +
                      ';">' +
                      sectionData.score.toFixed(0) +
                      '</span></div>'
                    : ''
                }
              </div>
            `
        },
        useHTML: true,
        distance: 20,
        connectorWidth: 0,
      },
      tooltip: { valueSuffix: '%', valueDecimals: 2 },
    },
  ]

  const dataLabels = {
    format: '{point.y:.0f}%',
    distance: 5,
    connectorWidth: 0,
  }

  const tooltip = { valueSuffix: '%', valueDecimals: 2 }

  const drilldownSeries = [
    {
      name: 'Peso',
      id: 'environmental',
      data: formatSubsectionData(props.data.environmental.subsections),
      dataLabels,
      tooltip,
    },
    {
      name: 'Peso',
      id: 'social',
      data: formatSubsectionData(props.data.social.subsections),
      dataLabels,
      tooltip,
    },
    {
      name: 'Peso',
      id: 'governance',
      data: formatSubsectionData(props.data.governance.subsections),
      dataLabels,
      tooltip,
    },
  ]

  return {
    options: {
      ...commonPieChartOptions,
      title: { text: props.title ?? 'Pesos' },
      series: series as any,
      drilldown: {
        breadcrumbs: {
          showFullPath: false,
          format: '◁ Voltar',
        },
        activeDataLabelStyle: {
          color: undefined,
          textDecoration: 'none',
        },
        series: drilldownSeries as any,
        drillUpButton: {
          position: {
            x: 10,
            y: 0,
          },
        },
      },
    },
  }
}

const ESGPieChart: React.FC<Props> = (props) => {
  const chartProps = useMemo(() => getChartConfig(props), [props])

  return (
    <div
      className={classNames('grid h-chart grid-cols-1 p-2', props.className)}
    >
      <RegularChart chartProps={chartProps} />
    </div>
  )
}

export default ESGPieChart
