import React from 'react'

const OverviewIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.5233 9.33478H8.54418L13.1921 13.9827C13.3698 14.1604 13.6621 14.1748 13.8448 14.0027C14.983 12.9304 15.7659 11.4848 15.9957 9.85978C16.0351 9.58154 15.8042 9.33478 15.5233 9.33478ZM15.0577 7.4289C14.8154 3.91538 12.0077 1.10773 8.49418 0.865382C8.22595 0.846853 8.00006 1.07303 8.00006 1.34185V7.92302H14.5815C14.8504 7.92302 15.0762 7.69713 15.0577 7.4289ZM6.5883 9.33478V2.35567C6.5883 2.07479 6.34153 1.84391 6.06359 1.88332C2.55859 2.37861 -0.120524 5.44067 0.00418193 9.11037C0.132417 12.8792 3.37742 15.9698 7.14801 15.9224C8.63036 15.9039 10.0001 15.4262 11.1262 14.6274C11.3586 14.4627 11.3739 14.1206 11.1724 13.9192L6.5883 9.33478Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.393616)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default OverviewIcon
