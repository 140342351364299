import classNames from 'classnames'
import React from 'react'

import { useActive } from '../../contexts/Active'
import ReportChart from '../ReportChart'
import DebugTable from './DebugTable'

interface Props {
  title: string
  series: {
    name: string
    data: { x: number; y: number }[]
  }[]
  unit: string
  className?: string
}

const DebugPanel: React.FC<Props> = (props) => {
  const { activeSeries } = useActive()

  return (
    <div
      className={classNames(
        'grid grid-cols-1 gap-4 md:grid-cols-2',
        props.className
      )}
    >
      <DebugTable series={props.series} />
      <ReportChart
        title={props.title}
        unit={props.unit}
        series={props.series.filter((series) => activeSeries[series.name])}
        enableLegend
      />
    </div>
  )
}

export default DebugPanel
