import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import type { Props as ScoreHistoryChartProps } from '../../../../components/ScoreHistoryChart'
import type { Props as SubsectionPieChartProps } from '../../../../components/SubsectionPieChart'
import { slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import ESGSectionOverview from '../../components/ESGSectionOverview'
import type { Props as SubsectionScoresChartProps } from '../../components/SubsectionScoresChart'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface Props {
  section: ESGSection
  className?: string
}

interface QueryResult {
  companyFramework: {
    sections: {
      title: string
      subsections: {
        title: string
        score: number | null
        weight: number | null
      }[]
    }[]
  }
  companyFrameworks: {
    year: number
    sections: {
      title: string
      score: number
    }[]
  }[]
}

const query = loader('./query.graphql')

const getSubsections = (data: QueryResult, args: { section: ESGSection }) => {
  const sectionData = data.companyFramework.sections.find(
    (s) => slugify(s.title) === args.section
  )

  if (!sectionData) {
    throw new Error(`Section ${args.section} does not exist`)
  }

  return sectionData.subsections
    .filter(
      (
        subsection
      ): subsection is {
        title: string
        score: number
        weight: number
      } => subsection.score !== null
    )
    .map((subsection) => ({
      name: subsection.title,
      score: subsection.score,
      weight: subsection.weight,
    }))
    .sort((a, b) => b.score - a.score)
}

export const getSubsectionPieChartProps = (
  data: QueryResult,
  args: { section: ESGSection }
) => ({
  subsections: getSubsections(data, args),
})

export const getScoreHistoryChartProps = (
  data: QueryResult,
  args: {
    section: ESGSection
    companyName: string
  }
) => ({
  title: '',
  series: [
    {
      name: args.companyName,
      type: 'default' as const,
      data: data.companyFrameworks
        .map((fw) => ({
          year: fw.year,
          value: fw.sections.find((s) => slugify(s.title) === args.section)
            ?.score,
        }))
        .filter(
          (point): point is { year: number; value: number } =>
            point.value !== undefined
        ),
    },
  ],
})

export const getSubsectionScoresChartProps = (
  data: QueryResult,
  args: { section: ESGSection }
) => ({
  title: '',
  subsections: getSubsections(data, args),
})

const ESGSectionOverviewContainer: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [subsectionPieChartProps, setSubsectionPieChartProps] =
    useState<SubsectionPieChartProps | null>(null)
  const [scoreHistoryChartProps, setScoreHistoryChartProps] =
    useState<ScoreHistoryChartProps | null>(null)
  const [subsectionScoresChartProps, setSubsectionScoresChartProps] =
    useState<SubsectionScoresChartProps | null>(null)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingCompany) {
      return
    }

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        setSubsectionPieChartProps(
          getSubsectionPieChartProps(data, { section: props.section })
        )
        setScoreHistoryChartProps(
          getScoreHistoryChartProps(data, {
            section: props.section,
            companyName: companyData!.name,
          })
        )
        setSubsectionScoresChartProps(
          getSubsectionScoresChartProps(data, { section: props.section })
        )
        setLoading(false)
      }
    )
  }, [loadingCompany, makeQuery, props.section, companyData, year])

  return (
    <ESGSectionOverview
      className={props.className}
      loading={loading}
      subsectionPieChartProps={subsectionPieChartProps}
      scoreHistoryChartProps={scoreHistoryChartProps}
      subsectionScoresChartProps={subsectionScoresChartProps}
    />
  )
}

export default ESGSectionOverviewContainer
