import { loader } from 'graphql.macro'
import { clone, union } from 'ramda'
import { useCallback } from 'react'
import { utils as xlsxUtils, writeFile as xlsxWriteFile } from 'xlsx'

import { useImperativeQuery } from '../../../utils/hooks'
import { CompanyRemunerationDataHandler } from './CompanyRemunerationDataHandler'

const companiesDataQuery = loader('./companiesDataQuery.graphql')
const companiesListQuery = loader('./companiesListQuery.graphql')

export const TIME_SERIES_FIELDS: TimeSeriesRemunerationDataField[] = [
  'councilRemuneration',
  'directorsRemuneration',
  'mktcap',
  'ebitda',
  'profit',
  'councilRemunerationBreakdown',
  'directorsRemunerationBreakdown',
  'councilRemunerationStats',
  'directorsRemunerationStats',
  'councilMemberCount',
  'directorsMemberCount',
]

export const EXTENDED_TIME_SERIES_FIELDS: Exclude<
  ExtendedRemunerationDataField,
  `${'council' | 'directors'}Remuneration${'Breakdown' | 'Stats'}`
>[] = [
  'councilRemuneration',
  'directorsRemuneration',
  'mktcap',
  'ebitda',
  'profit',
  'councilLongTermRemuneration',
  'directorsLongTermRemuneration',
  'councilFixedRemuneration',
  'directorsFixedRemuneration',
  'councilVariableRemuneration',
  'directorsVariableRemuneration',
  'councilMaxRemuneration',
  'directorsMaxRemuneration',
  'councilMinRemuneration',
  'directorsMinRemuneration',
  'councilAvgRemuneration',
  'directorsAvgRemuneration',
  'councilMemberCount',
  'directorsMemberCount',
]

export const COMPANY_FIELDS: {
  label: string
  field: TimeSeriesRemunerationDataField
}[] = [
  { label: 'Market Cap', field: 'mktcap' },
  { label: 'EBITDA', field: 'ebitda' },
  { label: 'Lucro', field: 'profit' },
]

export const COUNCIL_FIELDS: {
  label: string
  field: ExtendedRemunerationDataField
}[] = [
  {
    label: 'Remuneração Total',
    field: 'councilRemuneration',
  },
  {
    label: 'Remuneração Fixa',
    field: 'councilFixedRemuneration',
  },
  {
    label: 'Remuneração Variável',
    field: 'councilVariableRemuneration',
  },
  {
    label: 'Remuneração de Longo Prazo',
    field: 'councilLongTermRemuneration',
  },
  {
    label: 'Remuneração Média',
    field: 'councilAvgRemuneration',
  },
  {
    label: 'Remuneração Máxima',
    field: 'councilMaxRemuneration',
  },
  {
    label: 'Remuneração Mínima',
    field: 'councilMinRemuneration',
  },
]

export const DIRECTORS_FIELDS: {
  label: string
  field: ExtendedRemunerationDataField
}[] = [
  {
    label: 'Remuneração Total',
    field: 'directorsRemuneration',
  },
  {
    label: 'Remuneração Fixa',
    field: 'directorsFixedRemuneration',
  },
  {
    label: 'Remuneração Variável',
    field: 'directorsVariableRemuneration',
  },
  {
    label: 'Remuneração de Longo Prazo',
    field: 'directorsLongTermRemuneration',
  },
  {
    label: 'Remuneração Média',
    field: 'directorsAvgRemuneration',
  },
  {
    label: 'Remuneração Máxima',
    field: 'directorsMaxRemuneration',
  },
  {
    label: 'Remuneração Mínima',
    field: 'directorsMinRemuneration',
  },
]

export const isEstimateDate = (date: string) => date.endsWith('E')

export const getConsolidatedDate = (date: string) =>
  isEstimateDate(date) ? date.slice(0, -1) : date

export const getEstimatedDate = (date: string) =>
  isEstimateDate(date) ? date : date + 'E'

export const CUSTOM_ESTIMATE_YEARS = ['2021', '2022'].map(getEstimatedDate)

export const getInitialCustomEstimates = (
  data: RemunerationData,
  year: string
) =>
  Object.fromEntries(
    Object.values(data).map((companyData) => [
      companyData.company.ticker,
      {
        councilRemuneration:
          companyData.councilRemuneration.data.find(
            (value) => getEstimatedDate(value.date) === year
          )?.value ?? null,
        directorsRemuneration:
          companyData.directorsRemuneration.data.find(
            (value) => getEstimatedDate(value.date) === year
          )?.value ?? null,
      },
    ])
  )

export const fillCustomEstimates = (
  data: RemunerationData,
  estimates: RemunerationEstimates,
  year: string
) => {
  const clonedData = clone(data)

  Object.keys(clonedData).forEach((ticker) => {
    if (!estimates[ticker]) {
      return
    }

    const councilRemunerationEstimate = clonedData[
      ticker
    ]?.councilRemuneration.data.find(
      (value) => getEstimatedDate(value.date) === year
    )
    const directorsRemunerationEstimate = clonedData[
      ticker
    ]?.directorsRemuneration.data.find(
      (value) => getEstimatedDate(value.date) === year
    )

    if (councilRemunerationEstimate) {
      councilRemunerationEstimate.date = getEstimatedDate(
        councilRemunerationEstimate.date
      )
      councilRemunerationEstimate.value = estimates[ticker].councilRemuneration
    } else {
      clonedData[ticker].councilRemuneration.data.push({
        date: year,
        value: estimates[ticker].councilRemuneration,
      })
    }

    if (directorsRemunerationEstimate) {
      directorsRemunerationEstimate.date = getEstimatedDate(
        directorsRemunerationEstimate.date
      )
      directorsRemunerationEstimate.value =
        estimates[ticker].directorsRemuneration
    } else {
      clonedData[ticker].directorsRemuneration.data.push({
        date: year,
        value: estimates[ticker].directorsRemuneration,
      })
    }
  })

  return clonedData
}

export const getReferenceFormLinks = (companiesData: RemunerationData) =>
  Object.values(companiesData)
    .sort((dataA, dataB) =>
      dataA.company.name.localeCompare(dataB.company.name)
    )
    .map((data) => ({
      label: data.company.name,
      url: data.referenceFormUrl,
    }))

export const useCompaniesList = () => {
  const makeCompaniesListQuery: () => Promise<{
    remunerationCompanies: RemunerationCompany[]
  }> = useImperativeQuery(companiesListQuery)

  const getCompaniesList = useCallback(async () => {
    try {
      const data = await makeCompaniesListQuery()
      const sortedCompanies = [...data.remunerationCompanies].sort(
        (companyA, companyB) => companyA.name.localeCompare(companyB.name)
      )

      return sortedCompanies
    } catch (err) {
      throw new Error('Failed to get companies list:' + err)
    }
  }, [makeCompaniesListQuery])

  return getCompaniesList
}

export const useCompaniesData = () => {
  const makeCompaniesDataQuery: (args: { tickers: string[] }) => Promise<{
    remunerationData: (CompanyRemunerationData | null)[]
  }> = useImperativeQuery(companiesDataQuery)

  const getCompaniesData = useCallback(
    async (tickers: string[]) => {
      try {
        const data = await makeCompaniesDataQuery({ tickers })

        return Object.fromEntries(
          data.remunerationData
            .filter(
              (companyData): companyData is CompanyRemunerationData =>
                !!companyData
            )
            .map((companyData) => [companyData.company.ticker, companyData])
        )
      } catch (err) {
        throw new Error('Failed to get companies data:' + err)
      }
    },
    [makeCompaniesDataQuery]
  )

  return getCompaniesData
}

export const exportDataToXlsx = (data: RemunerationData) => {
  const wb = xlsxUtils.book_new()

  Object.values(data).forEach((companyData) => {
    const ws = xlsxUtils.aoa_to_sheet([
      ['Empresa:', companyData.company.name],
      ['Ticker:', companyData.company.ticker],
    ])
    const handler = new CompanyRemunerationDataHandler(companyData)

    ;['consolidated' as const, 'estimated' as const].forEach((dataType) => {
      const dataTypeLabel =
        dataType === 'consolidated'
          ? 'Valores consolidados'
          : 'Valores estimados'

      ;[
        { fields: COMPANY_FIELDS, label: 'Empresa' },
        { fields: COUNCIL_FIELDS, label: 'Conselho' },
        { fields: DIRECTORS_FIELDS, label: 'Diretoria' },
      ].forEach((fieldType) => {
        const availableYears = fieldType.fields
          .map(({ field }) =>
            handler
              .getAvailableYears(field)
              .filter((year) =>
                dataType === 'consolidated'
                  ? !isEstimateDate(year)
                  : isEstimateDate(year)
              )
          )
          .reduce((acc, years) => union(acc, years), [])
          .sort((ya, yb) => ya.localeCompare(yb))

        if (availableYears.length === 0) {
          return
        }

        xlsxUtils.sheet_add_aoa(
          ws,
          [
            [],
            [
              [dataTypeLabel, fieldType.label].join(' - ') as string | number,
            ].concat(
              availableYears.map((year) => parseInt(getConsolidatedDate(year)))
            ),
          ],
          { origin: -1 }
        )

        fieldType.fields.forEach((field) => {
          xlsxUtils.sheet_add_aoa(
            ws,
            [
              [field.label as string | number | null].concat(
                availableYears.map((year) =>
                  handler.getValue(year, field.field)
                )
              ),
            ],
            { origin: -1 }
          )
        })
      })
    })

    xlsxUtils.book_append_sheet(wb, ws, companyData.company.ticker)
  })

  xlsxWriteFile(wb, 'dados.xlsx')
}
