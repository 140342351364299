import { equals, omit } from 'ramda'
import { useReducer } from 'react'
import { v4 as uuid } from 'uuid'

import type {
  BasketCompany,
  BasketFilter,
  BasketFilterOption,
} from '../../typings/baskets'

interface State {
  filters: BasketFilter[]
  filterOptions: BasketFilterOption[]
  companies: BasketCompany[] | null
  basketName: string
  userBaskets: UserBasket[]
  loadingCompanies: boolean
  loadingFiltersData: boolean
}

interface AddFilterAction {
  type: 'ADD_FILTER'
}

interface ChangeFilterFilterAction {
  type: 'CHANGE_FILTER_FILTER'
  id: string
  filter: SelectOption
}

interface ChangeFilterModeAction {
  type: 'CHANGE_FILTER_MODE'
  id: string
  mode: SelectOption
}

interface ChangeFilterValuesAction {
  type: 'CHANGE_FILTER_VALUES'
  id: string
  values: SelectOption[]
}

interface LoadBasketSettingsAction {
  type: 'LOAD_BASKET_SETTINGS'
  basket: UserBasket
}

interface RemoveFilterAction {
  type: 'REMOVE_FILTER'
  id: string
}

interface SetStateAction extends Partial<State> {
  type: 'SET_STATE'
}

type Action =
  | AddFilterAction
  | ChangeFilterFilterAction
  | ChangeFilterModeAction
  | ChangeFilterValuesAction
  | LoadBasketSettingsAction
  | RemoveFilterAction
  | SetStateAction

const INITIAL_STATE: State = {
  filters: [],
  filterOptions: [],
  companies: null,
  basketName: '',
  userBaskets: [],
  loadingCompanies: false,
  loadingFiltersData: false,
}

const addFilter: React.Reducer<State, AddFilterAction> = (state) => {
  const [option] = state.filterOptions

  if (!option) {
    return state
  }

  const newFilter: BasketFilter = {
    id: uuid(),
    filter: { label: option.label, value: option.name },
    mode: option.types[0],
    values: [],
  }

  return { ...state, filters: state.filters.concat(newFilter) }
}

const changeFilterFilter: React.Reducer<State, ChangeFilterFilterAction> = (
  state,
  action
) => {
  const option = state.filterOptions.find(
    (option) => option.name === action.filter.value
  )

  if (!option) {
    return state
  }

  return {
    ...state,
    filters: state.filters.map((filter) =>
      filter.id === action.id && filter.filter.value !== action.filter.value
        ? {
            ...filter,
            filter: action.filter,
            mode: option.types.some((type) => equals(type, filter.mode))
              ? filter.mode
              : option.types[0],
            values: [],
          }
        : filter
    ),
  }
}

const changeFilterMode: React.Reducer<State, ChangeFilterModeAction> = (
  state,
  action
) => ({
  ...state,
  filters: state.filters.map((filter) =>
    filter.id === action.id ? { ...filter, mode: action.mode } : filter
  ),
})

const changeFilterValues: React.Reducer<State, ChangeFilterValuesAction> = (
  state,
  action
) => ({
  ...state,
  filters: state.filters.map((filter) =>
    filter.id === action.id ? { ...filter, values: action.values } : filter
  ),
})

const loadBasketSettings: React.Reducer<State, LoadBasketSettingsAction> = (
  state,
  action
) => {
  const filters = action.basket.criteria
    .map((criterion) => {
      const filter = state.filterOptions.find(
        (option) => option.name === criterion.name
      )

      if (!filter) {
        return null
      }

      const mode =
        filter.types.find((option) => option.value === criterion.type) ??
        filter.types[0] ??
        null
      const values = filter.values.filter((value) =>
        criterion.values.includes(value.value)
      )

      return {
        id: uuid(),
        filter: { label: filter.label, value: filter.name },
        mode,
        values,
      }
    })
    .filter((filter): filter is BasketFilter => filter !== null)

  return {
    ...state,
    basketName: action.basket.name,
    filters,
  }
}

const removeFilter: React.Reducer<State, RemoveFilterAction> = (
  state,
  action
) => ({
  ...state,
  filters: state.filters.filter((filter) => filter.id !== action.id),
})

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'ADD_FILTER':
      return addFilter(state, action)
    case 'CHANGE_FILTER_FILTER':
      return changeFilterFilter(state, action)
    case 'CHANGE_FILTER_MODE':
      return changeFilterMode(state, action)
    case 'CHANGE_FILTER_VALUES':
      return changeFilterValues(state, action)
    case 'LOAD_BASKET_SETTINGS':
      return loadBasketSettings(state, action)
    case 'REMOVE_FILTER':
      return removeFilter(state, action)
    case 'SET_STATE':
      return { ...state, ...omit(['type'], action) }
  }
}

export const useBasketsReducer = () => useReducer(reducer, INITIAL_STATE)
