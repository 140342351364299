import React from 'react'
import { Link } from 'react-router-dom'

import ButtonWithLoading from '../ButtonWithLoading'
import BellIcon from '../icons/Bell'
import BlankBellIcon from '../icons/BlankBell'
import BlankStarIcon from '../icons/BlankStar'
import StarIcon from '../icons/Star'

export interface Props {
  isFavorite: boolean
  isNotified: boolean
  label: string
  url: string
  onFavoriteChange?: (value: boolean) => Promise<void>
  onNotificationChange?: (value: boolean) => Promise<void>
}

const ListItem: React.FC<Props> = (props) => {
  return (
    <div className="flex items-center justify-between border-b border-jgp-secondary-light border-opacity-50 px-4 py-3">
      <Link className="text-sm font-semibold" to={props.url}>
        {props.label}
      </Link>
      <span className="flex items-center">
        <ButtonWithLoading
          className="flex items-center justify-center focus:outline-none"
          onClick={async () => props.onFavoriteChange?.(!props.isFavorite)}
          spinnerProps={{
            className: 'text-jgp-success-main',
            size: 18,
          }}
        >
          {props.isFavorite ? (
            <StarIcon className="h-6 w-6 text-jgp-success-main" />
          ) : (
            <BlankStarIcon className="h-6 w-6 text-jgp-secondary-main" />
          )}
        </ButtonWithLoading>
        <ButtonWithLoading
          className="ml-3 flex items-center justify-center focus:outline-none"
          onClick={async () => props.onNotificationChange?.(!props.isNotified)}
          spinnerProps={{
            className: 'text-jgp-success-main',
            size: 18,
          }}
        >
          {props.isNotified ? (
            <BellIcon className="h-6 w-6 text-jgp-success-main" />
          ) : (
            <BlankBellIcon className="h-6 w-6 text-jgp-secondary-main" />
          )}
        </ButtonWithLoading>
      </span>
    </div>
  )
}

export default ListItem
