export const formatRanking = (pos: number, total: number) =>
  `${pos === -1 ? '-' : pos}/${total}`

export const buildQualiTableIndicator = (indicator: {
  title: string
  score: number
  reason: string
}) => ({
  title: indicator.title,
  status:
    indicator.score >= 100
      ? ('green' as const)
      : indicator.score > 0
      ? ('yellow' as const)
      : ('red' as const),
  reason: indicator.reason,
})
