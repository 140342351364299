import { useState } from 'react'
import Tooltip from 'react-tooltip'

import { PencilAltIcon, TrashIcon } from '../../../../components/icons'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import Modal from '../../../../components/Modal'
import { useToast } from '../../../../contexts/Toast'
import { displayModal } from '../../../../utils/modal'
import RenameModal from './RenameModal'

interface Report {
  id: string
  name: string
}

type Props = React.ComponentProps<typeof Modal> & {
  onLoad?: (id: string) => Promise<any>
  onRename?: (id: string, name: string) => Promise<any>
  onDelete?: (id: string) => Promise<any>
  savedReports: Report[]
}

const getRenameButtonTooltipId = (id: string) => `rename-${id}`

const getDeleteButtonTooltipId = (id: string) => `delete-${id}`

const RemunerationLoadModal: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [reportToRename, setReportToRename] = useState<Report | null>(null)

  const { enqueueToast } = useToast()

  const handleLoad = (id: string) => {
    if (!props.onLoad) {
      return
    }

    setLoading(true)

    props
      .onLoad(id)
      .then(() => {
        enqueueToast({
          type: 'success',
          message: 'Relatório carregado com sucesso',
        })
        props.onClose?.()
      })
      .catch((err: any) => {
        console.error('Failed to load report:', err)
        enqueueToast({
          type: 'fail',
          message: 'Ocorreu um erro ao carregar o relatório',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleRename = (id: string, name: string) => {
    if (!props.onRename) {
      return
    }

    setLoading(true)

    props
      .onRename(id, name)
      .then(() => {
        enqueueToast({
          type: 'success',
          message: 'Relatório renomeado com sucesso',
        })
      })
      .catch((err: any) => {
        console.error('Failed to rename report:', err)
        enqueueToast({
          type: 'fail',
          message: 'Ocorreu um erro ao tentar renomear o relatório',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDelete = (report: Report) => {
    const proceedToDelete = () => {
      if (!props.onDelete) {
        return
      }

      setLoading(true)

      props
        .onDelete(report.id)
        .then(() => {
          enqueueToast({
            type: 'success',
            message: 'Relatório excluído com sucesso',
          })
        })
        .catch((err) => {
          console.error('Failed to delete report:', err)
          enqueueToast({
            type: 'fail',
            message: 'Ocorreu um erro ao tentar excluír o relatório',
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }

    displayModal({
      message: `Deseja mesmo excluir o relatório "${report.name}"?`,
      props: {
        open: true,
        onClose: () => {},
      },
      buttons: [
        {
          label: 'Excluir',
          props: {
            variant: 'danger',
            className: 'h-8 w-28',
            onClick: proceedToDelete,
          },
        },
        {
          label: 'Cancelar',
          props: {
            variant: 'secondary',
            className: 'h-8 w-28',
          },
        },
      ],
    })
  }

  return (
    <Modal {...props} dismissable={!loading && props.dismissable}>
      <div className="max-w-screen relative min-w-[40rem] p-8">
        <LoadingOverlay
          className="absolute inset-0 flex items-center justify-center bg-opacity-70"
          type="local"
          show={loading}
        />
        <div className="text-2xl font-bold">Carregar relatório</div>
        {props.savedReports.length === 0 ? (
          <div className="mt-4">Você não possui relatórios salvos.</div>
        ) : (
          <div className="mt-4">
            {props.savedReports.map((report) => (
              <div
                key={report.id}
                className="flex items-center justify-between p-1 hover:bg-[#abe695]"
              >
                <button
                  className="hover:underline"
                  onClick={() => handleLoad(report.id)}
                >
                  {report.name}
                </button>
                <span className="flex items-center gap-x-2">
                  <button
                    data-tip
                    data-for={getRenameButtonTooltipId(report.id)}
                    onClick={() => setReportToRename(report)}
                  >
                    <PencilAltIcon className="h-5 w-5 text-jgp-success-dark" />
                  </button>
                  <Tooltip
                    id={getRenameButtonTooltipId(report.id)}
                    effect="solid"
                  >
                    Renomear relatório
                  </Tooltip>
                  <button
                    data-tip
                    data-for={getDeleteButtonTooltipId(report.id)}
                    onClick={() => handleDelete(report)}
                  >
                    <TrashIcon className="h-5 w-5 text-jgp-error-dark" />
                  </button>
                  <Tooltip
                    id={getDeleteButtonTooltipId(report.id)}
                    effect="solid"
                  >
                    Excluir relatório
                  </Tooltip>
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      {reportToRename && (
        <RenameModal
          currentName={reportToRename.name}
          reportNames={props.savedReports.map((report) => report.name)}
          onRename={(name) => handleRename(reportToRename.id, name)}
          onClose={() => setReportToRename(null)}
        />
      )}
    </Modal>
  )
}

export default RemunerationLoadModal
