import React from 'react'

import { XIcon } from '../../../../components/icons'

interface Props {
  title: string
  onClose?: () => void
  onClick?: () => void
}

const Modal: React.FC<Props> = (props) => (
  <div
    className="fixed inset-0 z-40 flex items-center justify-center p-4"
    onClick={props.onClose}
  >
    <div
      className="max-h-full max-w-screen-sm flex-grow shadow-md"
      onClick={(event) => {
        event.stopPropagation()
        props.onClick?.()
      }}
    >
      <div className="flex items-center justify-between bg-jgp-success-main p-4">
        <span className="text-lg font-semibold">{props.title}</span>
        <button
          className="text-white hover:text-gray-100 focus:text-gray-100 focus:outline-none"
          onClick={props.onClose}
        >
          <XIcon className="h-5 w-5" />
        </button>
      </div>
      {props.children}
    </div>
  </div>
)

export default Modal
