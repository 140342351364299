export const GLOBAL_VARIABLES_SLUG = 'variaveis-globais'

export const SELECT_STYLES = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    fontSize: '0.875rem',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#30ba09',
    '&:hover': {
      color: '#297f0d',
    },
  }),
}

export const BASKET_SHARED_MSG =
  'Um link compartilhável do basket foi copiado para sua área de transferência'
export const REPORT_SHARED_MSG =
  'Um link compartilhável da análise foi copiado para sua área de transferência'
