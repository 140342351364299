import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'

import { slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import ESGSubsection from '../../components/ESGSubsection'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface Props {
  section: ESGSection
  subsection: string
  className?: string
}

interface QueryResult {
  companyFramework: {
    sections: {
      title: string
      score: number
      ranking: number[]
      peerAverageScore: {
        value: number
      }
      weight: number
      subsections: {
        title: string
        score: number
        ranking: number[]
        peerAverageScore: {
          value: number
        }
        weight: number
        quantIndicators: {
          values: {
            year: number
          }[]
        }[]
      }[]
    }[]
  }
}

const query = loader('./query.graphql')

const ESGSubsectionContainer: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [score, setScore] = useState<number | null>(null)
  const [ranking, setRanking] = useState<number[]>([])
  const [peerAverageScore, setPeerAverageScore] = useState<number | null>(null)
  const [weight, setWeight] = useState<number | null>(null)
  const [hasQuant, setHasQuant] = useState<boolean>(false)
  const [hasQuali, setHasQuali] = useState<boolean>(false)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingCompany) {
      return
    }

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        const section = data.companyFramework.sections.find(
          (s) => slugify(s.title) === props.section
        )

        if (!section) {
          throw new Error(`Section ${props.section} does not exist`)
        }

        const subsection = section.subsections.find(
          (s) => s.title === props.subsection
        )

        if (!subsection) {
          throw new Error(
            `Section ${props.subsection} does not exist in section ${props.section}`
          )
        }

        setScore(subsection.score)
        setRanking(subsection.ranking)
        setPeerAverageScore(subsection.peerAverageScore?.value ?? null)
        setWeight(subsection.weight)
        setHasQuant(subsection.quantIndicators.length > 0)
        setHasQuali(subsection.score !== null)
        setLoading(false)
      }
    )
  }, [
    loadingCompany,
    makeQuery,
    companyData,
    year,
    props.section,
    props.subsection,
  ])

  return (
    <ESGSubsection
      className={props.className}
      loading={loading}
      section={props.section}
      subsection={props.subsection}
      score={score}
      ranking={ranking}
      peerAverageScore={peerAverageScore}
      weight={weight}
      hasQuant={hasQuant}
      hasQuali={hasQuali}
    />
  )
}

export default ESGSubsectionContainer
