import classNames from 'classnames'
import { path } from 'ramda'
import React, { useState } from 'react'

import Logo from '../../../../assets/logo.png'
import Spinner from '../../../../components/Spinner'
import styles from './Login.module.css'

interface Props {
  onLogin: (
    email: string,
    password: string,
    rememberMe: boolean
  ) => Promise<void>
  className?: string
}

const Login: React.FC<Props> = (props) => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [rememberMe, setRememberMe] = useState<boolean>(false)

  const handleLogin = () => {
    setLoading(true)
    props.onLogin(email, password, rememberMe).catch((err) => {
      const status = path(['response', 'status'], err)
      const errorMessage =
        status === 401
          ? 'Usuário ou senha incorretos'
          : status === 403
          ? 'Você não tem permissão para acessar esta plataforma'
          : status === 429
          ? 'Muitas solicitações. Por favor, tente novamente mais tarde'
          : 'Ocorreu um erro inexperado. Por favor, tente novamente mais tarde'
      setLoading(false)
      setError(errorMessage)
    })
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    setPassword(event.target.value)
  }

  return (
    <div
      className={classNames(
        'flex h-full items-center justify-center',
        styles.container,
        props.className
      )}
    >
      <div className="my-10 w-96 font-work-sans">
        <div className="flex">
          <button className="h-6 w-28 bg-white text-sm font-light focus:outline-none">
            Cliente
          </button>
        </div>
        <div className="flex flex-col items-center bg-white pt-11 pb-4 pl-11 pr-11">
          <img className="h-12" alt="Logo" src={Logo} />
          <input
            className="mt-10 h-8 w-full border-2 border-jgp-secondary-light border-opacity-50 px-1 focus:border-jgp-success-main focus:outline-none"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <input
            className="mt-3 h-8 w-full border-2 border-jgp-secondary-light border-opacity-50 px-1 focus:border-jgp-success-main focus:outline-none"
            type="password"
            placeholder="Senha"
            value={password}
            onChange={handlePasswordChange}
          />
          <div className="mt-5 flex w-full justify-between">
            <button className="h-8 w-32 border-2 border-jgp-success-light text-xs font-medium text-jgp-success-light hover:border-jgp-success-main hover:text-jgp-success-main focus:outline-none focus-visible:border-jgp-success-main focus-visible:text-jgp-success-main">
              Cadastre-se
            </button>
            <button
              className="flex h-8 w-32 items-center justify-center border-2 border-jgp-success-light text-xs font-medium text-jgp-success-light hover:border-jgp-success-main hover:text-jgp-success-main focus:outline-none focus-visible:border-jgp-success-main focus-visible:text-jgp-success-main"
              onClick={handleLogin}
            >
              {loading ? (
                <Spinner className="text-jgp-success-main" size={16} />
              ) : (
                'Entrar'
              )}
            </button>
          </div>
          <label className="mt-5 flex cursor-pointer select-none items-center self-start">
            <input
              className="mr-1.5"
              type="checkbox"
              checked={rememberMe}
              onChange={(event) => setRememberMe(event.target.checked)}
            />
            <span className="text-xs font-light">Lembre-me</span>
          </label>
          <div
            className={classNames(
              'mt-2 min-h-[16px] self-start text-xs text-jgp-error-main',
              {
                invisible: !error,
              }
            )}
          >
            {error}
          </div>
        </div>
        <div className="w-full bg-white">
          <div className="flex h-10 w-full items-center justify-center bg-jgp-secondary-light bg-opacity-50 text-sm font-medium">
            <span className="cursor-pointer">Esqueci a senha</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
