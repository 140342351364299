import React from 'react'
import ReactSelect, {
  type OptionTypeBase,
  type Props,
  components,
} from 'react-select'
import CreatableSelect, { type CreatableProps } from 'react-select/creatable'

import SearchIcon from '../icons/Search'

interface CustomProps {
  innerRef?: React.RefObject<any>
  color?: 'light' | 'dark'
  icon?: 'search' | null
  creatable?: boolean
}

const Control =
  (icon: CustomProps['icon']) =>
  ({ children, ...rest }: any) => (
    <components.Control {...rest}>
      {icon === 'search' ? (
        <SearchIcon className="ml-2 h-4 w-4 text-white" />
      ) : null}
      {children}
    </components.Control>
  )

const Select = <
  T extends OptionTypeBase = SelectOption,
  isMulti extends boolean = false,
>(
  props: Props<T, isMulti> & CreatableProps<T, isMulti> & CustomProps
) => {
  const {
    innerRef,
    color = 'light',
    icon = 'search',
    creatable,
    ...rest
  } = props

  const CoreSelect = (props: Props<T, isMulti> & CreatableProps<T, isMulti>) =>
    creatable ? (
      <CreatableSelect<T, isMulti> {...props} />
    ) : (
      <ReactSelect<T, isMulti> {...props} />
    )

  return (
    <CoreSelect
      {...rest}
      ref={innerRef}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(255, 255, 255, 0)',
        }),
        placeholder: (provided) => ({
          ...provided,
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#abe695',
          primary50: '#80d960',
          primary: '#297f0d',
          ...(color === 'light'
            ? {
                neutral50: 'white',
                neutral80: 'white',
              }
            : {}),
        },
      })}
      components={{ Control: Control(icon) }}
    />
  )
}

export default Select
