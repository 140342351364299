import { useLocation } from 'react-router-dom'

import { Path } from '../../../utils/constants'

export const useSectoralPath = () => {
  const { pathname } = useLocation()

  const skipCount = Path.REPORTS_SECTORAL.split('/').length
  const [sector, section, subsection, subsubsection] = pathname
    .split('/')
    .slice(skipCount)

  return { sector, section, subsection, subsubsection }
}
