import { loader } from 'graphql.macro'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useCompanies } from '../../../../contexts/Companies'
import { slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import { useCompanyPath } from '../../utils/hooks'

interface ICompanyReportContext {
  companyData: Company | null
  availableYears: number[]
  year: number | null
  setYear: React.Dispatch<React.SetStateAction<number | null>>
  loading: boolean
}

const CompanyReportContext = createContext<ICompanyReportContext>(
  {} as ICompanyReportContext
)

const query = loader('./query.graphql')

export const CompanyReportProvider: React.FC = (props) => {
  const [companyData, setCompanyData] = useState<Company | null>(null)
  const [availableYears, setAvailableYears] = useState<number[]>([])
  const [year, setYear] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const makeQuery = useImperativeQuery(query)
  const { loading: loadingCompanies, companies } = useCompanies()
  const { companyName } = useCompanyPath()

  useEffect(() => {
    if (loadingCompanies) {
      return
    }

    const companyMetadata = companies.find(
      (metadata) => slugify(metadata.name) === companyName
    )

    if (!companyMetadata) {
      setLoading(false)
      return
    }

    makeQuery({ companyId: companyMetadata.id }).then((data) => {
      const years = data.companyFrameworks.map(
        (fw: { year: number }) => fw.year
      ) as number[]
      setCompanyData({
        ...companyMetadata,
        desc: data.company.description,
        controversies: data.company.controversies,
      })
      setAvailableYears(years.sort((ya, yb) => yb - ya))
      years.length > 0 && setYear(Math.max(...years))
      setLoading(false)
    })
  }, [loadingCompanies, companies, companyName, makeQuery])

  const value = useMemo(
    () => ({
      companyData,
      availableYears,
      year,
      setYear,
      loading,
    }),
    [companyData, availableYears, year, setYear, loading]
  )

  // Debug
  useEffect(() => {
    ;(window as any).CompanyReportContext = value
  })

  return (
    <CompanyReportContext.Provider value={value}>
      {props.children}
    </CompanyReportContext.Provider>
  )
}

export const useCompanyReport = () => {
  const ctx = useContext(CompanyReportContext)

  if (!ctx) {
    throw new Error(
      'useCompanyReport must be used within a CompanyReportProvider'
    )
  }

  return ctx
}
