import classNames from 'classnames'
import { union } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

import Button from '../../../../components/Button'
import { ChevronDownIcon, ChevronRightIcon } from '../../../../components/icons'
import LabeledComponent from '../../../../components/LabeledComponent'
import { usePrevious } from '../../../../utils/hooks'
import { CompanyRemunerationDataHandler } from '../../utils/CompanyRemunerationDataHandler'
import {
  COMPANY_FIELDS,
  COUNCIL_FIELDS,
  DIRECTORS_FIELDS,
  TIME_SERIES_FIELDS,
} from '../../utils/remuneration'
import type { DataField } from './DataFieldSection'
import DataFieldSection from './DataFieldSection'

interface Props {
  className?: string
  data: RemunerationData
  originalData: RemunerationData
  onDataChange?: (data: RemunerationData) => any
}

type State = 'collapsed' | 'expanded'

const BUTTON_CLASS = 'h-12 w-28'

const addFieldValues = (
  fields: Omit<DataField, 'value'>[],
  data: CompanyRemunerationData,
  year: string
): DataField[] => {
  const handler = new CompanyRemunerationDataHandler(data)

  return fields.map((field) => ({
    ...field,
    value: handler.getValue(year, field.field) ?? null,
  }))
}

const RemunerationDataCorrector: React.FC<Props> = (props) => {
  const [state, setState] = useState<State>('collapsed')
  const [company, setCompany] = useState<SelectOption | null>(null)
  const [year, setYear] = useState<SelectOption | null>(null)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [data, setData] = useState<RemunerationData>(props.data)

  const prevCompany = usePrevious(company)
  const prevYear = usePrevious(year)
  const prevData = usePrevious(props.data)

  const companyOptions = useMemo(
    () =>
      Object.values(props.data).map((data) => ({
        label: data.company.name,
        value: data.company.ticker,
      })),
    [props.data]
  )

  const yearOptions = useMemo(() => {
    if (company === null || !props.data[company.value]) {
      return []
    }

    const allYears = TIME_SERIES_FIELDS.map((field) =>
      props.data[company.value][field].data.map((point) => point.date)
    )

    return allYears
      .reduce((acc, years) => union(acc, years), [])
      .sort((ya, yb) => yb.localeCompare(ya))
      .map((year) => ({ label: year, value: year }))
  }, [props.data, company])

  const getFields = useCallback(
    (fields: Omit<DataField, 'value'>[]) => {
      if (!company || !year || !data[company.value]) {
        return fields.map((field) => ({ ...field, value: null }))
      }

      return addFieldValues(fields, data[company.value], year.value)
    },
    [company, year, data]
  )

  const companyFields = useMemo(() => getFields(COMPANY_FIELDS), [getFields])
  const directorsFields = useMemo(
    () => getFields(DIRECTORS_FIELDS),
    [getFields]
  )
  const councilFields = useMemo(() => getFields(COUNCIL_FIELDS), [getFields])

  const ChevronIcon = state === 'collapsed' ? ChevronRightIcon : ChevronDownIcon
  const sectionsDisabled = company === null || year === null

  const handleToggleState = () =>
    setState((curState) =>
      curState === 'collapsed' ? 'expanded' : 'collapsed'
    )

  const handleChange = useCallback(
    (field: ExtendedRemunerationDataField, value: number | null) => {
      if (!company || !year) {
        return
      }

      const handler = new CompanyRemunerationDataHandler(data[company.value])

      if (value !== handler.getValue(year.value, field)) {
        setHasChanges(true)
      }

      setData((curData) => ({
        ...curData,
        [company.value]: handler.setValue(year.value, field, value).getData(),
      }))
    },
    [data, company, year]
  )

  const handleApply = useCallback(() => {
    const onDataChange = props.onDataChange
    onDataChange?.(data)
  }, [props.onDataChange, data])

  const resetChanges = useCallback(() => {
    setData(props.data)
    setHasChanges(false)
  }, [props.data])

  const handleUseOriginalValues = useCallback(() => {
    setData(props.originalData)

    if (props.data !== props.originalData) {
      setHasChanges(true)
    }
  }, [props.data, props.originalData])

  useEffect(() => {
    if (
      prevCompany === company &&
      prevYear === year &&
      props.data === prevData
    ) {
      return
    }

    resetChanges()
  }, [company, year, props.data, prevCompany, prevYear, prevData, resetChanges])

  useEffect(() => {
    if (!companyOptions.some((option) => option.value === company?.value)) {
      setCompany(companyOptions[0] ?? null)
    }
  }, [company, companyOptions])

  useEffect(() => {
    if (
      company &&
      !yearOptions.some((option) => option.value === year?.value)
    ) {
      setYear(yearOptions[0] ?? null)
    }
  }, [company, year, yearOptions])

  return (
    <div className={props.className}>
      <div>
        <button
          className="flex items-center hover:underline"
          onClick={handleToggleState}
        >
          <ChevronIcon className="h-5 w-5" />
          <span>Corrigir dados</span>
        </button>
      </div>
      <div
        className={classNames('mt-4 pl-1', {
          hidden: state === 'collapsed',
        })}
      >
        <div className="flex items-center gap-4">
          <LabeledComponent label="Empresa">
            <Select
              className="w-80"
              options={companyOptions}
              value={company}
              placeholder=""
              onChange={(value) => setCompany(value ?? null)}
            />
          </LabeledComponent>
          <LabeledComponent label="Ano">
            <Select
              className="w-28"
              options={yearOptions}
              value={year}
              isDisabled={company === null}
              placeholder=""
              onChange={(value) => setYear(value ?? null)}
              noOptionsMessage={() => null}
            />
          </LabeledComponent>
        </div>
        <div className="mt-8 grid grid-cols-1 gap-x-8 gap-y-8 lg:grid-cols-2">
          <DataFieldSection
            title="Empresa"
            fields={companyFields}
            disabled={sectionsDisabled}
            onChange={handleChange}
          />
          <DataFieldSection
            className="lg:row-start-2"
            title="Conselho de Administração"
            fields={councilFields}
            disabled={sectionsDisabled}
            onChange={handleChange}
          />
          <DataFieldSection
            className="lg:row-start-2"
            title="Diretoria"
            fields={directorsFields}
            disabled={sectionsDisabled}
            onChange={handleChange}
          />
        </div>
        <div className="mt-8 flex justify-end gap-4">
          <Button
            className={BUTTON_CLASS}
            variant="primary"
            onClick={handleApply}
            disabled={!hasChanges}
          >
            Aplicar
          </Button>
          <Button
            className={BUTTON_CLASS}
            variant="secondary"
            onClick={resetChanges}
          >
            Desfazer alterações
          </Button>
          <Button
            className={BUTTON_CLASS}
            variant="secondary"
            onClick={handleUseOriginalValues}
          >
            Usar valores originais
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RemunerationDataCorrector
