import { useMutation } from '@apollo/client'
import { genPPT } from '@jgp-er-dev/charts'
import { getTheme } from '@jgp-er-dev/themes'
import { loader } from 'graphql.macro'
import React, { useCallback, useEffect, useState } from 'react'

import { getChartConfig as getColumnChartConfig } from '../../../../components/ColumnChart'
import { getChartConfig as getESGPieChartConfig } from '../../../../components/ESGPieChart'
import ControversyIcon from '../../../../components/icons/Controversy'
import DatabaseIcon from '../../../../components/icons/Database'
import EnvironmentalIcon from '../../../../components/icons/Environmental'
import GovernanceIcon from '../../../../components/icons/Governance'
import OverviewIcon from '../../../../components/icons/Overview'
import ScoresIcon from '../../../../components/icons/Scores'
import SocialIcon from '../../../../components/icons/Social'
import NotFound from '../../../../components/NotFound'
import type { Section } from '../../../../components/ReportSidebar/SidebarSection'
import { getChartConfig as getScoreHistoryChartConfig } from '../../../../components/ScoreHistoryChart'
import { getChartConfig as getSubsectionPieChartConfig } from '../../../../components/SubsectionPieChart'
import { useChartRenderer } from '../../../../contexts/ChartRenderer'
import { slugify } from '../../../../utils'
import { ENVIRONMENTAL, GOVERNANCE, SOCIAL } from '../../../../utils/constants'
import { useImperativeQuery } from '../../../../utils/hooks'
import SectoralReport from '../../components/SectoralReport'
import { useActiveCompanies } from '../../contexts/ActiveCompanies'
import { useSectoralReport } from '../../contexts/SectoralReport'
import * as ESGSectionOverview from '../ESGSectionOverview'
import * as ESGSubsection from '../ESGSubsection'
import * as Overview from '../Overview'

interface Props {
  className?: string
}

interface QueryResult {
  multiCompanyFramework: {
    sections: {
      title: string
      subsections: {
        title: string
      }[]
    }[]
  }
  user: {
    favorites: { sectors: string[] }
    notifications: { sectors: string[] }
  }
}

interface FullReportQueryResult {
  peerGroupFrameworks: {
    company: {
      name: string
    }
    score: number
    sections: {
      title: string
      weight: number
      subsections: {
        title: string
        weight: number
      }[]
    }[]
  }[]
  multiCompanyFramework: {
    sections: {
      title: string
      defaultWeight: number
      scores: {
        company: { name: string }
        value: number
      }[]
      subsections: {
        title: string
        defaultWeight: number | null
        scores:
          | {
              company: { name: string }
              value: number
            }[]
          | null
        scoreHistories: {
          company: { name: string }
          values: {
            year: number
            value: number
          }[]
        }[]
        quantIndicators: {
          title: string
          unit: string
          values: {
            company: { name: string }
            values: {
              year: number
              value: number
            }[]
          }[]
        }[]
      }[]
    }[]
  }
}

const query = loader('./query.graphql')
const fullReportQuery = loader('./fullReportQuery.graphql')
const mutationSetFavorite = loader('./mutationSetFavorite.graphql')
const mutationSetNotification = loader('./mutationSetNotification.graphql')
const mutationSetRecent = loader('./mutationSetRecent.graphql')

const SectoralReportContainer: React.FC<Props> = (props) => {
  const [sections, setSections] = useState<Section[]>([])
  const [isFavorite, setIsFavorite] = useState<boolean>(false)
  const [isNotified, setIsNotified] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const {
    loading: loadingSector,
    companies,
    year,
    sector,
  } = useSectoralReport()
  const { activeCompanies } = useActiveCompanies()
  const makeQuery = useImperativeQuery(query)
  const makeFullReportQuery = useImperativeQuery(fullReportQuery)
  const [changeFavorite] = useMutation(mutationSetFavorite)
  const [changeNotification] = useMutation(mutationSetNotification)
  const [updateRecent] = useMutation(mutationSetRecent)
  const { getChartImages } = useChartRenderer()

  useEffect(() => {
    if (loadingSector || !sector) {
      return
    }

    updateRecent({ variables: { sectorSlug: slugify(sector!) } })

    makeQuery({
      companyIds: companies.map((company) => company.id),
      year,
    }).then((data: QueryResult) => {
      const environmentalSection = data.multiCompanyFramework.sections.find(
        (s) => s.title === ENVIRONMENTAL
      )
      const socialSection = data.multiCompanyFramework.sections.find(
        (s) => s.title === SOCIAL
      )
      const governanceSection = data.multiCompanyFramework.sections.find(
        (s) => s.title === GOVERNANCE
      )

      setSections([
        {
          Icon: OverviewIcon,
          path: '/overview',
          label: 'Visão Geral',
        },
        {
          Icon: EnvironmentalIcon,
          path: '/environmental',
          label: 'Ambiental',
          subsections: [
            {
              label: 'Visão geral',
              path: '/environmental/overview',
            },
            ...environmentalSection!.subsections.map((subsection) => ({
              label: subsection.title,
              path: `/environmental/${slugify(subsection.title)}`,
            })),
          ],
        },
        {
          Icon: SocialIcon,
          path: '/social',
          label: 'Social',
          subsections: [
            {
              label: 'Visão geral',
              path: '/social/overview',
            },
            ...socialSection!.subsections.map((subsection) => ({
              label: subsection.title,
              path: `/social/${slugify(subsection.title)}`,
            })),
          ],
        },
        {
          Icon: GovernanceIcon,
          path: '/governance',
          label: 'Governança',
          subsections: [
            {
              label: 'Visão geral',
              path: '/governance/overview',
            },
            ...governanceSection!.subsections.map((subsection) => ({
              label: subsection.title,
              path: `/governance/${slugify(subsection.title)}`,
            })),
          ],
        },
        {
          Icon: ControversyIcon,
          path: '/controversies',
          label: 'Controvérsias',
        },
        {
          Icon: ScoresIcon,
          path: '/scores',
          label: 'Notas',
        },
        {
          Icon: DatabaseIcon,
          path: '/data',
          label: 'Dados',
        },
      ])
      setLoading(false)
      setIsFavorite(data.user.favorites.sectors.includes(slugify(sector!)))
      setIsNotified(data.user.notifications.sectors.includes(slugify(sector!)))
    })
  }, [loadingSector, companies, year, sector, makeQuery, updateRecent])

  const handleFavoriteChange = useCallback(
    async (value: boolean) => {
      if (loadingSector) {
        return
      }

      return changeFavorite({
        variables: {
          sectorSlug: slugify(sector!),
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setIsFavorite(
          res.data.setFavorite.favorites.sectors.includes(slugify(sector!))
        )
      })
    },
    [loadingSector, changeFavorite, sector]
  )

  const handleNotificationChange = useCallback(
    async (value: boolean) => {
      if (loadingSector) {
        return
      }

      return changeNotification({
        variables: {
          sectorSlug: slugify(sector!),
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setIsNotified(
          res.data.setNotification.notifications.sectors.includes(
            slugify(sector!)
          )
        )
      })
    },
    [loadingSector, changeNotification, sector]
  )

  const handleDownload = useCallback(async () => {
    if (loadingSector) {
      return
    }

    const data: FullReportQueryResult = await makeFullReportQuery({
      peerGroup: sector,
      companyIds: companies.map((company) => company.id),
      year,
    })

    const overviewChartConfigs = [
      getESGPieChartConfig(Overview.getEsgPieChartProps(data)!),
      getColumnChartConfig(Overview.getColumnChartProps(data)),
    ]

    const esgSectionChartConfigs = [
      'environmental' as const,
      'social' as const,
      'governance' as const,
    ]
      .map((section: ESGSection) => {
        const esgSectionOverviewChartConfigs = [
          getSubsectionPieChartConfig(
            ESGSectionOverview.getSubsectionPieChartProps(data, { section })
          ),
          getColumnChartConfig(
            ESGSectionOverview.getScoresColumnChartProps(data, {
              section,
              companies: activeCompanies,
            })
          ),
        ]

        const sectionData = data.multiCompanyFramework.sections.find(
          (s) => slugify(s.title) === section
        )

        const subsectionChartConfigs = sectionData!.subsections
          .map((subsection) => {
            const columnChartProps = ESGSubsection.getScoresColumnChartProps(
              data,
              {
                section,
                subsection: subsection.title,
                companies: activeCompanies,
              }
            )
            const scoreHistoryChartProps =
              ESGSubsection.getScoreHistoryChartProps(data, {
                section,
                subsection: subsection.title,
                companies: activeCompanies,
              })

            const indicatorChartConfigs =
              ESGSubsection.getIndicatorChartPropsList(data, {
                section,
                subsection: subsection.title,
                year: year!,
                companies: activeCompanies,
              })
                .map(([columnChartProps, scoreHistoryChartProps]) => [
                  getColumnChartConfig(columnChartProps),
                  getScoreHistoryChartConfig(scoreHistoryChartProps),
                ])
                .flat()

            return [
              ...(columnChartProps
                ? [
                    getColumnChartConfig({
                      ...columnChartProps,
                      title: `Notas ${subsection.title}`,
                    }),
                  ]
                : []),
              ...(scoreHistoryChartProps
                ? [getScoreHistoryChartConfig(scoreHistoryChartProps)]
                : []),
              ...indicatorChartConfigs,
            ]
          })
          .flat()

        return [...esgSectionOverviewChartConfigs, ...subsectionChartConfigs]
      })
      .flat()

    const chartConfigs = [...overviewChartConfigs, ...esgSectionChartConfigs]
    const theme = await getTheme('jgp')

    await getChartImages(chartConfigs).then((svgs) =>
      genPPT({ theme, svgs, imagesPerPage: 1 })
    )
  }, [
    loadingSector,
    makeFullReportQuery,
    sector,
    companies,
    year,
    activeCompanies,
    getChartImages,
  ])

  return !loadingSector && (!sector || !year) ? (
    <NotFound />
  ) : (
    <SectoralReport
      loading={loading}
      className={props.className}
      sector={sector}
      sections={sections}
      isFavorite={isFavorite}
      isNotified={isNotified}
      onFavoriteChange={handleFavoriteChange}
      onNotificationChange={handleNotificationChange}
      onDownload={handleDownload}
    />
  )
}

export default SectoralReportContainer
