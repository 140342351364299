import classNames from 'classnames'
import React from 'react'

import ScoreHistoryChart, {
  Props as ScoreHistoryChartProps,
} from '../../../../components/ScoreHistoryChart'
import SubsectionPieChart, {
  Props as SubsectionPieChartProps,
} from '../../../../components/SubsectionPieChart'
import { useWatchLoading } from '../../../../contexts/Loading'
import SubsectionScoresChart, {
  Props as SubsectionScoresChartProps,
} from '../SubsectionScoresChart'

interface Props {
  loading: boolean
  subsectionPieChartProps: SubsectionPieChartProps | null
  scoreHistoryChartProps: ScoreHistoryChartProps | null
  subsectionScoresChartProps: SubsectionScoresChartProps | null
  className?: string
}

const ESGSectionOverview: React.FC<Props> = (props) => {
  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div className={classNames('font-work-sans', props.className)}>
      <div className="text-xl font-semibold">Visão geral</div>
      <div className="mt-12 grid grid-cols-2 gap-x-4 gap-y-16">
        <SubsectionPieChart
          className="col-span-2 w-5/6"
          {...props.subsectionPieChartProps!}
        />
        <ScoreHistoryChart {...props.scoreHistoryChartProps!} />
        <SubsectionScoresChart {...props.subsectionScoresChartProps!} />
      </div>
    </div>
  )
}

export default ESGSectionOverview
