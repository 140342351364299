import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import React, { useCallback, useEffect, useState } from 'react'

import { useImperativeQuery } from '../../../../utils/hooks'
import Sectors from '../../components/Sectors'

interface Props {
  className?: string
}

interface QueryResult {
  user: {
    favorites: { sectors: string[] }
    notifications: { sectors: string[] }
  }
}

const query = loader('./query.graphql')
const mutationSetFavorite = loader('./mutationSetFavorite.graphql')
const mutationSetNotification = loader('./mutationSetNotification.graphql')

const SectorsContainer: React.FC<Props> = (props) => {
  const [favorites, setFavorites] = useState<string[]>([])
  const [notifications, setNotifications] = useState<string[]>([])

  const makeQuery = useImperativeQuery(query)
  const [changeFavorite] = useMutation(mutationSetFavorite)
  const [changeNotification] = useMutation(mutationSetNotification)

  useEffect(() => {
    makeQuery().then((data: QueryResult) => {
      setFavorites(data.user.favorites.sectors)
      setNotifications(data.user.notifications.sectors)
    })
  }, [makeQuery])

  const handleFavoriteChange = useCallback(
    (sectorSlug: string, value: boolean) =>
      changeFavorite({
        variables: {
          sectorSlug,
          type: 'COMPANY',
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setFavorites(res.data.setFavorite.favorites.sectors)
      }),
    [changeFavorite]
  )

  const handleNotificationChange = useCallback(
    (sectorSlug: string, value: boolean) =>
      changeNotification({
        variables: {
          sectorSlug,
          type: 'COMPANY',
          mode: value ? 'SET' : 'UNSET',
        },
      }).then((res) => {
        setNotifications(res.data.setNotification.notifications.sectors)
      }),
    [changeNotification]
  )

  return (
    <Sectors
      className={props.className}
      favorites={favorites}
      notifications={notifications}
      onFavoriteChange={handleFavoriteChange}
      onNotificationChange={handleNotificationChange}
    />
  )
}

export default SectorsContainer
