import { RegularChart } from '@jgp-er-dev/charts'
import classNames from 'classnames'
import React, { useMemo } from 'react'

export interface Props {
  type: 'vertical' | 'horizontal'
  data: {
    name: string
    value: number
    type?: 'default' | 'highlighted'
    color?: string
  }[]
  className?: string
  title?: string
  unit?: string
  average?: number
  dataLabels?: boolean
}

export const getChartConfig = (props: Props) => {
  const series = [
    {
      name: props.title,
      data: props.data.map((column) => ({
        color:
          column.color ?? column.type === 'default' ? '#83d66b' : '#30ba09',
        name: column.name,
        y: column.value,
      })),
      tooltip: {
        valueSuffix: props.unit && ` ${props.unit}`,
        valueDecimals: 2,
      },
    },
  ]

  const isLongUnit = props.unit && props.unit.length > 6

  return {
    options: {
      chart: {
        type:
          props.type === 'vertical' ? ('column' as const) : ('bar' as const),
        style: { fontFamily: 'Work Sans' },
      },
      plotOptions: {
        bar: { minPointLength: 1 },
        column: {
          dataLabels: {
            enabled: !!props.dataLabels,
            format: '{point.y:.0f}',
          },
          minPointLength: 1,
        },
      },
      series: series as any,
      title: {
        text: props.title
          ? isLongUnit
            ? `${props.title} (${props.unit})`
            : props.title
          : '',
        style: { fontSize: '14px' },
      },
      credits: { text: '' },
      legend: { enabled: false },
      xAxis: { type: 'category' as const },
      yAxis: {
        title: {
          align: 'high' as const,
          text: props.unit && !isLongUnit ? `(${props.unit})` : undefined,
          rotation: 0,
          offset: 15,
          y: props.type === 'vertical' ? -20 : 10,
        },
        plotLines:
          props.average !== undefined
            ? [
                {
                  label: {
                    align: 'right' as const,
                    text: props.average.toFixed(2),
                    style: {
                      color: 'Gray',
                    },
                  },
                  value: props.average,
                  dashStyle: 'ShortDash' as const,
                  color: 'LightGray',
                  zIndex: 5,
                },
                {
                  label: {
                    align: 'right' as const,
                    text: 'Média',
                    y: 12,
                    style: {
                      color: 'Gray',
                    },
                  },
                  value: props.average,
                  dashStyle: 'ShortDash' as const,
                  color: 'LightGray',
                  zIndex: 5,
                },
              ]
            : [],
      },
      navigation: {
        buttonOptions: { enabled: false },
      },
    },
  }
}

const ColumnChart: React.FC<Props> = (props) => {
  const chartProps = useMemo(() => getChartConfig(props), [props])

  return (
    <div
      className={classNames(
        'relative grid h-chart grid-cols-1 p-2',
        props.className
      )}
    >
      <RegularChart chartProps={chartProps} />
      {props.data.length === 0 && (
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-sm text-gray-500">Sem dados para exibir</span>
        </div>
      )}
    </div>
  )
}

export default ColumnChart
