import { render } from 'react-dom'
import { v4 as uuid } from 'uuid'

import Button from '../components/Button'
import Modal from '../components/Modal'

export const displayModal = (args: {
  message: string
  props: React.ComponentProps<typeof Modal>
  buttons: {
    props: React.ComponentProps<typeof Button>
    label: string
  }[]
}) => {
  const id = uuid()

  const container = document.createElement('div')
  document.body.appendChild(container)

  const cleanUp = () => {
    const modal = document.getElementById(id)
    modal && document.body.removeChild(modal)
  }

  const createModalCloseHandler =
    (handler: React.ComponentProps<typeof Modal>['onClose']) => () => {
      handler?.()
      cleanUp()
    }

  const createButtonClickHandler =
    (handler: React.ComponentProps<typeof Button>['onClick']) => () => {
      handler?.()
      cleanUp()
    }

  const modal = (
    <Modal
      id={id}
      {...args.props}
      onClose={createModalCloseHandler(args.props.onClose)}
    >
      <div className="max-w-screen min-w-[30rem] p-8">
        <div>{args.message}</div>
        <div className="mt-8 flex justify-end gap-4">
          {args.buttons.map((button) => (
            <Button
              key={button.label}
              {...button.props}
              onClick={createButtonClickHandler(button.props.onClick)}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </div>
    </Modal>
  )

  render(modal, container)
  document.body.removeChild(container)
}
