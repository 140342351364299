import classNames from 'classnames'
import React from 'react'

import ColumnChart, {
  Props as ColumnChartProps,
} from '../../../../components/ColumnChart'
import ESGPieChart, {
  Props as ESGPieChartProps,
} from '../../../../components/ESGPieChart'
import LinkGrid from '../../../../components/LinkGrid'
import { useWatchLoading } from '../../../../contexts/Loading'
import { getCompanyUrl } from '../../../../utils'

interface Props {
  loading: boolean
  companies: string[]
  esgPieChartProps: ESGPieChartProps | null
  columnChartProps: ColumnChartProps | null
  className?: string
}

const Overview: React.FC<Props> = (props) => {
  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div
      className={classNames('pb-20 pl-10 pr-4 font-work-sans', props.className)}
    >
      <div className="text-3xl font-semibold">Visão geral</div>
      <div className="mt-20 grid grid-cols-1 gap-y-16">
        <ESGPieChart className="w-5/6" {...props.esgPieChartProps!} />
        <ColumnChart {...props.columnChartProps!} />
      </div>

      <LinkGrid
        className="mt-20"
        links={props.companies.map((company) => ({
          label: company,
          url: getCompanyUrl(company),
        }))}
      />
    </div>
  )
}

export default Overview
