import { loader } from 'graphql.macro'
import { uniq } from 'ramda'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useSectors } from '../../../../contexts/Sectors'
import { slugify } from '../../../../utils'
import { useImperativeQuery } from '../../../../utils/hooks'
import { useSectoralPath } from '../../utils/hooks'

interface ISectoralReportContext {
  sector: string | null
  companies: Company[]
  availableYears: number[]
  year: number | null
  setYear: React.Dispatch<React.SetStateAction<number | null>>
  loading: boolean
}

interface Company {
  id: string
  name: string
  availableYears: number[]
  hasQuantDataOnly: boolean
}

interface QueryResult {
  companiesInPeerGroup: {
    id: string
    name: string
    frameworks: {
      year: number
      score: number
    }[]
  }[]
}

const SectoralReportContext = createContext<ISectoralReportContext>(
  {} as ISectoralReportContext
)

const query = loader('./query.graphql')

export const SectoralReportProvider: React.FC = (props) => {
  const [companies, setCompanies] = useState<Company[]>([])
  const [availableYears, setAvailableYears] = useState<number[]>([])
  const [year, setYear] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingSectors, sectors } = useSectors()
  const { sector: sectorSlug } = useSectoralPath()
  const makeQuery = useImperativeQuery(query)

  const sector = useMemo(() => {
    if (loadingSectors) {
      return null
    }

    return sectors.find((sector) => slugify(sector) === sectorSlug) ?? null
  }, [loadingSectors, sectors, sectorSlug])

  useEffect(() => {
    if (loadingSectors) {
      return
    }

    if (!sector) {
      setLoading(false)
      return
    }

    makeQuery({ peerGroup: sector }).then((data: QueryResult) => {
      setCompanies(
        data.companiesInPeerGroup.map((company) => ({
          id: company.id,
          name: company.name,
          availableYears: company.frameworks.map((fw) => fw.year),
          hasQuantDataOnly: company.frameworks.every((fw) => fw.score === 0),
        }))
      )
      const years = uniq(
        data.companiesInPeerGroup
          .map((company) => company.frameworks.map((fw) => fw.year))
          .flat()
      ).sort((a, b) => a - b)
      setAvailableYears(years)
      setYear(years.length > 0 ? years[years.length - 1] : null)
      setLoading(false)
    })
  }, [loadingSectors, sector, makeQuery])

  const value = useMemo(
    () => ({
      sector,
      companies,
      availableYears,
      year,
      setYear,
      loading,
    }),
    [sector, companies, availableYears, year, loading]
  )

  return (
    <SectoralReportContext.Provider value={value}>
      {props.children}
    </SectoralReportContext.Provider>
  )
}

export const useSectoralReport = () => {
  const ctx = useContext(SectoralReportContext)

  if (!ctx) {
    throw new Error(
      'useSectoralReport must be used within a SectoralReportProvider'
    )
  }

  return ctx
}
