import axios from 'axios'

export interface SearchArgs {
  q: string
  start?: number
  dateRestrict?: string
}

export interface SearchItem {
  title: string
  htmlTitle: string
  link: string
  displayLink: string
  snippet: string
  htmlSnippet: string
  formattedUrl: string
  htmlFormattedUrl: string
}

export interface SearchResult {
  items: SearchItem[]
  nextPage: Required<SearchArgs> | null
}

export interface Controversy {
  type: ESGSection
  data: {
    title: string
    link: string
    snippet: string
  }
}

export const getSearchResults = async ({
  q,
  start = 1,
  dateRestrict = 'y3',
}: SearchArgs): Promise<SearchResult> =>
  axios({
    method: 'GET',
    url: '/api/controversies/search',
    params: { q, start, dateRestrict },
  }).then((res) => res.data)

export const checkControversies = async (args: {
  companyId: string
  links: string[]
}): Promise<Record<string, boolean>> =>
  axios({
    method: 'POST',
    url: '/api/controversies/check',
    data: args,
  }).then((res: { data: { existingLinks: string[] } }) => {
    const result = Object.fromEntries(args.links.map((link) => [link, false]))
    res.data.existingLinks.forEach((link) => {
      result[link] = true
    })
    return result
  })

export const saveControversy = async (args: {
  companyId: string
  controversy: Controversy
}): Promise<any> =>
  axios({
    method: 'POST',
    url: '/api/controversies/save',
    data: args,
  })

export const deleteControversy = async (
  args:
    | {
        companyId: string
        controversy: { data: Pick<Controversy['data'], 'link'> }
      }
    | { _id: string }
): Promise<any> =>
  axios({
    method: 'DELETE',
    url: '/api/controversies/delete',
    data: args,
  })

export const getControversyList = async (): Promise<
  {
    _id: string
    company: Pick<Company, 'id' | 'name'>
    controversy: Controversy & { _id: string }
  }[]
> =>
  axios({
    method: 'GET',
    url: '/api/controversies/list',
  }).then((res) => res.data.controversies)

export const getCompanies = async () =>
  axios({
    method: 'GET',
    url: '/api/controversies/companies',
  }).then((res) => res.data.companies)

export const createCompany = async (company: {
  name: string
  peerGroup: string
  ticker: string
  cnpj: string
}) =>
  axios({
    method: 'POST',
    url: '/api/controversies/companies',
    data: company,
  })
