import React from 'react'
import { useHistory } from 'react-router-dom'

import { useUser } from '../../contexts/User'
import { Path } from '../../utils/constants'
import ConfigContainer from './containers/Config'

interface Props {
  className?: string
}

const ConfigPage: React.FC<Props> = (props) => {
  const { email } = useUser()
  const history = useHistory()

  if (!email) {
    history.push(Path.LOGIN)
  }

  return <ConfigContainer className={props.className} />
}

export default ConfigPage
