import React, { useMemo } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import NotFound from '../../components/NotFound'
import { useUser } from '../../contexts/User'
import { Path } from '../../utils/constants'
import BasketsContainer from './containers/Baskets'
import PersonalizedReportContainer from './containers/PersonalizedReport'

interface Props {
  className?: string
}

const PersonalizedReportPage: React.FC<Props> = (props) => {
  const { email, checkPermissions } = useUser()
  const history = useHistory()

  const utilitiesOnly = useMemo(
    () => checkPermissions(['v2:esg_reports:utilities_only']),
    [checkPermissions]
  )

  if (!email) {
    history.push(Path.LOGIN)
  }

  if (utilitiesOnly) {
    return <NotFound />
  }

  return (
    <Switch>
      <Route path={Path.REPORTS_PERSONALIZED} exact>
        <PersonalizedReportContainer className={props.className} />
      </Route>
      <Route path={Path.REPORTS_PERSONALIZED_BASKETS}>
        <BasketsContainer className={props.className} />
      </Route>
    </Switch>
  )
}

export default PersonalizedReportPage
