import { loader } from 'graphql.macro'
import { useEffect, useState } from 'react'

import { computeAverage } from '../../../../utils'
import { ENVIRONMENTAL } from '../../../../utils/constants'
import { useImperativeQuery } from '../../../../utils/hooks'
import DecarbonizationQuant, {
  ChartProps,
} from '../../components/DecarbonizationQuant'
import { useCompanyReport } from '../../contexts/CompanyReport'

interface Props {
  className?: string
}

interface QueryResult {
  companyDecarbFramework: {
    sections: {
      title: string
      subsections: {
        title: string
        quantIndicators: {
          title: string
          values: {
            year: number
            value: number
          }[]
          peerValues: {
            company: {
              name: string
            }
            value: number
          }[]
          unit: string
        }[]
      }[]
    }[]
  } | null
}

const query = loader('./query.graphql')

const getIndicatorColumnChartPropsList = (
  data: QueryResult,
  args: { companyName: string }
): [
  ChartProps['indicatorColumnChartProps'],
  ChartProps['vsPeersColumnChartProps']
][] => {
  if (!data.companyDecarbFramework) {
    return []
  }

  const section = data.companyDecarbFramework.sections.find(
    (s) => s.title === ENVIRONMENTAL
  )

  if (!section) {
    throw new Error(
      'Decarb framework does not contain an Environmental section'
    )
  }

  const indicators = section.subsections
    .map((subsection) => subsection.quantIndicators)
    .flat()

  return indicators.map((indicator) => [
    {
      type: 'vertical' as const,
      title: indicator.title,
      unit: indicator.unit,
      data: indicator.values.map((point) => ({
        name: point.year.toString(),
        value: point.value,
      })),
      dataLabels: true,
    },
    {
      type: 'vertical' as const,
      title: `${indicator.title} x Peers`,
      unit: indicator.unit,
      data: indicator.peerValues
        .map((peer) => ({
          name: peer.company.name,
          value: peer.value,
          type:
            peer.company.name === args.companyName ? 'highlighted' : 'default',
        }))
        .sort((a, b) => b.value - a.value),
      average: computeAverage(indicator.peerValues.map((peer) => peer.value)),
      dataLabels: true,
    },
  ])
}

const DecarbonizationQuantContainer: React.FC<Props> = (props) => {
  const [chartProps, setChartProps] = useState<ChartProps[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { loading: loadingCompany, companyData, year } = useCompanyReport()
  const makeQuery = useImperativeQuery(query)

  useEffect(() => {
    if (loadingCompany) {
      return
    }

    setLoading(true)

    makeQuery({ companyId: companyData!.id, year }).then(
      (data: QueryResult) => {
        const propsList = getIndicatorColumnChartPropsList(data, {
          companyName: companyData!.name,
        })

        setChartProps(
          propsList.map(
            ([indicatorColumnChartProps, vsPeersColumnChartProps]) => ({
              indicatorColumnChartProps,
              vsPeersColumnChartProps,
            })
          )
        )

        setLoading(false)
      }
    )
  }, [loadingCompany, makeQuery, companyData, year])

  return (
    <DecarbonizationQuant
      className={props.className}
      loading={loading}
      companyName={companyData?.name ?? ''}
      chartProps={chartProps}
    />
  )
}

export default DecarbonizationQuantContainer
