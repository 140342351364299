import React from 'react'

import ScoreHistoryChart from '../../../../components/ScoreHistoryChart'

interface Props {
  series: {
    name: string
    data: { x: number; y: number }[]
  }[]
  title: string
  unit: string
  className?: string
  enableLegend?: boolean
}

const ReportChart: React.FC<Props> = (props) => {
  const series = props.series.map((series) => ({
    name: series.name,
    data: series.data.map(({ x, y }) => ({ year: x, value: y })),
    type: 'default' as const,
  }))

  return (
    <ScoreHistoryChart
      className={props.className}
      series={series}
      title={props.title}
      unit={props.unit}
      enableLegend={props.enableLegend}
    />
  )
}

export default ReportChart
