import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import logo from '../../assets/logo.png'
import { useUser } from '../../contexts/User'
import { Path } from '../../utils/constants'
import BellIcon from '../icons/Bell'
import ChevronDownIcon from '../icons/ChevronDown'
import CogIcon from '../icons/Cog'
import PageLink from './PageLink'

interface Props {
  className?: string
}

const TopBar: React.FC<Props> = (props) => {
  const { email, name, checkPermissions } = useUser()
  const location = useLocation()
  const hasNotification = false

  const shouldShowStatistics = useMemo(
    () => checkPermissions(['v2:esg_reports:score_statistics']),
    [checkPermissions]
  )

  const shouldShowPersonalizedReports = useMemo(
    () => checkPermissions(['v2:esg_reports:personalized_reports']),
    [checkPermissions]
  )
  const shouldShowThemes = useMemo(
    () => checkPermissions(['v2:esg_reports:themes']),
    [checkPermissions]
  )

  return (
    <div
      className={classNames(
        'z-10 flex w-full justify-center shadow-md',
        props.className
      )}
    >
      <div className="flex h-14 w-full max-w-screen-xl items-center justify-between px-4">
        <span className="flex h-full flex-shrink-0 items-center">
          <a className="my-4" href={Path.REPORTS}>
            <img className="h-8" src={logo} alt="Logo" />
          </a>
          <div className="ml-16 flex h-full">
            <PageLink
              label="Home"
              href={Path.REPORTS}
              active={location.pathname === Path.REPORTS}
            />
            <PageLink
              label="Empresas"
              href={Path.REPORTS_COMPANY}
              active={location.pathname.startsWith(Path.REPORTS_COMPANY)}
            />
            <PageLink
              label="Setores"
              href={Path.REPORTS_SECTORAL}
              active={location.pathname.startsWith(Path.REPORTS_SECTORAL)}
            />
            {shouldShowStatistics && (
              <PageLink
                label="Estatísticas"
                href={Path.REPORTS_STATISTICS}
                active={location.pathname.startsWith(Path.REPORTS_STATISTICS)}
              />
            )}
            {shouldShowPersonalizedReports && (
              <PageLink
                label="Personalizar"
                href={Path.REPORTS_PERSONALIZED}
                active={location.pathname.startsWith(Path.REPORTS_PERSONALIZED)}
              />
            )}
            {shouldShowThemes && (
              <PageLink
                label="Temas"
                href={Path.REPORTS_THEMES}
                active={location.pathname.startsWith(Path.REPORTS_THEMES)}
              />
            )}
            <PageLink label="Ajuda" href="#" />
          </div>
        </span>
        <span className="flex h-full items-center">
          <Link to={Path.CONFIG}>
            <CogIcon className="h-5 w-5 text-jgp-secondary-light" />
          </Link>
          <button className="relative focus:outline-none">
            <BellIcon className="ml-6 h-5 w-5 text-jgp-secondary-light" />
            {hasNotification && (
              <span className="absolute top-0 right-0 h-2.5 w-2.5 -translate-y-1/4 transform rounded-full border-2 border-white bg-jgp-success-main" />
            )}
          </button>
          <span className="ml-6 h-8 w-0 border-r border-jgp-secondary-light border-opacity-50" />
          <span className="ml-6 flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-jgp-secondary-light font-bold text-white" />
          <span className="ml-4 text-xs">{name ?? email}</span>
          <ChevronDownIcon className="ml-4 h-6 w-6" />
        </span>
      </div>
    </div>
  )
}

export default TopBar
